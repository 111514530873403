import { fabric } from 'fabric';

const shape = ({ coords, color, scale }) => {
  let line = new fabric.Polyline(coords, {
    fill: 'transparent',
    stroke: color,
    strokeWidth: 3*scale,
    strokeLineJoin: 'round',
    perPixelTargetFind: true,
    selectable: false,
    evented: false
  });

  return line;
}

export default shape;