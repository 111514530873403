import React, { Fragment, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { fabric } from 'fabric';

import { emptyObj, isNumeric } from '../../utils/functions';

import StatusButtons from './components/StatusButtons';

import { init } from './utils/init';
import { wheelHandler } from './utils/interaction/wheel';
import { handleResize } from './utils/interaction/resize';
import { handleKeyDown, handleKeyUp } from './utils/interaction/keys';
import { handleObjModified, handleObjMoving, handleObjRotating } from './utils/interaction/objects';
import { handleSelectionCreated, handleSelectionUpdated, handleSelectionCleared } from './utils/interaction/selection';
import { handleMouseDown, handleMouseUp, handleDblClick } from './utils/interaction/mouse';
// import { handleHighlight } from './utils/interaction/highlight';
import { handleTouchDrag, handleLongpress } from './utils/interaction/touch';
import { viewportChanged } from './utils/interaction/viewport';

import { handleDrawPlots } from './utils/display/draw-plots';

const View = (props) => {
  const debug = false;
  const { map, editable, plots, selected, setSelected, zoomTo, toParent } = props;
  const [loading, setLoading] = useState(true);

  const interaction = useRef({
    panX: null,
    panY: null,
    bkgdLocked: false,
    selected: [],
    pauseSel: false, // needed, see notes in interaction/selection
    objMoved: false,
    nextPlot: 1

    // width: 100,
    // height: 100,
    // ogWidth: 100,
    // ogHeight: 100,
    // scale: 1,
    // zoom: 1,
    // locatorScale: 1,
    // labelScale: 1,
    // panning: false,
    // pinching: false,
    // touching: false,
    // canvasDragged: false,
    // objDragged: false,
    // objSelected: false,
    // objRotatable: false,
    // ocrMode: false,
    // ocrCoords: {}
  });

  const wrapperRef = useRef(null);
  const canvasRef = useRef(null);
  const bkgdRef = useRef([]);

  const [width, setWidth] = useState(100);
  const [height, setHeight] = useState(100);

  const [bkgdLocked, setBkgdLocked] = useState(false);

  const wheel   = (e) => wheelHandler({ e, canvas: canvasRef.current, map });
  const resize = (e) => handleResize({ wrapper: wrapperRef.current, canvas: canvasRef.current, map, bkgd: bkgdRef.current });
  const keyDown = (e) => handleKeyDown({ e, debug, canvas: canvasRef.current, interaction: interaction.current, setSelected, toParent: fromChild });
  const keyUp   = (e) => handleKeyUp({ e, debug, canvas: canvasRef.current, interaction: interaction.current, setSelected, toParent: fromChild });

  const selCreated     = (e) => handleSelectionCreated({ e, debug, canvas: canvasRef.current, interaction: interaction.current, setSelected });
  const selUpdated     = (e) => handleSelectionUpdated({ e, debug, canvas: canvasRef.current, interaction: interaction.current, setSelected });
  const selCleared     = (e) => handleSelectionCleared({ e, debug, interaction: interaction.current, setSelected });
  const touchDrag      = (e) => handleTouchDrag({ e, debug, canvas: canvasRef.current, interaction: interaction.current, map });
  // const touchGesture   = (e) => handleTouchGesture({ e, debug, canvas: canvasRef.current, viewRef, interactionRef });
  const touchLongpress = (e) => handleLongpress({ e, debug, canvas: canvasRef.current, interaction: interaction.current, toParent: fromChild });
  const objMoving      = (e) => handleObjMoving({ e, debug, canvas: canvasRef.current, interaction: interaction.current });
  const objRotating    = (e) => handleObjRotating({ e, debug, canvas: canvasRef.current, interaction: interaction.current });
  const objModified    = (e) => handleObjModified({ e, interaction: interaction.current, toParent: fromChild });
  // const objCleared    = (e) => handleObjCleared({ e, canvas: canvasRef.current, fromMap });
  const mouseDown      = (e) => handleMouseDown({ e, debug, canvas: canvasRef.current, interaction: interaction.current, toParent: fromChild });
  const mouseUp        = (e) => handleMouseUp({ e, debug, canvas: canvasRef.current, interaction: interaction.current, setSelected, toParent: fromChild  });
  const mouseDblClick  = (e) => handleDblClick({ e, debug, canvas: canvasRef.current, interaction: interaction.current, toParent: fromChild });

  const drawPlots = () => handleDrawPlots({ canvas: canvasRef.current, map, plots, interaction: interaction.current });

  useLayoutEffect(() => {
    const update = () => {
      let width = wrapperRef.current.parentElement.offsetWidth;
      let height = wrapperRef.current.parentElement.offsetHeight;
      setWidth(width);
      setHeight(height);
    }
    update();
    window.addEventListener('resize', update);
    return () => window.removeEventListener('resize', update);
  }, []);

  useEffect(() => {
    setLoading(true);
    let canvas = init('map', editable);
    let bkgd = new Image();
    bkgd.crossOrigin = 'Anonymous';
    bkgd.src = map.url; // surveytbd
    bkgd.onload = () => {
      bkgdRef.current = [bkgd.width, bkgd.height];
      window.addEventListener('wheel', wheel);
      window.addEventListener('resize', resize);
      window.addEventListener('keydown', keyDown);
      window.addEventListener('keyup', keyUp);

      canvas.on('selection:created', selCreated );
      canvas.on('selection:updated', selUpdated );
      canvas.on('selection:cleared', selCleared );
      canvas.on('object:modified', objModified );
      canvas.on('object:moving', objMoving );
      canvas.on('object:rotating', objRotating );
      canvas.on('mouse:dblclick', mouseDblClick );
      canvas.on('mouse:up', mouseUp );
      // // canvas.on('touch:gesture', touchGesture );
      // canvas.on('before:selection:cleared', objCleared );
      canvas.on('mouse:down', mouseDown );
      canvas.on('touch:drag', touchDrag );
      canvas.on('touch:longpress', touchLongpress );

      canvas.setBackgroundImage(map.url, canvas.renderAll.bind(canvas), {
        opacity: 1,
        backgroundImageStretch: false,
        originX: 'center',
        originY: 'center',
        left: bkgd.width/2,
        top: bkgd.height/2,
        crossOrigin: 'Anonymous',
        // this angle works but the resize thing doesnt account for it
        // angle: 90
      });

      setLoading(false);
      canvasRef.current = canvas;

      // reset interaction before drawing plots
      interaction.current.selected = [];
      resize();
      drawPlots();
    }

    return () => {
      // console.log('unmounting, clear out canvas')
      canvas.wrapperEl.parentElement.innerHTML = '<canvas id="map" height="1" width="1" />';
      window.removeEventListener('wheel', wheel);
      window.removeEventListener('resize', resize);
      window.removeEventListener('keydown', keyDown);
      window.removeEventListener('keyup', keyUp);
    }
  }, [map])

  useEffect(() => {
    // determine next highest number
    let highestPlot = Math.max(...plots.map(o => isNumeric(o.name) ? o.name : 0), 0);
    interaction.current.nextPlot = String(highestPlot+1);

    // then continue to draw plots
    (async () => {
      let temp = [...interaction.current.selected];
      await drawPlots();
      setSelected(temp);
    })();
  }, [plots])

  useEffect(() => {
    if(!canvasRef.current) return;
    canvasRef.current.selection = bkgdLocked;
  }, [bkgdLocked])

  useEffect(() => {
    if(!canvasRef.current) return;
    interaction.current.pauseSel = true;
    interaction.current.selected = selected;
    canvasRef.current.discardActiveObject();

    let objs = [];
    for(const plot of selected) {
      canvasRef.current.getObjects().forEach((obj) => {
        if(plot.id === obj.id && plot.piece === obj.piece)
          objs.push(obj);
      });
    }

    if(objs.length===1) {
      canvasRef.current.setActiveObject(objs[0]).requestRenderAll();
    } else {
      var sel = new fabric.ActiveSelection(objs, { canvas: canvasRef.current });
      canvasRef.current.setActiveObject(sel).requestRenderAll();
    }

    interaction.current.pauseSel = false;
  }, [selected])

  useEffect(() => {
    if(!canvasRef.current || emptyObj(zoomTo)) return;
    // lklklk: think about highlighting the plot too?
    // 1. find the obj that matches via appid
    // 2. animate a highlight
    // console.log(cmd.value.appId);

    // calculate canvas size + center point of plot
    let zoom = 1.3; // desired zoom
    let x = -zoomTo.left*zoom + canvasRef.current.width/2;
    let y = -zoomTo.top*zoom + canvasRef.current.height/2;
    canvasRef.current.setViewportTransform([zoom,0,0,zoom,x,y]);
    viewportChanged(canvasRef.current, map);
  }, [zoomTo])

  const fromChild = (data) => {
    const { type, value } = data;
    if(debug) console.log(data);
    if(type === 'shift key') {
      setBkgdLocked(value);
    } else {
      toParent(data);
    }    
  }

  return (
    <Fragment>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0" />
        <style>{`body { overflow: hidden; }`}</style>
      </Helmet>

      { loading && (
        <div style={{top:0, left: 0, height: '100vh',width:'100vw',zIndex:1040}} className="position-fixed bg-light d-flex align-items-center justify-content-center">
          <div className="spinner-border text-muted" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}

      <div ref={wrapperRef} className="d-inline-block" style={{width:width, height:height}}>
        <canvas id="map" height="1" width="1" />
      </div>

      <StatusButtons 
        classes="position-fixed" 
        styles={{bottom:16, right:16}} 
        interaction={interaction.current}
        bkgdLocked={bkgdLocked}
        setBkgdLocked={setBkgdLocked}
        resize={resize}
      />
    </Fragment>
  )
}

export default View;
