import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useAxios } from '../../../../hooks/useAxios';

import { Map as MapIcon } from 'react-bootstrap-icons'
import DeleteBtn  from '../../../../components/buttons/DeleteBtn';
import Generated  from '../../../../components/system/Generated';
import { formObj } from '../../../../utils/schemas';

const schema = [
  { id: '1', type: 'col-12', label: '1', col: [
    { id: '2', type: 'hidden', field: 'id', },
    { id: '3', type: 'input', inputType: 'text', field: 'name', },
    { id: '4', type: 'hidden', field: 'status', },
  ]},
]

const View = (props) => {
  const { idx, obj, pieces, length, toParent } = props;
  const navigate = useNavigate();
  const { serverCall } = useAxios();
  const { control, register, handleSubmit, formState: { errors }, reset, watch, setValue } = useForm({
    defaultValues: useMemo(() => formObj(schema), [])
  });

  // if you need to set the form value based on props.obj changing
  useEffect(() => {
    if(!obj) return;
    let filled = formObj(schema, obj);
    reset(filled);
  }, [obj, reset])

  const onSubmit = async (data) => {
    let res = await serverCall({ method: 'PATCH', data, url: `/mapping/plans/${obj.appId}`, eamsAuth0: true });
    if(res.status!==200) return alert('Error updating plan. Contact support.'); // lklklk
    if(toParent) toParent({ type: 'update plan', value: data });
  };

  const fromChild = (data) => {
    const { type, name, value } = data;
    if(type === 'delete confirmed') {
      setValue('status','D');
      handleSubmit(onSubmit)();
    } else {
      if(obj[name] !== value)
        handleSubmit(onSubmit)();
    }
  }

  return (
    <div className={`d-flex align-items-center py-2 ${ idx === length - 1 ? '' : 'border-bottom' }`}>
      <button className="btn px-2" onClick={() => navigate(`./map/${obj.appId}`)}  tabIndex="-1">
        <MapIcon size={16} />
      </button>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" className="flex-grow-1">
        <Generated id={`plan-row-${obj.appId}`} schema={schema} size="sm" pieces={{ register, control, setValue, watch, errors, toParent: fromChild }} />
      </form>
      {/*<DeleteBtn toParent={fromChild} styles="float-end btn btn-xs mx-2" size={18} tabIndex="-1" />*/}
    </div>
  )
}

export default View;
