import React, { forwardRef, useCallback, useEffect, useRef } from 'react'

import '../../../static/redactor/redactorx.css';
import RedactorX from '../../../static/redactor/redactorx.js';

const Field = forwardRef(({ item, pieces, disabled, ...rest }, ref) => {
  const app = useRef();
  const editorId = `${pieces.formId}-${item.field}`;

  // watching both in case this is a plot with duplicate appIds
  const id = pieces.watch('id');
  const appId = pieces.watch('appId');

  const blurred = useCallback(() => {
    let content = app.current.editor.getContent();
    pieces.setValue(item.field, content);
    if(pieces.toParent) pieces.toParent({ type: 'redactor', name: item.field, value: content });
  }, [item.field, pieces]);

  useEffect(() => {
    if(app.current) {
      app.current.destroy();
      app.current = '';
    }

    let value = pieces.getValues(item.field);
    let html = (value && value!=='') ? value : '<p></p>';

    app.current = RedactorX(`#${editorId}`, {
      content: html,
      addbar: { hide: item.hide },
      subscribe: {
        'editor.blur': () => blurred()
      }
    });
  }, [id, appId, editorId, item, pieces, blurred])

  useEffect(() => {
    return () => {
      app.current.destroy();
      app.current = '';
    }    
  }, [])

  return (
    <div className="my-2">
      {item.label && (<label htmlFor={item.field}>{item.label}</label>)}
      <textarea id={editorId} />
    </div>
  );
});

export default Field;
