


// lklklk this finds the most recent one but doesn't find it by the active phase...
// is that right???

// actually ... the first check works
// but after we sort the phases we need to cut off the ones after activePhase
// so that we are getting an older one...

// separately, we should return the resource grid if all else fails


// consider figuring it out so we sort in order ASC, then remove after index:
// https://stackoverflow.com/questions/26568536/remove-all-items-after-an-index
// then resort negatively to find the most recent one...?

export const findPhasePlan = (phases, activePhase, planPhases) => {
  // to find plan by phase, we check to see if a plan for phase exists
  // if it does, return that; if NOT, then we:
  // 1. sort phases in descending order
  // 2. loop through the phases to see if a plan exists
  // 3. break/return when we find one
  // 4. otherwise, we return null and the grid appears

  // check if there's a planPhase matching the current id
  let found = planPhases.find(x => x.phaseId === activePhase.id);
  if(found) return found;

  // if not, sort phases by descending order
  phases.sort((a,b) => (b.sortOrder > a.sortOrder) ? 1 : ((a.sortOrder > b.sortOrder) ? -1 : 0));

  // then check for the most recent plan update
  for (const phase of phases) {
    let plan = planPhases?.find(x => x.phaseId === phase.id);
    if(plan) return plan;
  }

  return null;
}
