import React, { Fragment } from 'react';
import { useOutletContext } from 'react-router-dom'

const View = (props) => {
  const { someVar } = useOutletContext();

  return (
    <Fragment>
      {someVar}<br />
      list
    </Fragment>
  )
}

export default View;
