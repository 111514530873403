import React, { Fragment } from 'react';
import DraggableMarker from './DraggableMarker';

// note: we have this as a child component
// because it seemed like the parent map wouldn't update
// often enough when the plots were updated

const View = (props) => {
  const { draggable, zoom, plots, toParent } = props;

  return (
    <Fragment>
      { plots.map(obj => (
        <DraggableMarker 
          key={`plot-${obj.appId}-${obj.phaseId}`} 
          plot={obj}
          zoom={zoom}
          draggable={draggable}
          toParent={toParent}
        /> 
      ))}
    </Fragment>
  );
}

export default View;