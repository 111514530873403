import React, { forwardRef, useEffect, useState } from 'react'

import Autosuggest from './Autosuggest'

// future: add a 'variation label' too for the second group?

const Field = forwardRef(({ item, pieces, disabled, ...rest }, ref) => {
  const typeOpts = pieces.vars?.types ? pieces.vars.types : [];
  const varOpts  = pieces.vars?.variations ? pieces.vars.variations : [];
  const [typeVars, setTypeVars] = useState([]);

  const value = pieces.watch(item.field);

  useEffect(() => {
    if(!value) return;

    // lklklk once typeId is set, filter varOpts

  }, [value])

  return (
    <div className={ pieces.size === 'sm' ? 'my-0' : 'my-2'}>
      {item.label && (<label htmlFor={item.field}>{item.label}</label>)}
      <Autosuggest item={{ field: 'typeId', options: typeOpts, autosave: item.autosave }} pieces={pieces} />
      { typeVars.length > 0 && (
        <Autosuggest item={{ field: 'variationId', options: varOpts, autosave: item.autosave }} pieces={pieces} />
      )}
    </div>
  )
});

export default Field;
