import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Marker, Tooltip } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet-rotatedmarker';

import { emptyObj } from '../../../../utils/functions'

// lklklk: need to implement rotatable marker

// lklklk: also need to implement the wider dimensions for directionals


// lKLKLK INSTEAD OF AN ENTIRELY DIFFERENT ICON
// can we just adjust the border color

// const rotatingIcon  = new L.divIcon({
//   iconSize: [10, 10],
//   html: `<div class="divIcon" style="
//   transform: translate(-50%, -50%);
//   width: `+mkrWidth+`px;
//   height: `+mkrHeight+`px;
//   background-color: red;
//   border: 2.5px solid white;
//   border-radius: `+mkrRadius+`px;"></div>`
// });

const View = (props) => {
  const { plot, zoom, toParent } = props;
  const draggable = plot.disabled ? false : props.draggable;
  const opacity   = plot.disabled ? 0.3 : 1;
  const [position, setPosition] = useState([plot.styling.lat, plot.styling.lng])
  const [rotation, setRotation] = useState(0);
  const [styling, setStyling] = useState({ width: 8, height: 8, radius: 9, bgColor: '#000', border: '2.5px solid #000'});
  const markerRef = useRef(null);
  const timerRef = useRef();

  const icon = new L.divIcon({
    iconSize: [10, 10],
    html: `<div class="divIcon" style="
    transform: translate(-50%, -50%);
    width: `+styling.width+`px;
    height: `+styling.height+`px;
    background-color: `+styling.bgColor+`;
    border: `+styling.border+`;
    border-radius: `+styling.radius+`px;"></div>`
  });

  const eventHandlers = useMemo(() => ({
    click(e) {
      if(e.originalEvent.detail===1) {
        timerRef.current = setTimeout(() => {
          if(toParent) toParent({ type: 'view plot', value: { appId: plot.appId, phaseId: plot.phaseId } });
        }, 100);

      } else if(e.originalEvent.detail===2) {
        clearTimeout(timerRef.current);
        // if(rotatable) startRotation();
      }

    },
    dragend() {
      const marker = markerRef.current
      if (marker != null) {
        let latLng = marker.getLatLng(); 
        setPosition(latLng);
        if(toParent) toParent({ type: 'plots moved', value: [{ appId: plot.appId, phaseId: plot.phaseId, lat: latLng.lat, lng: latLng.lng }] });
      }
    },
  }), [])

  useEffect(() => {
    // lklklk this isn't updating properly
    // may need to just switch to a plain marker instead of the component
    // https://stackoverflow.com/questions/64197805/react-leaflet-marker-rotation
    let rotate = plot.styling.rotate ? plot.styling.rotate : 0;
    setRotation(rotate);
  }, [plot])

  useEffect(() => {
    setStyling((prev) => {
      let style;
      if(zoom>=23) 
        style = { ...prev, width: 30, height: 15, radius: 15 };
      else if(zoom===22) 
        style = { ...prev, width: 20, height: 9, radius: 9 };
      else if(zoom===21)
        style = { ...prev, width: 15, height: 8, radius: 9 };
      else
        style = { ...prev, width: 8, height: 8, radius: 9 };
      return style;
    })
  }, [zoom])

  useEffect(() => {
    if(emptyObj(plot.display)) return;
    setStyling((prev) => {
      let style = { ...prev};
      style.bgColor = plot.display.locatorFill ? plot.display.locatorFill : '#000';
      style.border = plot.display.borderColor ? `2px solid ${plot.display.borderColor}` : '2px solid #000';
      return style;
    })
  }, [plot])

  return (
    <Marker
      appId={plot.appId}
      phaseId={plot.phaseId}
      draggable={draggable}
      opacity={opacity}
      eventHandlers={eventHandlers}
      icon={icon}
      rotationAngle={plot.styling.rotate}
      rotationOrigin="left top"
      position={position}
      ref={markerRef}
    >
    <Tooltip direction="top">{plot.display.textLabel}</Tooltip>
  </Marker>
  );
}

export default View;