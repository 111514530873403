
// if you need to research deep copying objects:
// https://stackoverflow.com/a/53771927/983017

// emptyObj = checks if an object is empty
// addToArray, removeFromArray = adds/removes from array and removes duplicates
// mergeArrays, removeMultiple = adds/removes arrays from arrays
// checkSubset = checks to see if a parent array contains an entire child array
// createFileName = sanitizes input to be a file name
// formatBytes = converts integer to kb, mb, gb, etc.
// titleCase = converts first word of string to title case
// nl2br = split linebreaks and add br tags
// getDifference = get difference between two arrays
// regexpBucket
// regexpUrls
// downloadFile
// arrToObj = converts an array to an object based on the provided key
// userArray = returns a formatted list of user names
// accessArray = returns a formatted list of groups + roles
// isNumeric = checks if a string is a number
// pickTextColor = selects foreground color
// countByKey, countByTwoKeys = returns number of records
// sumByKey, sumByTwoKeys = returns sum of records

// templates:
// promise resolve function

export const emptyObj = (obj) => {
  if(!obj) return true;
  return Object.keys(obj).length === 0;
}

export const addToArray = (arr, val) => {
  let newArr = [...arr];
  newArr.push(val);
  let unique = [...new Set(newArr)];
  return unique;
}

export const removeFromArray = (arr, val) => {
  let newArr = [...arr];
  let idx = newArr.indexOf(val);
  if (idx > -1) newArr.splice(idx, 1);
  return newArr;
}

export const mergeArrays = (arr1, arr2) => {
  let newArr = [...arr1, ...arr2];
  let unique = [...new Set(newArr)];
  return unique;
}

export const removeMultiple = (arr, vals) => {
  let newArr = [...arr];
  newArr = newArr.filter(x => !vals.includes(x));
  return newArr;
}

export const checkSubset = (parentArray, subsetArray) => {
  return subsetArray.every((el) => {
    return parentArray.includes(el)
  })
}

export const createFileName = (input) => {
  if(!input) return 'tbd';

  var illegalRe = /[<>\\:":]/g;
  // var controlRe = /[\x00-\x1f\x80-\x9f]/g;
  var reservedRe = /^\.+$/;
  var windowsReservedRe = /^(con|prn|aux|nul|com[0-9]|lpt[0-9])(\..*)?$/i;

  var sanitized = input
    .replace(illegalRe, '')
    // .replace(controlRe, '')
    .replace(reservedRe, '')
    .replace(windowsReservedRe, '')
    .replace(/[^a-zA-Z0-9 ]/g, '')
    .replace(/\s+/g, '-')
    .split('').splice(0, 255).join('')
    .toLowerCase();

  return sanitized;
};

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export const titleCase = (str) => {
  if(!str) return;
  return str.toLowerCase().split(' ').map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');
}

export const nl2br = (str, is_xhtml) => {
  if (typeof str === 'undefined' || str === null) {
    return '';
  }
  var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
  return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
}

export const getDifference = (a, b) => {
  return a.filter(element => {
    return !b.includes(element);
  });
}

// split url to get bucket and key/filename info
// saving in case removing chars makes this not work /\bhttps?:\/\/([^\/]+)\.s3[^\/]+amazonaws\.com\/([^\s]*)\b/g;
export const regexpBucket = /\bhttps?:\/\/([^]+)\.s3[^]+amazonaws\.com\/([^\s]*)\b/g;

// search string for all included URLs (not limited to AWS)
export const regexpUrls = /(http|ftp|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])/g;

export const downloadFile = (file, fileName, createUrl) => {
  // you haven't tested this with createUrl being true
  if(createUrl) return alert('NOT TESTED READ DOCUMENTATION');

  // Create a link and set the URL using `createObjectURL`
  const link = document.createElement('a');
  link.style.display = 'none';
  link.href = createUrl ? URL.createObjectURL(file) : file;
  link.download = fileName;

  // It needs to be added to the DOM so it can be clicked
  document.body.appendChild(link);
  link.click();

  // wait to remove so this works in FF
  setTimeout(() => {
    URL.revokeObjectURL(link.href);
    link.parentNode.removeChild(link);
  }, 0);

  // another different example if the above doesn't work for some reason
  // link.href = `data:application/pdf;base64,${e.data.}`;
}

export const arrToObj = (array, key) =>
  array.reduce(
    (obj, item) => ({
      ...obj,
      [item[key]]: item
    }),
    {}
  );

export const objToArr = (obj) => {
  return Object.keys(obj).map(function (key) {
    return obj[key];
  });
}

export const userArray = (allUsers, idArray) => {
  if(!idArray || idArray.length === 0) return '-';
  let list = [];
  for (const id of idArray) {
    let found = allUsers.find(x => x.id === Number(id));
    if(found) list.push(`${found.firstName} ${found.lastName ? found.lastName : ''}`);
  }

  return list.join(', ');
}

export const accessArray = (allGroups, rolesArray) => {
  // lklklk: may need to add projects to this at some point too?
  if(!rolesArray || rolesArray.length === 0) return '';
  let list = [];
  for (const role of rolesArray) {
    if(role.type !== 'group') continue;
    let group = allGroups.find(x => x.id === role.id);
    let str = `${group.label}`;
    str += role.role ? ` (${role.role.charAt(0).toUpperCase() + role.role.slice(1)})` : '';
    list.push(str);
  }

  return list.sort().join(', ');
}

// blob to array buffer to blob
// const buf = await blob.arrayBuffer();
// let objUrl = URL.createObjectURL( new Blob( [ buf ] ) );

// convert blob to base64 string
// const reader = new FileReader();
// reader.addEventListener('load', () => {
//   console.log(reader.result);
// });
// reader.readAsDataURL(blob);


// custom sort ordering on object values
// var ordering = {}, // map for efficient lookup of sortIndex
//     sortOrder = ['D','A','B','C'];
// for (var i=0; i<sortOrder.length; i++)
//     ordering[sortOrder[i]] = i;
//
// let lktest = schema.sort( function(a, b) {
//     return (ordering[a.id] - ordering[b.id]) || a.id.localeCompare(b.id);
// });

// function to check equality between two objects
// function deepEqual(x, y) {
//   const ok = Object.keys, tx = typeof x, ty = typeof y;
//   return x && y && tx === 'object' && tx === ty ? (
//     ok(x).length === ok(y).length &&
//       ok(x).every(key => deepEqual(x[key], y[key]))
//   ) : (x === y);
// }

export const isNumeric = (str) => {
  if (typeof str === 'number') return true;
  if (typeof str !== 'string') return false; // we only process strings!
  return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
         !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

export const pickTextColor = (bgColor, lightColor, darkColor) => {
  // https://stackoverflow.com/questions/3942878/how-to-decide-font-color-in-white-or-black-depending-on-background-color

  if(!bgColor)    bgColor = '#f00';
  if(!lightColor) lightColor = '#fff';
  if(!darkColor)  darkColor = '#000';

  bgColor = bgColor.replace('#', '');

  if(bgColor.length === 3) {
  	bgColor = bgColor.split('').map(function (hex) {
  		return hex + hex;
  	}).join('');
  }

  var color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;
  var r = parseInt(color.substring(0, 2), 16); // hexToR
  var g = parseInt(color.substring(2, 4), 16); // hexToG
  var b = parseInt(color.substring(4, 6), 16); // hexToB
  var uicolors = [r / 255, g / 255, b / 255];
  var c = uicolors.map((col) => {
    if (col <= 0.03928) {
      return col / 12.92;
    }
    return Math.pow((col + 0.055) / 1.055, 2.4);
  });
  var L = (0.2126 * c[0]) + (0.7152 * c[1]) + (0.0722 * c[2]);
  return (L > 0.179) ? darkColor : lightColor;
}

export const countByKey = (data, key) => {
  const res = data.reduce((acc, obj) => {
    const existingIndex = acc.findIndex(
      el => el[key] === obj[key]
    )
    if (existingIndex > -1) {
      acc[existingIndex].quantity += 1
    } else {
      let newObj = {};
      newObj[key] = obj[key];
      newObj['quantity'] = 1;
      acc.push(newObj);
    }
    return acc
  }, []);

  return res;
}

export const countByTwoKeys = (data, key1, key2) => {
  const res = data.reduce((acc, obj) => {
    const existingIndex = acc.findIndex(
      el => el[key1] === obj[key1] && el[key2] === obj[key2]
    )
    if (existingIndex > -1) {
      acc[existingIndex].quantity += 1
    } else {
      let newObj = {};
      newObj[key1] = obj[key1];
      newObj[key2] = obj[key2];
      newObj['quantity'] = 1;
      acc.push(newObj);
    }
    return acc
  }, []);

  return res;
}

export const sumByKey = (data, key) => {
  const res = data.reduce((acc, obj) => {
    // set quantity as 1 if obj.property doesn't exist/is null
    if(!obj.hasOwnProperty('quantity') || obj.quantity === null)
      obj.quantity = 1;

    const existingIndex = acc.findIndex(
      el => el[key] === obj[key]
    )
    if (existingIndex > -1) {
      acc[existingIndex].quantity = acc[existingIndex].quantity + obj.quantity;
    } else {
      let newObj = {};
      newObj[key] = obj[key];
      newObj['quantity'] = obj.quantity;
      acc.push(newObj);
    }
    return acc
  }, []);

  return res;
}

export const sumByTwoKeys = (data, key1, key2) => {
  const res = data.reduce((acc, obj) => {
    // set quantity as 1 if obj.property doesn't exist/is null
    if(!obj.hasOwnProperty('quantity') || obj.quantity === null)
      obj.quantity = 1;

    const existingIndex = acc.findIndex(
      el => el[key1] === obj[key1] && el[key2] === obj[key2]
    )
    if (existingIndex > -1) {
      acc[existingIndex].quantity = acc[existingIndex].quantity + obj.quantity;
    } else {
      let newObj = {};
      newObj[key1] = obj[key1];
      newObj[key2] = obj[key2];
      newObj['quantity'] = obj.quantity;
      acc.push(newObj);
    }
    return acc
  }, []);

  return res;
}

export const example1 = () => {
  return new Promise(async(resolve) => {
    resolve(true);
  })
};
