import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useAxios } from '../../../../hooks/useAxios';

import DeleteBtn  from '../../../../components/buttons/DeleteBtn';
import Generated  from '../../../../components/system/Generated';
import { formObj } from '../../../../utils/schemas';

const schema = [
  { id: '1', type: 'col-12', label: '1', col: [
    { id: '2', type: 'hidden', field: 'id', },
    { id: '3', type: 'floating-input', inputType: 'text', label: 'Phase name', field: 'name', },
    { id: '4', type: 'hidden', field: 'status', },
  ]},
]

const View = (props) => {
  const { obj, toParent } = props;
  const { serverCall } = useAxios();
  const { control, register, handleSubmit, formState: { errors }, reset, watch, setValue } = useForm({
    defaultValues: useMemo(() => formObj(schema), [])
  });

  // if you need to set the form value based on props.obj changing
  useEffect(() => {
    if(!obj) return;
    let filled = formObj(schema, obj);
    reset(filled);
  }, [obj, reset])

  const onSubmit = async (data) => {
    let res = await serverCall({ method: 'PATCH', data, url: `/mapping/phases/${obj.appId}`, eamsAuth0: true });
    if(res.status!==200) return alert('Error updating phase name. Contact support.'); // lklklk
    if(toParent) toParent({ type: 'update', value: data });
  };

  const fromChild = (data) => {
    const { type } = data;
    if(type === 'delete confirmed') {
      setValue('status','D');
      handleSubmit(onSubmit)();
    } else {
      handleSubmit(onSubmit)();
    }
  }

  return (
    <div className="d-flex align-items-center">
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" className="flex-grow-1">
        <Generated id={obj.appId} schema={schema} size="md" pieces={{ register, control, setValue, watch, errors, toParent: fromChild }} />
      </form>
      <DeleteBtn toParent={fromChild} styles="float-end btn btn-xs mx-2" size={18} />
    </div>
  )
}

export default View;
