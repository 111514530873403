import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom'
import { useAxios } from '../../../hooks/useAxios';
import { nanoid } from 'nanoid';
import dayjs from 'dayjs'
import { ArrowsCollapse, ArrowsExpand } from 'react-bootstrap-icons'

import { UserContext } from '../../../context/UserContext';

import Modal from 'react-bootstrap/Modal';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Category from './components/Category';
import BulkEdit from './components/BulkEdit';
import TypeEdit from './components/TypeEdit';

import { emptyObj, removeMultiple } from '../../../utils/functions';

const View = (props) => {
  const { parentType, parentId, parentAppId, schema, types, setTypes } = props;
  const { userDetails, setProjects, schemas } = useContext(UserContext);
  const { serverCall } = useAxios();

  const [bulkSchema, setBulkSchema] = useState([]);
  const [editSchema, setEditSchema] = useState([]);
  const [activeType, setActiveType] = useState({});
  const [selected, setSelected] = useState([]);

  // category toggles
  const [open, setOpen] = useState(true);
  const toggleOpen = () => setOpen((s) => !s);

  // modal + offcanvas controls
  const [show, setShow] = useState(false);
  const handleClose = () => setShow();
  const closePanel = () => setActiveType({});

  useEffect(() => {
    let found = schemas.find(x => x.name === 'type-edit');
    if(found) setEditSchema(found.schema);

    found = schemas.find(x => x.name === 'type-bulk-edit');
    if(found) setBulkSchema(found.schema);
  }, [schemas])

  const addCategory = () => {
    let arr = [...schema];
    arr.push({ name: 'New Category', uid: nanoid(), ids: [] });
    (parentType === 'project') ? updateProject(arr) : updateGlobal(arr);
  }

  const updateProject = async (data) => {
    setProjects((prev) => {
      let arr = [...prev];
      let idx = arr.findIndex(x => x.appId === parentAppId);
      arr[idx].library = data;
      return arr;
    })

    let update = { 
      id: parentId, 
      library: data,
      updatedBy: userDetails.id,
      updatedAt: dayjs().format('YYYY-MM-DD HH:mm:ss')
    };
    let res = await serverCall({ method: 'PATCH', data: update, url: `/mapping/projects/${parentAppId}`, eamsAuth0: true });
    console.log(res);
  }

  const updateGlobal = async (data) => {
    alert('implement global');
  }

  const fromChild = (data) => {
    console.log(data);
    const { type, value } = data;

    if(type==='edit type') {
      let found = types.find(x => x.id === value);
      if(found) setActiveType(found);

    } else {
      let arr = [...schema];
      if(type==='category update') {
        let idx = arr.findIndex(x => x.uid === value.uid);
        arr[idx].name = value.name;
  
      } else if(type==='category deleted') {
        let idx = arr.findIndex(x => x.uid === value);
        arr.splice(idx, 1);
  
      } else if(type==='add types') {
        let idx = arr.findIndex(x => x.uid === value.uid);
        arr[idx].ids = [...arr[idx].ids, ...value.ids];
  
      } else if(type==='remove types') {
        // check if we are manually passing the id or deleting selected from bulk
        let remove = value ? value : selected;
        for(const category of arr) {
          category.ids = removeMultiple(category.ids, remove);
        }
      }
  
      if(parentType==='project')
        updateProject(arr);
      else if(parentType==='global')
        updateGlobal(arr);
    }
  }

  return (
    <Fragment>
      <div className="row h-100 overflow-hidden">
        <div className="col-sm-9 h-100 overflow-scroll">
          { schema.length === 0 && (
            <div className="mb-3 p-2 bg-white rounded">
              <p className="p-3 mb-0">This looks like a new library. Add a category to get started!</p>
            </div>
          )}
          { schema.sort((a, b) => ((a || {}).name || '').localeCompare((b || {}).name || '', undefined, { numeric: true, sensitivity: 'base' })).map(obj => {
            let filtered = types.filter(x => obj.ids.includes(x.id));
            return <Category key={obj.uid} obj={obj} open={open} parentType={parentType} parentId={parentId} toParent={fromChild} types={filtered} setTypes={setTypes} selected={selected} setSelected={setSelected} />;
          })}
        </div>
        <div className="col-sm-3 h-100 overflow-scroll">
          <h5>Actions</h5>
          <button className="btn btn-outline-dark me-2 mb-2" onClick={toggleOpen}>
            { open && (
              <Fragment><ArrowsCollapse size={18} /> Collapse</Fragment>
            )}
            { !open && (
              <Fragment><ArrowsExpand size={18} /> Expand</Fragment>
            )}
          </button>
          <br />
          <button className="btn btn-outline-dark mb-2" onClick={addCategory}>Add Category</button>
          <br />
          { selected.length > 1 && (
            <button className="btn btn-outline-dark me-2 mb-2" onClick={() => setShow(true)}>Edit {selected.length} Types</button>
          )}

          {/* something here for library to copy? or... we are just linking */}
          { parentType==='project' && (
            <h5 className="mt-3 mb-1">Linked Libraries</h5>
          )}
          { parentType==='global' && (
            <h5>Add delete btn here?</h5>
          )}
        </div>
      </div>

      <Modal size="md" show={show} onHide={handleClose} centered scrollable>
        <BulkEdit userDetails={userDetails} schema={bulkSchema} selected={selected} setTypes={setTypes} handleClose={handleClose} toParent={fromChild} />
      </Modal>

      <Offcanvas show={!emptyObj(activeType)} style={{width:500}} onHide={closePanel} placement="end" scroll={true} keyboard={false} backdrop={false} className="shadow">
        <TypeEdit userDetails={userDetails} obj={activeType} schema={editSchema} setTypes={setTypes} handleClose={closePanel} toParent={fromChild} />
      </Offcanvas>
    </Fragment>
  )
}

export const Library = (props) => {
  const { library, setLibrary, types, setTypes } = props;
  return <View parentType="library" parentId={library.id} parentAppId={library.appId} schema={library.schema} setLibrary={setLibrary} types={types} setTypes={setTypes} />
}

export const LibraryOutlet = (props) => {
  const { project, types, setTypes } = useOutletContext();
  return <View parentType="project" parentId={project.id} parentAppId={project.appId} schema={project.library} types={types} setTypes={setTypes} />
}

// could also do LibraryParam which would grab the object from the server...?
