import React, { Fragment, useState } from 'react';
import { Trash } from 'react-bootstrap-icons';

// how to use:
// <DeleteBtn toParent={fromChild} wrapper="float-end" styles="float-end btn btn-xs me-1" size="16" />
// <DeleteBtn toParent={fromChild} text="Archive" styles="float-end btn btn-xs me-1" />

// future:
// consider adding a 'condition' that you pass, plus a message
// this would be like an extra warning, e.g. in DSM Presentations
// when we want to show a warning if active.children.length > 0
// it could show a modal to add an extra layer of protection

const Button = (props) => {
  const [pending, setPending] = useState(true);
  const [confirmed, setConfirmed] = useState(false);
  const wrapper = (props.wrapper) ? props.wrapper : '';
  const text = (props.text) ? props.text : '';
  const styles = (props.styles) ? props.styles : "btn btn-sm btn-outline-danger";
  const size = (props.size) ? props.size : 14;

  const confirmDelete = () => {
    props.toParent({type: 'delete confirmed'});
    setConfirmed(true);

    setTimeout(() => {
      setConfirmed(false);
      togglePending();
    }, 1000)
  }
  const togglePending  = () => setPending((s) => !s);

  return (
    <Fragment>
      { pending && (
        <div className={wrapper}>
          <button type="button" className={styles} onClick={togglePending}>
            { !text && (
              <Trash className="text-danger" size={size} />
            )}
            { text }
          </button>
        </div>
      )}

      { !pending && (
        <div className={wrapper}>
          <button type="button" className={`${styles} btn-outline-danger`} onClick={confirmDelete} disabled={confirmed}>
            {!confirmed && 'Confirm' }
            { confirmed && 'Please Wait...' }
          </button>
          <button type="button" className={`${styles} btn-outline-dark ms-1`} onClick={togglePending} disabled={confirmed}>
            Cancel
          </button>
        </div>
      )}
    </Fragment>
  )
}

export default Button;
