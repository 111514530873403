import React, { Fragment, useContext, useEffect, useMemo, useState } from 'react';
import { useOutletContext } from 'react-router-dom'

import { UserContext } from '../../../context/UserContext';
import { formatHeaders } from '../../../utils/tables';
import Table from '../../../components/system/Table';

import Filters, { applyFilters } from './Filters';
import Modal from 'react-bootstrap/Modal';
import TaskNew from './TaskNew';
import TaskEdit from './TaskEdit';

const View = () => {
  const { allUsers, schemas, tasks, setTasks } = useContext(UserContext);
  const { project, projTasks } = useOutletContext();

  const [filters, setFilters] = useState([]);
  const [search, setSearch] = useState();
  const activeData = useMemo(() => applyFilters(projTasks, filters, search), [projTasks, filters, search]);

  const [headers, setHeaders] = useState([]);

  const [active, setActive] = useState({});
  const [show, setShow] = useState(false);
  const handleCancel = () => {
    setShow(false);
    setActive({});
  }

  const clicked = (e) => {
    let found = projTasks.find(x => x.id === e.original.id);
    if(!found) return;
    setActive(found);
    setShow(true);
  }

  useEffect(() => {
    let found = schemas.find(x => x.type === 'headers' && x.name === 'task-project');
    if(!found) return;
    let headers = formatHeaders(found.schema, { allUsers });
    setHeaders(headers);
  }, [schemas, allUsers])

  return (
    <Fragment>
      <div className="row h-100 overflow-hidden">
        <div className="col-sm-12 h-100 overflow-scroll">
          <div className="mb-3 p-3 bg-white rounded">
            <div className="mb-2">
            <Filters search={search} setSearch={setSearch} filters={filters} setFilters={setFilters} />
              <div className="float-end"><TaskNew parentType="project" parentId={project.id} modalText={{ button: 'Create Task', title: 'Create New' }} /></div>
            </div>
            <Table columns={headers} data={activeData} onRowClicked={clicked} classes="table table-sm table-hover my-0" rowClasses="pointer" noDataMsg="No tasks for this project yet." />
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleCancel} size='lg' backdrop='static' keyboard={false} scrollable>
        <TaskEdit obj={active} handleCancel={handleCancel} tasks={tasks} setTasks={setTasks} />
      </Modal>
    </Fragment>
  )
}

export default View;
