import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { HexColorPicker, HexColorInput } from "react-colorful";

// lklklk future:
// tooltip to hover color info
// escape key to put back to original value
// enter key to set
// if it's disabled, what do we show?

import useClickOutside from '../../../hooks/useClickOutside';

const Field = forwardRef(({ item, pieces, disabled }, ref) => {
  const [color, setColor] = useState();
  const popover = useRef();
  const value = pieces.watch(item.field);

  const [open, setOpen] = useState(false);
  const toggleOpen = () => setOpen((s) => !s);

  const close = useCallback(() => {
    setOpen(false);
    pieces.setValue(item.field, color);
    if(pieces.toParent) pieces.toParent({ type: 'color-picker', name: item.field, value: color });
  }, [color]);
  useClickOutside(popover, close);

  useEffect(() => {
    setColor(value);
  }, [value])

  return (
    <div className="position-relative my-2">
      <div className="border rounded pointer" style={{backgroundColor: color, width:20, height:20}} onClick={toggleOpen} />
      { open && (
        <div ref={popover} className="position-absolute border rounded-3 bg-white p-2 shadow-sm" style={{top:22, left:0, zIndex: 500}}>
          <HexColorPicker color={color} onChange={setColor} />
          <label className="d-block mt-2">Hex Color:</label>
          <HexColorInput className="form-control" color={color} onChange={setColor} />
        </div>
      )}
    </div>
  )
});

export default Field;

