import React, { Fragment, useContext } from 'react';
import { useContextOutlet, useNavigate } from 'react-router-dom'

import { SiteContext } from '../../../context/SiteContext';
import { UserContext } from '../../../context/UserContext';

const View = (props) => {
  const navigate = useNavigate();
  const { config } = useContext(SiteContext);
  const { schemas } = useContext(UserContext);

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            this is the voerview
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default View;
