import React from 'react';
import FormModal from '../../../../components/system/FormModal';

const schema = [
  { id: '1', type: 'col-12', label: '1', col: [
    { id: '2', type: 'hidden', field: 'projectId' },
    { id: '3', type: 'floating-input', inputType: 'text', label: 'Phase name', field: 'name', },
  ]},
]

const View = (props) => {
  const { projectId, setPhases } = props;

  const fromForm = (data) => {
    let { type, status, response } = data; // value = form values, response = response from server

    if(type === 'submitted' && status === 200) {
      if(!response) alert('Error.'); //lklklk

      setPhases((prev) => {
        let arr = [...prev];
        arr.push(response);
        return arr;
      })
    }
  }

  return (
    <FormModal debug={false} method="POST" url="/mapping/phases" schema={schema}
      createForm={true} updateForm={false} toParent={fromForm}
      vals={{ projectId }}
      modalText={{ button: 'Create Phase', title: 'Create Phase' }}
      formText={{ submit: 'Create', pending: 'Creating', success: 'Created', cancel: 'Cancel' }}
      submitType={{ type: 'close-modal' }}
    />
  )
}

export default View;
