import React, { Fragment, useState } from 'react';

// how to use:
// <ConfirmBtn toParent={fromChild} wrapper="float-end" styles="float-end btn btn-xs me-1" size="16" />
// <ConfirmBtn toParent={fromChild} text="Archive" styles="float-end btn btn-xs me-1" />

const Button = (props) => {
  const { toParent } = props;
  const wrapper = (props.wrapper) ? props.wrapper : '';
  const styles = (props.styles) ? props.styles : '';
  const warning = (props.warning) ? props.warning : '';

  const [pending, setPending] = useState(true);
  const [confirmed, setConfirmed] = useState(false);

  const confirm = () => {
    if(toParent) toParent({type: 'confirmed'});
    setConfirmed(true);

    setTimeout(() => {
      setConfirmed(false);
      togglePending();
    }, 1000)
  }
  const togglePending = () => setPending((s) => !s);

  return (
    <Fragment>
      { pending && (
        <div className={wrapper}>
          <button type="button" className={`${styles} btn btn-outline-primary`} onClick={togglePending}>
            { props.children }
          </button>
        </div>
      )}

      { !pending && (
        <div className={wrapper}>
          { warning ? <p className="mt-3 mb-2">{warning}</p> : ''}
          <button type="button" className={`${styles} btn btn-outline-success`} onClick={confirm} disabled={confirmed}>
            {!confirmed && 'Confirm' }
            { confirmed && 'Please Wait...' }
          </button>
          <button type="button" className={`${styles} btn btn-outline-danger ms-2`} onClick={togglePending} disabled={confirmed}>
            Cancel
          </button>
        </div>
      )}
    </Fragment>
  )
}

export default Button;
