import React, { Fragment, useContext, useEffect, useRef } from 'react';
import { WorkerContext } from '../../../../context/WorkerContext';
import { useAxios } from '../../../../hooks/useAxios';

import { emptyObj } from '../../../../utils/functions';
import Dropzone from '../../../../components/system/dropzone/Dropzone'

const View = (props) => {
  const { project, activePhase, setPlans, setPhasePlans } = props;
  const { startWorker } = useContext(WorkerContext);
  const { serverCall } = useAxios();

  const projectId = useRef();
  const phaseId = useRef();

  useEffect(() => {
    if(emptyObj(project)) return;
    projectId.current = project.id;
  }, [project])

  useEffect(() => {
    if(emptyObj(activePhase)) return;
    phaseId.current = activePhase.id;
  }, [activePhase])

  const fromChild = async (data) => {
    const { type, value } = data;
    value.projectId = projectId.current;
    value.phaseId = phaseId.current;

    if(type==='file uploaded') {
      let res = await serverCall({ method: 'POST', data: value, url: '/services/cc-plan/start', eamsAuth0: true });
      if(!res.data.id || !res.data.links.self) return alert('Error converting plan. Contact support.'); // lklklk

      startWorker({ type: 'cc-plan', pieces: { subtype: 'plan upload', name: `${value.source} processing`, filename: value.source, url: res.data.links.self, value, setPlans, setPhasePlans } });
    }
  }

  return (
    <Fragment>
      <Dropzone
        folder="uploads/plans/"
        prefix="plan"
        fileTypes={{
          'image/jpg': [],
          'image/jpeg': [],
          'image/png': [],
          'image/gif': [],
          'application/pdf': []
        }}
        toParent={fromChild}
      />
    </Fragment>
  )
}

export default View;
