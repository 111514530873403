import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { ArrowsMove, AspectRatio, XLg as XIcon } from 'react-bootstrap-icons'

const View = (props) => {
  const { classes, styles, interaction, bkgdLocked, setBkgdLocked, resize } = props;
  
  const toggleBkgd = () => {
    interaction.bkgdLocked = !interaction.bkgdLocked;
    setBkgdLocked((s) => !s);
  }

  const reset = () => {
    localStorage.removeItem('viewport');
    resize();
  }

  return (
    <div className={classes} style={styles}>
      <OverlayTrigger placement="top" overlay={<Tooltip id="reset-viewport">Reset View</Tooltip>}>
        <button className="btn bg-white rounded border shadow-sm me-2" type="button" onClick={reset}>
          <AspectRatio size={20} />
        </button>
      </OverlayTrigger>
      <OverlayTrigger placement="top" overlay={<Tooltip id="bkgd-locked">{ bkgdLocked ? 'Unlock' : 'Lock'} Background</Tooltip>}>
        <button className="btn bg-white rounded border shadow-sm" type="button" onClick={toggleBkgd}>
          { bkgdLocked && <XIcon size={16} /> }
          { !bkgdLocked && <ArrowsMove size={16} /> }
        </button>
      </OverlayTrigger>
    </div>
  )
}

export default View;
