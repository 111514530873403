import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useOutletContext } from 'react-router-dom';
import { UserContext } from '../../../context/UserContext';
import { useAxios } from '../../../hooks/useAxios';

import Modal from 'react-bootstrap/Modal';
import Offcanvas from 'react-bootstrap/Offcanvas';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import QuickAdd from './components/QuickAdd';
import ChangePlan from './components/ChangePlan';
import ChangePhase from '../phases/components/ChangePhase';
import DownloadPlan from '../../../components/buttons/DownloadPlan';
import Map from '../../../components/mapping/MapV2';

import { Virtuoso } from 'react-virtuoso';
import PlotRow from '../plots/components/PlotRow';
import PlotEdit from '../plots/components/PlotEdit';
import Filters from '../plots/components/Filters';

import { Back as BackIcon, ChevronLeft, Clipboard, LayoutSplit, LayoutTextSidebar, Pencil } from 'react-bootstrap-icons';

import { emptyObj } from '../../../utils/functions';
import { findPhasePlan } from '../../../utils/phases';
import { getPlotDisplay } from '../../../utils/plots';
import { newPlot, copyPlot, addPlot, replacePlot, rotatePlot, updatePoints, movePlots, toggleStatus } from '../plots/utils/plots';

const View = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { serverCall } = useAxios();
  const { userDetails, schemas } = useContext(UserContext);
  const { 
    project, 
    phases, 
    planGroups,
    plans, 
    phasePlans, 
    activePlan, 
    setActivePlan, 
    activePhase, 
    setActivePhase, 
    types, 
    activePlots,
    plots, 
    setPlots, 
    search, 
    setSearch, 
    filters, 
    setFilters
  } = useOutletContext();
  const [mapPlan, setMapPlan] = useState({});
  const planRef = useRef({});
  const listRef = useRef(null);

  const [rowSchema, setRowSchema] = useState([]);
  const [actionSchema, setActionSchema] = useState([]);
  const [editSchema, setEditSchema] = useState([]);
  const [bulkSchema, setBulkSchema] = useState([]);
  const [activePlot, setActivePlot] = useState({});
  const [zoomTo, setZoomTo] = useState({});

  const [selected, setSelected] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [copiedIds, setCopiedIds] = useState([]);

  const quickRef = useRef();
  const [quickAdd, setQuickAdd] = useState(false);
  const toggleQuick = () => setQuickAdd((s) => {
    quickRef.current = null;
    return !s
  });

  const [activeId, setActiveId] = useState();

  const [showPanel, setShowPanel] = useState(false);
  const togglePanel = () => setShowPanel((s) => !s);
  const closePanel = () => setShowPanel(false);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow();

  useEffect(() => {
    let found = schemas.find(x => x.name==='plot-edit-row');
    if(found?.schema) setRowSchema(found.schema);

    found = schemas.find(x => x.name === 'plot-actions');
    if(found) setActionSchema(found.schema);

    found = schemas.find(x => x.name === 'plot-edit');
    if(found) setEditSchema(found.schema);

    found = schemas.find(x => x.name === 'plot-bulk-edit');
    if(found) setBulkSchema(found.schema);
  }, [schemas])

  useEffect(() => {
    if(emptyObj(activePhase)) return;
    setSelected([]);
    let plan = plans.find(x => x.appId === params.planId);
    if(!plan) alert('Error finding plan. Contact support.'); // lklklk
    planRef.current = plan;
    setActivePlan(plan);

    let filtered = phasePlans.filter(x => x.planId === plan.id);
    let phasePlan = findPhasePlan(phases, activePhase, filtered);
    if(phasePlan) {
      // send planId, styling, and signed url to the map
      // planId is required so we filter the plots
      setMapPlan({ projectId: project.id, planId: plan.id, styling: phasePlan.styling, url: phasePlan.url });
    }
  }, [activePhase, phasePlans, phases, plans, setActivePlan, params])

  useEffect(() => {
    if(emptyObj(activePlan)) return;
    if(activePlan.appId !== params.planId)
      navigate(`/project/${params.projectId}/phase/${params.phaseId}/map/${activePlan.appId}`)

    return () =>  {
      setActivePlan({});
    }
  }, [activePlan, setActivePlan, params, navigate])

  useEffect(() => {
    if(selected.length===0) {
      setActiveId();
      setActivePlot({});
    } else if(selected.length===1) {
      setActiveId(selected[0]);
    }

    let ids = selected.map(x => x.id);
    let unique = selected.length > 0 ? [...new Set(ids)] : [];
    setSelectedIds(unique);
  }, [selected])

  useEffect(() => {
    let found = activePlots.find(x => x.id === activeId);
    if(found) setActivePlot(found);
  }, [activePlots, activeId])

  const fromChild = async (data) => {
    const { type, value } = data;
    if(type==='view plot') {
      setActiveId(value);
      let index = activePlots.findIndex(x => x.id === value);
      if(index > -1) listRef.current.scrollToIndex({ index, align: 'center' });

    } else if(type==='dblclick') {
      // add new and add to database
      let plot = newPlot({ typeId: quickRef.current, project, plans, types, planRef, activePhase, pieces: value, userDetails });
      addPlot({ setPlots, plot });

      // push to server, and replace when we get it back with full id
      let res = await serverCall({ method: 'POST', data: plot, url: '/mapping/plots', eamsAuth0: true });
      if(res.status!==200) return alert('Error adding plot. Contact support.'); // lklklk
      let returned = getPlotDisplay({ project, plans, types, plot: res.data });
      replacePlot({ setPlots, plot: returned });

    } else if(type === 'plots moved') {
      let updates = await movePlots({ setPlots, plots: value, userDetails });
      let res = await serverCall({ method: 'PATCH', data: updates, url: '/mapping/plots/bulk', eamsAuth0: true });
      if(res.status!==200) return alert('Error moving plot(s). Contact support.'); // lklklk

    } else if(type === 'rotated' || type==='points moved') {
      let update = {};
      if(type==='rotated')
        update = await rotatePlot({ setPlots, plot: value, userDetails });
      else
        update = await updatePoints({ setPlots, plot: value, userDetails });

      let res = await serverCall({ method: 'PATCH', data: update, url: `/mapping/plots/${value.appId}`, eamsAuth0: true });
      if(res.status!==200) return alert('Error rotating plot. Contact support.'); // lklklk

      // if they were editing the polygon, reselect
      if(type==='points moved') {
        console.log('polygon edit selection NEEDS FIX')
        //   setSelected([value.id]);
      }

    } else if(type === 'delete plots') {
      setShow('delete');
    }
  }

  const copyPlots = () => {
    setCopiedIds(selectedIds);
  }

  const pastePlots = async () => {
    if(copiedIds.length === 0) return;

    // first grab the plots and copy them
    let arr = [];
    for(const id of copiedIds) {
      let ogPlot = plots.find(x => x.id === id);
      let obj = copyPlot({ project, plans, types, planRef, activePhase, ogPlot, userDetails });
      arr.push(obj);
    }

    // then sort by name 
    arr.sort((a, b) => ((a || {}).name || '').localeCompare((b || {}).name || '', undefined, { numeric: true, sensitivity: 'base' }));

    // push to server, and replace when we get it back with full id
    let res = await serverCall({ method: 'POST', data: arr, url: '/mapping/plots/bulk', eamsAuth0: true });
    if(res.status!==200) return alert('Error copying plot(s). Contact support.'); // lklklk

    let newSelect = [];
    await setPlots((prev) => {
      let arr = [...prev];
      for (const returned of res.data) {
        let plot = getPlotDisplay({ project, plans, types, plot: returned });
        arr.push(plot);
        
        // add to selection
        newSelect.push({ appId: plot.appId, id: plot.id, phaseId: plot.phaseId, piece: 'locator' });
        if(plot.display.type === 'arch')
          newSelect.push({ appId: plot.appId, id: plot.id, phaseId: plot.phaseId, piece: 'label' });
      }
      return arr;
    })

    setTimeout(() => setSelected(newSelect), 500);
  }

  const deletePlots = async () => {
    let statuses = await toggleStatus({ setPlots, plotIds: selectedIds, userDetails, status: 'D' });
    let res = await serverCall({ method: 'PATCH', data: statuses, url: '/mapping/plots/bulk', eamsAuth0: true });
    if(res.status!==200) return alert('Error deleting plot(s). Contact support.'); // lklklk
    setSelected([]);
    setCopiedIds([]);
    setShow();
  }

  return (
    <Fragment>
      <div className="row h-100 overflow-hidden">
        <div className="col-sm-5 d-flex flex-column h-100 overflow-hidden">
          <div className="row" style={{height:65}}></div>
          <div className="row overflow-scroll h-100">
            <div className="col p-0">
              { activePlots.length === 0 && <p className="text-muted mt-3">No plots yet.</p>}
              <Virtuoso
                ref={listRef}
                style={{width: '100%', height: '100%'}}
                data={activePlots.filter(x => x.planId === mapPlan.planId)}
                itemContent={(idx, obj) => (
                  <PlotRow schema={rowSchema} obj={obj} project={project} plans={plans} types={types} setPlots={setPlots} selected={selected} setSelected={setSelected} setZoomTo={setZoomTo} toParent={fromChild} />
                )}
              />
              <div style={{height:80}} />
            </div>
          </div>
        </div>
        <div className="col-sm-7 h-100 overflow-hidden">
          { !emptyObj(activePlan) && (
            <Map map={mapPlan} editable={true} plots={activePlots} selected={selected} setSelected={setSelected} zoomTo={zoomTo} toParent={fromChild} />
          )}
        </div>
      </div>

      <div className="position-fixed" style={{ top: 12, left: 12, zIndex: 1045 }}>
        { copiedIds.length > 0 && (
          <div className="mb-2 float-end">
            <OverlayTrigger placement="bottom" overlay={<Tooltip id={`paste-plots`}>Duplicate/Paste {copiedIds.length} Plots</Tooltip>}>
              <button className="btn bg-white border rounded shadow-sm me-2" onClick={pastePlots}><BackIcon size={16} className="me-1" /> {copiedIds.length}</button>
            </OverlayTrigger>
          </div>
        )}
        { selected.length > 0 && (
          <div className="mb-2 float-end">
            <OverlayTrigger placement="bottom" overlay={<Tooltip id={`copy-plots`}>Copy {selectedIds.length} Plots</Tooltip>}>
              <button className="btn bg-white border rounded shadow-sm me-2" onClick={copyPlots}><Clipboard size={16} className="me-1" /> {selectedIds.length}</button>
            </OverlayTrigger>
          </div>
        )}            
        {/* { selected.length > 1 && (
          <div className="mb-2 float-end">
            <OverlayTrigger placement="bottom" overlay={<Tooltip id={`bulk-edit-plots`}>Bulk Edit {selectedIds.length} Plots</Tooltip>}>
            <button className="btn bg-white border rounded shadow-sm me-2"><Pencil size={16} className="me-1" /> {selectedIds.length}</button>
            </OverlayTrigger>
          </div>
        )} */}
        <ChangePhase phases={phases} activePhase={activePhase} setActivePhase={setActivePhase} />
        <ChangePlan plans={plans} activePlan={activePlan} setActivePlan={setActivePlan} />
        <button className="btn bg-white border rounded shadow-sm me-2" onClick={() => navigate(`../phase/${activePhase.appId}`)}>
          <ChevronLeft />
        </button>
        { showPanel && (
          <OverlayTrigger placement="bottom" overlay={<Tooltip id={`layout1`}>Hide Detail Panel</Tooltip>}>
            <button className="btn bg-white border rounded shadow-sm me-2" onClick={togglePanel}><LayoutSplit size={16} /></button>
          </OverlayTrigger>
        )}
        { !showPanel && (
          <OverlayTrigger placement="bottom" overlay={<Tooltip id={`layout2`}>Show Detail Panel</Tooltip>}>
            <button className="btn bg-white border rounded shadow-sm me-2" onClick={togglePanel}><LayoutTextSidebar size={16} /></button>
          </OverlayTrigger>
        )}        
      </div>
      <div className="position-fixed" style={{top:12, right:12}}>
        <Filters planGroups={planGroups} plans={plans} types={types} search={search} setSearch={setSearch} filters={filters} setFilters={setFilters} />
        <QuickAdd quickAdd={quickAdd} quickRef={quickRef} toggle={toggleQuick} types={types} />
        <DownloadPlan classes="btn bg-white border rounded" project={project} planGroups={planGroups} plans={[activePlan]} phasePlans={phasePlans} phases={phases} phase={activePhase} plots={activePlots} />
      </div>

      <Offcanvas show={showPanel}  style={{width:500}} onHide={closePanel} placement="start" scroll={true} keyboard={false} backdrop={false} className="shadow">
        {(!emptyObj(activePlot) && selected.length===1) && (
          <PlotEdit project={project} activePhase={activePhase} obj={activePlot} schema={editSchema} actions={actionSchema} types={types} setPlots={setPlots} handleClose={closePanel} toParent={fromChild} />
        )}
        {(selected.length===0 || selected.length > 1 || emptyObj(activePlot)) && (
          <Fragment>
            <Offcanvas.Header className="bg-body" closeButton>
              <Offcanvas.Title>Detail View</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className="bg-body flex-grow-1 overflow-scroll">
            {emptyObj(activePlot) && <p>Select a plot to view its details here.</p>}
            {selected.length > 1 && <p>Multiple plots selected.</p>}
            </Offcanvas.Body>   
          </Fragment>       
        )}
      </Offcanvas>

      <Modal size="sm" show={show==='delete'} onHide={handleClose} centered>
        <Modal.Body className="bg-body">
          <h5 className="text-danger">Delete Plots</h5>
          <p className="text-danger">Are you sure you want to delete {selectedIds.length} plots?</p>
          <div className="text-center">
            <button className="btn btn-outline-danger mx-1 px-3" onClick={deletePlots}>Confirm</button>
            <button className="btn btn-outline-dark mx-1 px-3" onClick={handleClose}>Cancel</button>
          </div>
          <p className="small text-muted fst-italic mt-3 mb-0">Note: you can undo this by filtering for "inactive" plots and re-activating.</p>
        </Modal.Body>
      </Modal>
    </Fragment>
  )
}

export default View;
