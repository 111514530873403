import React, { Fragment, useContext, useState } from 'react';
import { fabric } from 'fabric';
import dayjs from 'dayjs'

import { WorkerContext } from '../../context/WorkerContext';
import { Download } from 'react-bootstrap-icons';
import { findPhasePlan } from '../../utils/phases';
import { handleDrawPlots } from '../mapping/utils/display/draw-plots';
import { downloadFile } from '../../utils/functions';

// letter: 792 pt x 612 pt
// tabloid: 1224 pt x 792 pt
// arch E: 48 in x 36 in, 3023 pt x 2160 pt

const View = (props) => {
  const { startWorker } = useContext(WorkerContext);
  const { project, planGroups, plans, phasePlans, phases, phase, plots } = props;
  const wrapper = props.wrapper ? props.wrapper : 'shadow-sm me-2';
  const classes = props.classes ? props.classes : 'btn btn-outline-primary';
  const text = props.text ? props.text : '';
  const [loading, setLoading] = useState(false);
  const [current, setCurrent] = useState();
  
  const download = async ({ format, size, fit, hideBg }) => {
    console.clear();
    setLoading(true);

    let timestamp = dayjs().format('YYYY-MM-DD');
    let planName = plans.length > 1 ? 'Plans' : plans[0].name;
    let name = `${project.name} - ${phase.name} - ${planName} (${format.toUpperCase()})`;
    let filename = `${project.name} - ${phase.name} - ${planName} ${timestamp}.${format}`;

    let pages = [];
    for (const [idx, plan] of plans.entries()) {
      setCurrent(idx+1);
      let filtered = phasePlans.filter(x => x.planId === plan.id);
      let phasePlan = findPhasePlan(phases, phase, filtered);
      if(!phasePlan) continue;

      let image = await drawMap({ phasePlan, hideBg });
      console.log(`${plan.name} - ${phasePlan.folder}${phasePlan.name} downloaded`);
      if(format==='pdf')
        pages.push({ image, fit });
      else {
        filename = `${project.name} - ${phase.name} - ${plan.name} ${timestamp}.${format}`;
        downloadFile(image, filename, false);
      }
    }

    if(format==='pdf') {
      let content = { pageSize: size, pageMargins: [18, 18, 18, 18], content: pages }
      startWorker({ type: 'pdf', pieces: { name, filename, content } });
    }

    setLoading(false);
  }


  const drawMap = async ({ phasePlan, hideBg }) => {
    let promise = new Promise((resolve, reject) => {
      let resolution = 1.5;
      let el = document.createElement('canvas');
      let canvas = new fabric.Canvas(el);
      fabric.Object.prototype.originX = fabric.Object.prototype.originY = 'center';

      let bkgd = new Image();
      bkgd.crossOrigin = 'Anonymous';
      bkgd.src = phasePlan.url;
      bkgd.onload = async () => {
        canvas.setViewportTransform([resolution,0,0,resolution,0,0]);
        canvas.setWidth(bkgd.width*resolution);
        canvas.setHeight(bkgd.height*resolution);
        if(!hideBg) {
          canvas.setBackgroundImage(phasePlan.url, canvas.renderAll.bind(canvas), {
            opacity: 1,
            backgroundImageStretch: false,
            originX: 'center',
            originY: 'center',
            left: bkgd.width/2,
            top: bkgd.height/2,
            crossOrigin: 'Anonymous',
          });
        }

        canvas = await handleDrawPlots({ canvas, map: phasePlan, plots });

        setTimeout(() => {
          let imgData = canvas.toDataURL();
          resolve(imgData);
        }, 500)
      }
    })

    let result = await promise;
    return result;
  }

  return (
    <div className={`btn-group position-static ${wrapper}`}>
      <button type="button" className={classes} onClick={() => download({format: 'pdf', size: {width: 1224, height: 792}, fit: [1188, 756]})} disabled={loading}>
        { !loading && (
          <Fragment>
            <Download size={16} />
            { text && <span className="d-inline-block ms-2">{text}</span>}
          </Fragment>
        )}

        { loading && (
          <Fragment>
            <div className="spinner-border spinner-border-sm" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
            { plans.length>1 && <span className="d-inline-block ms-2">{current} / {plans.length}</span>}
          </Fragment>
        )}        
      </button>
      <button type="button" className={`dropdown-toggle dropdown-toggle-split ${classes}`} data-bs-toggle="dropdown" aria-expanded="false" disabled={loading}>
        <span className="visually-hidden">Toggle Dropdown</span>
      </button>
      <ul className="dropdown-menu dropdown-menu-end">
        <li><h6 className="dropdown-header">PDF</h6></li>
        <li className="dropdown-item pointer" onClick={() => download({format: 'pdf', size: {width: 1224, height: 792}, fit: [1188, 756]})}>Tabloid Landscape</li>
        <li className="dropdown-item pointer" onClick={() => download({format: 'pdf', size: {width: 792, height: 1224}, fit: [756, 1188]})}>Tabloid Portrait</li>
        <li><hr className="dropdown-divider" /></li>
        <li><h6 className="dropdown-header">PNG</h6></li>
        <li className="dropdown-item pointer" onClick={() => download({ format: 'png' })}>Plots & Background</li>
        <li className="dropdown-item pointer" onClick={() => download({ format: 'png', hideBg: true })}>Plots Only</li>
      </ul>
    </div>
  )
}

export default View;
