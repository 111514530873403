import React, { Fragment, useContext, useEffect, useMemo, useState } from 'react';
import { useAxios } from '../../../../hooks/useAxios';
import { useForm } from 'react-hook-form';
import { nanoid } from 'nanoid';
import dayjs from 'dayjs';

import { UserContext } from '../../../../context/UserContext';

import Collapse from 'react-bootstrap/Collapse';
import RotatingCaret from '../../../../components/buttons/RotatingCaret';
import TypeRow from './TypeRow';

import DeleteBtn  from '../../../../components/buttons/DeleteBtn';
import Generated  from '../../../../components/system/Generated';

import { formObj } from '../../../../utils/schemas';
import { checkSubset, mergeArrays, removeMultiple } from '../../../../utils/functions';

const schema = [
  { id: '1', type: 'col-12', label: '1', col: [
    { id: '2', type: 'hidden', field: 'uid', },
    { id: '3', type: 'input', inputType: 'text', field: 'name', placeholder: 'Enter a category name', required: 'Please enter a category name' }
  ]},
]

const View = (props) => {
  const { obj, open, types, setTypes, selected, setSelected, parentType, parentId, toParent } = props;
  const { userDetails } = useContext(UserContext);
  const { serverCall } = useAxios();

  const [adding, setAdding] = useState(false);
  const [show, setShow] = useState(open);
  const toggleShow = () => setShow((s) => !s);

  const { control, register, handleSubmit, formState: { errors, isDirty }, reset, watch, setValue } = useForm({
    defaultValues: useMemo(() => formObj(schema), [])
  });

  // if you need to set the form value based on props.obj changing
  useEffect(() => {
    if(!obj) return;
    let filled = formObj(schema, obj);
    reset(filled);
  }, [obj, reset])

  useEffect(() => {
    setShow(open);
  }, [open])

  useEffect(() => {
    let selectAll = document.getElementById(`selectAll-${obj.uid}`);
    if(!selectAll) return;
    selectAll.indeterminate = false;

    let all = checkSubset(selected,obj.ids);
    let some = selected.some(r=> obj.ids.indexOf(r) >= 0);

    if(all && types.length > 0)
      selectAll.checked = true;
    else if(some)
      selectAll.indeterminate = true;
    else
      selectAll.checked = false;

  }, [obj.uid, selected, types])

  const onSubmit = async (data) => {
    toParent({ type: 'category update', value: data });
  };

  const fromChild = (data) => {
    const { type, autosave } = data;
    if(type === 'edit type') {
      toParent(data);
    } else if(type === 'delete confirmed') {
      toParent({ type: 'category deleted', value: obj.uid });
    } else if(type === 'remove types') {
      toParent(data);
    } else if(isDirty || autosave)
      handleSubmit(onSubmit)();
  }

  const selectAll = (e) => {
    e.target.blur();
    setSelected((prev) => {
      let arr = [];
      if(e.target.checked)
        arr = mergeArrays(prev, obj.ids);
      else
        arr = removeMultiple(prev, obj.ids);
      return arr;
    })
  }

  const addTypes = async (qty) => {
    setAdding(true);

    let arr = Array.from({ length: qty }, (_, i) => ({
      appId: nanoid(), 
      libraryId: (parentType==='global') ? parentId : null,
      code: 'TBD',
      createdBy: userDetails.id,
      createdAt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
      status: 'A'
    }))

    let res = await serverCall({ method: 'POST', data: arr, url: `/library/types/bulk`, eamsAuth0: true });
    if(res.status!==200) return alert('Error adding types. Contact support.'); // lklklk

    setTypes((prev) => {
      let arr = [...prev, ...res.data];
      return arr;
    })

    // map ids to add to this category
    let ids = res.data.map(x => x.id);
    toParent({ type: 'add types', value: { ids, uid: obj.uid } });
    setAdding(false);
  }

  return (
    <div className="mb-3 p-2 bg-white rounded">
      <div className="d-flex align-items-center">
        <div className="ms-2 me-1">
          <input
            type="checkbox"
            className="form-check-input"
            id={`selectAll-${obj.uid}`}
            disabled={types.length === 0}
            onChange={selectAll}
          />
        </div>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" className="flex-grow-1">
          <Generated id={obj.uid} schema={schema} size="md" pieces={{ register, control, setValue, watch, errors, toParent: fromChild }} />
        </form>
        <div className="btn-group">
          <button type="button" className="btn btn-sm btn-outline-dark" onClick={()=> addTypes(1)} disabled={adding}>
            { !adding && `Add Type(s)` }
            { adding && (
              <div className="spinner-border spinner-border-sm me-2" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>              
            )}
          </button>
          <button type="button" className="btn btn-sm btn-outline-dark dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false" disabled={adding}>
            <span className="visually-hidden">Toggle Dropdown</span>
          </button>
          <ul className="dropdown-menu">
            <li className="dropdown-item pointer" onClick={()=> addTypes(2)}>Add 2</li>
            <li className="dropdown-item pointer" onClick={()=> addTypes(3)}>Add 3</li>
            <li className="dropdown-item pointer" onClick={()=> addTypes(4)}>Add 4</li>
            <li className="dropdown-item pointer" onClick={()=> addTypes(5)}>Add 5</li>
            <li className="dropdown-item pointer" onClick={()=> addTypes(6)}>Add 6</li>
            <li className="dropdown-item pointer" onClick={()=> addTypes(7)}>Add 7</li>
            <li className="dropdown-item pointer" onClick={()=> addTypes(8)}>Add 8</li>
            <li className="dropdown-item pointer" onClick={()=> addTypes(9)}>Add 9</li>
            <li className="dropdown-item pointer" onClick={()=> addTypes(10)}>Add 10</li>
          </ul>
        </div>
        <div onClick={toggleShow} className="pointer ms-1 px-3"><RotatingCaret rotated={show} /></div>
      </div>
      <Collapse in={show}>
        <div className="px-2">
          { types.length === 0 && <p className="text-muted">No types yet.</p>}
          { types.length > 0 && (
            <Fragment>
              <div className="d-flex">
              <div style={{width:31}}></div>
              <div style={{width:31}}>{/* fill color */}</div>
              <div style={{width:38}}>{/* edit icon */}</div>
                <div className="text-muted" style={{width:80}}>Code</div>
                <div className="text-muted">Description</div>
              </div>
              { types.sort((a, b) => ((a || {}).label || '').localeCompare((b || {}).label || '', undefined, { numeric: true, sensitivity: 'base' }))
                 .map(type => <TypeRow key={type.appId} obj={type} setTypes={setTypes} selected={selected} setSelected={setSelected} toParent={fromChild} />)}
            </Fragment>
          )}
          <div className="mt-4 mb-3">
            <DeleteBtn toParent={fromChild} text="Delete Category" />
          </div>
        </div>
      </Collapse>
    </div>
  )
}

export default View;
