

// right now this assumes we aren't nesting multiple cols within each other
// not sure if that will matter at any point...?

// how to use:
// let obj = formObj(schema); <-- when you want an empty obj for blank forms
// let obj = formObj(schema, data); <-- when you want to be able to reset the form with data values

export const formObj = (schema, data) => {
  let obj = {};
  if(!data) data = {};

  let iterate = (items) => {
    for(const item of items) {
      if(!item.field) continue;
      if(item.array)
        obj[item.field] = data[item.array][item.index] ? data[item.array][item.index] : '';
      else
        obj[item.field] = data[item.field] ? data[item.field] : null;
    }
  }

  for(const item of schema) {
    if('col' in item) iterate(item.col);
    if('col1' in item) iterate(item.col1);
    if('col2' in item) iterate(item.col2);
    if('col3' in item) iterate(item.col3);
  }

  return obj;
}

// this tests for square brackets and removes the values
export const removeArrs = (data) => {
  let regex = /\(|\)|\[|\]/g;
  for (const [key, val] of Object.entries(data)) {
    if(regex.test(key)) {
      delete data[`${key}`];
    }
  }
  return data;
}
