import dayjs from 'dayjs'
import { nl2br } from './functions';

// lklklk future: move the email templates to system/company variables
// maybe instead of general "system email" we just have the html built in with things like [[udf2]]
// will need to update the template searches to look for a company email id, THEN system id

// const msg = {
//   to: [{email:'lauren@laurenkelly.com',name: 'Lauren Kelly'}],
//   from: {
//     email: 'lauren@mustardsquare.com',
//     name: 'WGS Support'
//   }, // Use the email address or domain you verified above
//   subject: '2 - Sending with Twilio SendGrid is Fun',
//   text: 'and easy to do anywhere, even with Node.js',
//   html: '<strong>and easy to do anywhere, even with Node.js</strong>',
// };

export const formatEmail = ({ template, html, senderObj, recipientIds, subject, emailData, logObj }) => {
  // template is from the template schema
  // senderObj is from the config, e.g. wayfinding@flysfo.com
  // recipient IDs is an array of ids that the server will parse
  // emailData are the pieces of info that we swap
  // log obj includes createdAt and createdBy of the user:
    // different from userDetails in case someone is filing a ticket for another person
    // in that case, userDetails would be the person they are filing the ticket for
    // createdBy/createdAt would be the ID of the person who is filing it

  html = html.replace('[[email-preheader]]', emailData.name);
  html = html.replace('[[email-subject]]', emailData.name);
  html = html.replace('[[email-title]]', emailData.name);

  if(template === 'new ticket')
    html = newTicket({ html, emailData });
  else if(template === 'ticket received')
    html = ticketReceived({ html, emailData });
  else if(template === 'task assigned')
    html = taskAssigned({ html, emailData });

  // log obj sends the createdBy id; append createdAt
  if(logObj) logObj.createdAt = dayjs().format('YYYY-MM-DD HH:mm:ss');

  return { senderObj, recipientIds, subject, html, logObj };
}


const newTicket = ({ html, emailData }) => {
  return html.replace('[[email-content]]', `
    ${emailData.udf2 ? `<b>Issue type</b><br />${emailData.udf2}` : ''}
    ${emailData.udf3 ? `› ${emailData.udf3}<br />` : '<br />'}
    ${emailData.udf1 ? `<br /><b>Issue location</b><br />${emailData.udf1}<br />` : ''}
    ${emailData.dueBy ? `<br /><b>Desired due date</b><br />${emailData.dueBy}<br />` : ''}
    ${emailData.udf4 ? `<br /><b>Issue description</b><br />${nl2br(emailData.udf4)}<br />` : ''}
    <br />
    <b>Submitted by</b><br />
    <a href="mailto:${emailData.email}">${emailData.firstName} ${emailData.lastName}</a><br />
    <br />
    <a href="https://wgs.flysfo.io/tasks">Click here</a> to view the ticket.
  `);
}

const ticketReceived = ({ html, emailData }) => {
  return html.replace('[[email-content]]', `
    You submitted a new ticket.<br /><br />
    ${emailData.udf2 ? `<b>Issue type</b><br />${emailData.udf2}` : ''}
    ${emailData.udf3 ? `› ${emailData.udf3}<br />` : '<br />'}
    ${emailData.udf1 ? `<br /><b>Issue location</b><br />${emailData.udf1}<br />` : ''}
    ${emailData.dueBy ? `<br /><b>Desired due date</b><br />${emailData.dueBy}<br />` : ''}
    ${emailData.udf4 ? `<br /><b>Issue description</b><br />${nl2br(emailData.udf4)}<br />` : ''}
  `)
}

const taskAssigned = ({ html, emailData }) => {
  return html.replace('[[email-content]]', `
    You've been assigned a new task.<br />
    ${emailData.udf2 ? `<br /><b>Issue type</b><br />${emailData.udf2}` : ''}
    ${(emailData.udf2 && emailData.udf3) ? `› ${emailData.udf3}<br />` : '<br />'}
    ${emailData.udf1 ? `<br /><b>Location</b><br />${emailData.udf1}<br />` : ''}
    ${emailData.udf5 ? `<br /><b>Work Order #</b><br />${emailData.udf5}<br />` : ''}
    ${emailData.dueBy ? `<br /><b>Due Date</b><br />${emailData.dueBy}<br />` : ''}
    ${emailData.udf4 ? '<br /><b>Description</b><br />' : ''}
    ${nl2br(emailData.udf4)}<br />
    <br />
    <b>Assigned by</b><br />
    <a href="mailto:${emailData.email}">${emailData.firstName} ${emailData.lastName}</a><br />
    <br />
    <a href="https://wgs.flysfo.io/tasks">Click here</a> to view.`);
}





//
