import React, { Fragment } from 'react';

import { filterExists, toggleFilter } from '../../../utils/filters'

const View = (props) => {
  const { arr, field, title, filters, setFilters, group } = props;

  return (
    <Fragment>
      { arr && (
        <Fragment>
          <li><h6 className="dropdown-header">{title}</h6></li>
          <div className="overflow-scroll" style={{maxHeight:284}}>
          { arr.map((obj) => (
            <li key={'typeId-'+obj.value} className="dropdown-item" style={{whiteSpace:'inherit'}}>
              <div className="form-check">
                <input className="form-check-input" type="checkbox"
                  id={'status-'+obj.value} checked={filterExists(filters, obj.value, group)}
                  onChange={() => { toggleFilter(filters, setFilters, obj.value, group, (x) => x[field] === obj.value) }}
                />
                <label className="form-check-label" htmlFor={'status-'+obj.value}>
                  {obj.label ? obj.label : obj.value}
                </label>
              </div>
            </li>
          ))}
          </div>
        </Fragment>
      )}
    </Fragment>
  )
}

export default View;
