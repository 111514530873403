import React, { useContext, useEffect, useState } from 'react';

import { UserContext } from '../../../context/UserContext';
import { nl2br } from '../../../utils/functions'
import parse from 'html-react-parser';
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime)

const View = (props) => {
  const { idx, length, obj } = props;
  const { allUsers } = useContext(UserContext);
  const [author, setAuthor] = useState({})

  useEffect(() => {
    let found = allUsers.find(x => x.id === obj.createdBy);
    if(found) setAuthor(found);
  }, [allUsers, obj])

  return (
    <div className={`d-flex align-items-start p-3 ${(idx+1 < length) ? "border-bottom" : ""}`}>
      <div className="flex-grow-1">
        <small className="float-end text-muted">{dayjs(obj.createdAt).fromNow()}</small>

        <p className="mb-2">{parse(nl2br(obj.content))}</p>
        <p className="small text-muted">
          <a href={`mailto:${author.email}`}>{author.firstName} {author.lastName}</a><br />
          {dayjs(obj.createdAt).format('MMM D, YYYY h:mma')}
        </p>


      </div>
    </div>
  )
}

export default View;
