import React, { Fragment, useEffect } from 'react';

import { checkConditions } from './forms/_utils/conditional';

import Html from './forms/Html'
import Hidden from './forms/Hidden'
import Input from './forms/Input'
import Checkbox from './forms/Checkbox'
import Select from './forms/Select'
import Textarea from './forms/Textarea'
import ButtonGroup from './forms/ButtonGroup'
import FloatingInput from './forms/FloatingInput'
import FloatingSelect from './forms/FloatingSelect'
import FloatingTextarea from './forms/FloatingTextarea'

import Autosuggest from './forms/Autosuggest'
import Attachments from './forms/Attachments'

import Access from './forms/Access'
import GroupSelection from './forms/GroupSelection'
import TypeSelection from './forms/TypeSelection'

import Icon from './forms/Icon'
import ColorPicker from './forms/ColorPicker'
import CheckboxSelect from './forms/CheckboxSelect'
import Redactor from './forms/Redactor'

import StylingMap from './forms/StylingMap'

// product- specific
import WGSLocation from './forms/_products/sfo/Location';

// consider adding a Collapse thing to the column
// it could watch for a value and then would animate if the value exists


// still to do:
// buttongroup
// checkbox
// checkboxes
// dropzone (?)
// html
// radio buttons

// how to use:
// <Generated id="yes-this-is-required" schema={schema} size="sm" pieces={{ register, control, setValue, watch, errors, toParent: fromChild }} />

const View = (props) => {
  const { schema, pieces } = props;
  const formId = props.id ? props.id : 'form';
  const layout = props.layout ? props.layout : 'vertical';
  pieces.size = props.size ? props.size : 'sm';
  pieces.formId = formId;

  useEffect(() => {
    // set any manual form values (i.e. values not sent in the active object/record)
    // mainly using this in "new" forms where we want to pass default values
    if(!pieces.vals) return;
    for (const [key, value] of Object.entries(pieces.vals)) {
      pieces.setValue(key, value);
    }
  }, [pieces])

  return (
    <Fragment>
      { schema.map((item) => findType({ formId, layout, item, pieces }) )}
    </Fragment>
  )
}

const findType = (props) => {
  const { formId, layout, item, pieces } = props;
  if(!item) return;

  item.errorMsg = (pieces.errors[item.field] && pieces.errors[item.field].message) ? pieces.errors[item.field].message : 'This field is required';

  let display = item.conditional ? checkConditions(item, pieces.watch) : true;
  if(display === 'error') return <div key={formId+item.id} className="bg-danger text-white p-3">Conditional logic error.</div>
  if(!display) return;

  if(item.type.includes('col-'))
    return <Col key={formId+item.id} formId={formId} layout={layout} obj={item} pieces={pieces} />;

  switch (item.type) {
    case 'input':
      return <Input key={formId+item.id} {...pieces.register(item.field)} item={item} pieces={pieces} />;
    case 'floating-input':
      return <FloatingInput key={formId+item.id} {...pieces.register(item.field)} item={item} pieces={pieces} />;
    case 'button-group':
      return <ButtonGroup key={formId+item.id} {...pieces.register(item.field)} item={item} pieces={pieces} />;
    case 'checkbox':
      return <Checkbox key={formId+item.id} {...pieces.register(item.field)} item={item} pieces={pieces} />;
    case 'select':
      return <Select key={formId+item.id} {...pieces.register(item.field)} item={item} pieces={pieces} />;
    case 'floating-select':
      return <FloatingSelect key={formId+item.id} {...pieces.register(item.field)} item={item} pieces={pieces} />;
    case 'autosuggest':
      return <Autosuggest key={formId+item.id} {...pieces.register(item.field)} item={item} pieces={pieces} />;
    case 'textarea':
      return <Textarea key={formId+item.id} {...pieces.register(item.field)} item={item} pieces={pieces} />;
    case 'floating-textarea':
      return <FloatingTextarea key={formId+item.id} {...pieces.register(item.field)} item={item} pieces={pieces} />;
    case 'hidden':
      return <Hidden key={formId+item.id} {...pieces.register(item.field)} item={item} pieces={pieces} />;
    case 'group-selection':
      return <GroupSelection key={formId+item.id} {...pieces.register(item.field)} item={item} pieces={pieces} />;
    case 'type-selection':
      return <TypeSelection key={formId+item.id} {...pieces.register(item.field)} item={item} pieces={pieces} />;
    case 'access':
      return <Access key={formId+item.id} {...pieces.register(item.field)} item={item} pieces={pieces} />;
    case 'attachments':
      return <Attachments key={formId+item.id} {...pieces.register(item.field)} item={item} pieces={pieces} />;
    case 'checkbox-select':
      return <CheckboxSelect key={formId+item.id} {...pieces.register(item.field)} item={item} pieces={pieces} />;
    case 'color-picker':
      return <ColorPicker key={formId+item.id} {...pieces.register(item.field)} item={item} pieces={pieces} />;
    case 'redactor':
      return <Redactor key={formId+item.id} {...pieces.register(item.field)} item={item} pieces={pieces} />;
    case 'wgs-location':
      return <WGSLocation key={formId+item.id} {...pieces.register(item.field)} item={item} pieces={pieces} />;
    case 'styling-map':
      return <StylingMap key={formId+item.id} {...pieces.register(item.field)} item={item} pieces={pieces} />;
    case 'icon':
      return <Icon key={formId+item.id} item={item} pieces={pieces} />;
    case 'html':
      return <Html key={formId+item.id} item={item} />;
    default:
      return <div key={formId+item.id} className="bg-danger text-white p-3">type not found: {item.type}</div>;
  }
}

const Col = (props) => {
  const { formId, obj, layout, pieces } = props;

  if(layout==='horizontal') {
    // need to filter out hidden columns and append below table
    let hidden = obj.col.filter(x => x.type === 'hidden');
    let visible = obj.col.filter(x => x.type !== 'hidden');

    return (
      <Fragment>
        <table className="table table-borderless table-sm my-0">
          <tbody>
            <tr>
            { visible.map((item) => (
              <td key={formId+item.id} style={{ width: item.width ? `${item.width}px` : 'auto'}}>
                { findType({ formId, item, layout, pieces }) }
              </td>
            ))}
            </tr>
          </tbody>
        </table>
        { hidden.map(item => findType({ formId, item, layout, pieces }))}
      </Fragment>
    )
  } else {
    return (
      <div className="row g-2">
        { obj.type === 'col-4' && (
          <Fragment>
            <div className="col-sm-4">
              { obj.col1?.map((item) => findType({ formId, item, layout, pieces }) )}
            </div>
            <div className="col-sm-4">
              { obj.col2?.map((item) => findType({ formId, item, layout, pieces }) )}
            </div>
            <div className="col-sm-4">
              { obj.col3?.map((item) => findType({ formId, item, layout, pieces }) )}
            </div>
          </Fragment>
        )}

        { obj.type === 'col-6' && (
          <Fragment>
            <div className="col-sm-6">
              { obj.col1?.map((item) => findType({ formId, item, layout, pieces }) )}
            </div>
            <div className="col-sm-6">
              { obj.col2?.map((item) => findType({ formId, item, layout, pieces }) )}
            </div>
          </Fragment>
        )}
  
        { obj.type === 'col-12' && (
          <div className="col">
            { obj.col?.map((item) => findType({ formId, item, layout, pieces }) )}
          </div>
        )}
      </div>
    )
  }
}

export default View;
