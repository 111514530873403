// future:
// add 'values' so you can check if any of them exist...?

export const checkConditions = (item, watch) => {
  if(!item.conditional.conditions) return 'error';
  if(!item.conditional.type) item.conditional.type = 'AND';

  let condsMet = 0;
  for (const condition of item.conditional.conditions){
    if(!condition.type) condition.type = 'EQUAL';
    if(!condition.value) condition.type = 'ANY';
    let field = watch(condition.field, null);

    if(condition.type==='ANY' && field) condsMet++;
    else if(condition.type==='EQUAL' && field===condition.value) condsMet++;
    else if(field && condition.type==='GREATER' && Number(field) > Number(condition.value)) condsMet++;
    else if(field && condition.type==='GREATER EQUAL' && Number(field) >= Number(condition.value)) condsMet++;
    else if(field && condition.type==='LESS' && Number(field) < Number(condition.value)) condsMet++;
    else if(field && condition.type==='LESS EQUAL' && Number(field) <= Number(condition.value)) condsMet++;
  }

  if(item.conditional.type==='AND' && condsMet < item.conditional.conditions.length) return false;
  else if(item.conditional.type==='OR' && condsMet===0) return false;
  else if(item.conditional.type==='NOT' && condsMet>0) return false;
  return true;
}