import React, { forwardRef } from 'react'
import { Controller } from 'react-hook-form'

// how to use:
// <FloatingSelect key={item.id} {...register(item.field)} item={item} pieces={{ control, errors, toParent, size }} />

// schema example:
// { id: '__NEW_ID__', field: '__DB_FIELD__', type: 'floating-select', label: 'ze label', required: 'wow u fucked', options: [{label: 'Opt 1', value: 1}, {value: 2}]},

const Field = forwardRef(({ item, pieces, disabled, ...rest }, ref) => {
  if(!item.options) item.options = [];
  if(!pieces.vars) pieces.vars = {};
  if(!pieces.vars[item.optionVar]) pieces.vars[item.optionVar] = [];

  return (
    <Controller
      name={item.field}
      control={pieces.control}
      rules={{ required: item.required }}
      render={({ field }) => {
        if(!field.value) field.value = '';

        return (
          <div className="form-floating my-2">
            <select {...field}
              ref={field.ref}
              name={item.field}
              placeholder={item.label}
              value={field.value}
              className={`form-select ${(pieces.size) ? "form-select-"+pieces.size : ""}`}
              onChange={(e) => {
                e.target.blur();
                // create a tempArray if needed
                let val = item.tempArray ? [String(e.target.value)] : e.target.value;
                pieces.setValue(`${item.field}`, val);
                if(pieces.toParent) pieces.toParent({ type: 'select', name: item.field, value: val });
              }}
              disabled={item.disabled}>
              { item.options.map((opt, idx) => {
                // these options are hard-coded into the form schema
                // leaving this here in case you want to append TBD/null etc options
                if(!opt.label) opt.label = opt.value;
                return (
                  <option key={`${item.field}-select-arr-${idx}`} value={opt.value}>{opt.label}</option>
                )
              })}

              { pieces.vars[item.optionVar].map((opt, idx) => {
                // these options are based on a schema/variable array
                if(!opt.label) opt.label = opt.value;
                return (
                  <option key={`${item.field}-select-var-${idx}`} value={opt.value}>{opt.label}</option>
                )
              })}
            </select>
            {item.label && (<label htmlFor={item.field}>{item.label}</label>)}
            {pieces.errors[item.field] && <p className="mt-0 error">{item.errorMsg}</p>}
          </div>
        )
      }}
    />
  )
});

export default Field;
