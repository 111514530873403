import React from 'react';
import { useOutletContext } from 'react-router-dom'

import FormBasic from '../../../components/system/FormBasic'
import Comment from './Comment';

const View = (props) => {
  const { parentType, parentId, comments, setComments } = props;
  // threads, setThreads,

  const schema = [
    { id: '1', type: 'col-12', label: '1', col: [
      // { id: '2', type: 'access', label: 'Set access levels', field: 'access' },
      { id: '3', type: 'floating-textarea', label: 'Add to the discussion', field: 'content', required: 'Please enter a comment', rows: 5 },
    ]},
  ]

  const fromForm = (data) => {
    const { type, response } = data;
    if(type === 'submitted') {
      setComments((prev) => {
        let all = [...prev];
        all.unshift(response);
        return all;
      })
    }
    console.log(data);
  }

  return (
    <div className="row h-100 overflow-hidden">
      <div className="col-sm-8 h-100 overflow-scroll">
        <div className="mb-3 p-2 bg-white rounded">
          { comments.length === 0 && <p className="p-3 mb-0">No discussion yet.</p>}
          { comments.map((obj, idx) => {
            return <Comment key={obj.appId} idx={idx} length={comments.length} obj={obj} />
          })}
        </div>
      </div>
      <div className="col-sm-4">
        <h5 className="mb-1">Add a Comment</h5>
        <FormBasic debug={false} method="POST" url="/system/discussion/comments" clearSubmit={true}
          vals={{  parentType, parentId, threadId: 22 }} createForm={true} toParent={fromForm} schema={schema}
          formText={{ submit: 'Add', pending: 'Adding', success: 'Added!', cancel: 'Cancel' }}
        />
      </div>
    </div>
  )
}

export const Discussion = (props) => {
  const { parentType, parentId, threads, setThreads, comments, setComments } = props;
  return <View parentType={parentType} parentId={parentId} threads={threads} setThreads={setThreads} comments={comments} setComments={setComments} />
}

export const DiscussionOutlet = (props) => {
  const { parentType, parentId, threads, setThreads, comments, setComments } = useOutletContext();
  return <View parentType={parentType} parentId={parentId} threads={threads} setThreads={setThreads} comments={comments} setComments={setComments} />
}

// could also do DiscussionParam which would grab the object from the server...?
