

const makeReport = ({ activeQtys }) => {
  // console.log(activeQtys);
  let body = activeQtys.map((x) => {
    if(x.typeId==='total') 
      return ['',{text: 'Total', alignment: 'right'}, {text: x.quantity, alignment: 'right'}];
    else
      return [x.code, x.description, {text: x.quantity, alignment: 'right'}];
  });
  
  body.unshift(['Code', 'Description', {text: 'Quantity', alignment: 'right'}]);

  let report = {
    pageSize: 'LETTER',
    pageOrientation: 'portrait',
    pageMargins: [25, 25, 25, 70],
    content: [
      {
        table: {
          widths: [50, '*', 60],
          body: body
        },
        layout: 'lightHorizontalLines', // headerLineOnly, lightHorizontalLines, noBorders
  		},
    ],
    footer: {
      margin: [25, 25],
      columns: [
        'Quantities',
        // { text: 'Right part', alignment: 'right' }
      ]
    }
  }
  return report;
}

export default makeReport;