import React, { Fragment } from 'react';
import { Lightning, LightningFill } from 'react-bootstrap-icons'
import Select from 'react-select';

const View = (props) => {
  const { quickAdd, quickRef, toggle, types } = props;

  return (
    <Fragment>
      { quickAdd && (
        <div className="d-inline-block me-2" style={{width:200}}>
          <Select
            isClearable
            blurInputOnSelect
            classNamePrefix="select"
            placeholder="Select type"
            onChange={(e) => {
              let val = e?.value ? e.value : null;
              quickRef.current = val;
            }}
            options={types}
          />
        </div>
      )}
      <button className="btn bg-white border rounded me-2" onClick={toggle}>
        { !quickAdd && <Lightning size={18} />}
        {  quickAdd && <LightningFill size={18} />}
      </button>
    </Fragment>
  )
}

export default View;
