import React, { Fragment, useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom'
import { useAxios } from '../../../../hooks/useAxios';
import { Helmet } from 'react-helmet';
import dayjs from 'dayjs';

// if task is labeled as "In Review", perhaps we auto-show the table?
// if it's in progress, do we show the modal instructions? (text below)

// future: you may need to move the "Add to project" into history
// and in that case we'd copy from a specific one instead of the last one

import { SiteContext } from '../../../../context/SiteContext';
import { UserContext } from '../../../../context/UserContext';

import { plans } from '../../../../components/_products/sfo/plans';

import Loading from '../../../../components/system/Loading';
import Header  from '../../../../components/system/HeaderTask';
import Filters, { applyFilters } from '../../../mapping/plots/components/Filters';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import GisMap from '../../../../components/mapping/GisMap';
import PlotHistory from '../../../mapping/plots/History';
import PlotEdit from '../../../mapping/plots/components/Edit';
import Review from './ApplicationReview';

import { emptyObj } from '../../../../utils/functions';
import { getPlotDisplay } from '../../../../utils/plots';

const View = (props) => {
  const params = useParams();
  const { allLoaded, setAllLoaded, project, projTasks, types, setTypes, activePhase, activePlan, setActivePlan, plots, setPlots, photos, setPhotos } = props.context;
  const { config } = useContext(SiteContext);
  const { userDetails } = useContext(UserContext);
  
  const { serverCall, pending } = useAxios();
  const closePanel = () => {
    setActiveAppId();
    setActivePlot({});
    setPlotHistory([]);
    setPlotPhotos([]);
  }

  const [task, setTask] = useState({});
  const [libraries, setLibraries] = useState([]);

  // lklklk this should be set within the project...?
  const [mapStart, setMapStart] = useState({ zoom: 20, center: [37.61797713168521, -122.3867189884186] })
  const [activeAppId, setActiveAppId] = useState();
  const [activePlot, setActivePlot] = useState({});
  const [plotHistory, setPlotHistory] = useState([]);
  const [plotPhotos, setPlotPhotos] = useState([]);

  const [bounds, setBounds] = useState([]);
  const [filters, setFilters] = useState([]);
  const [search, setSearch] = useState();

  // note: not currently sending the activePhase, does that matter?
  const activePlots = useMemo(() => applyFilters(activePhase, activePlan, plots, filters, search, bounds, project.id), [activePhase, activePlan, plots, filters, search, bounds, project.id]);

  useEffect(() => {
    let found = projTasks.find(x => x.appId === params.taskId);
    if(!found) return; // lklklk
    setTask(found);
  }, [params.taskId, projTasks])

  useEffect(() => {
    let plan = plans.find(x => x.id === 2);
    if(plan) setActivePlan(plan);
  }, [setActivePlan])

  useEffect(() => {
    if(allLoaded || emptyObj(userDetails)) return;
    (async () => {
      let res = await serverCall({
        method: 'GET', url: `/mapping/plots/all`, eamsAuth0: true,
        headers: { 'eams-key': userDetails.email, 'eams-access': userDetails.uuid }
      });
      if(res.status!==200) return alert('Error getting all plots. Contact support.'); // lklklk
      if(res.data.libraries) setLibraries(res.data.libraries);
      if(res.data.types) setTypes(res.data.types);
      if(res.data.photos) setPhotos(res.data.photos);

      // spin through to format the plot display and store within each individual plot
      let formatted = [];
      for (const plot of res.data.plots) {
        let newPlot = getPlotDisplay({ types: res.data.types, plot });
        formatted.push(newPlot);
      }
      setPlots(formatted);
      setAllLoaded(true);
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allLoaded, userDetails])

  useEffect(() => {
    if(emptyObj(activeAppId)) {
      setActivePlot({});
      setPlotHistory([]);
      setPlotPhotos([]);
    } else {
      let found = plots.find(x => x.appId === activeAppId && x.projectId === project.id && x.phaseId === activePhase.id);
      (found) ? setActivePlot(found) : setActivePlot({});

      let history = plots.filter(x => x.status==='A' && x.appId === activeAppId && (x.projectId !== project.id || x.phaseId !== activePhase.id)).sort((a,b) => (b.id > a.id) ? 1 : ((a.id > b.id) ? -1 : 0));
      setPlotHistory(history);

      let pics = photos.filter(x => x.plotAppId === activeAppId);
      setPlotPhotos(pics);
    }
  }, [activeAppId, activePhase.id, project.id, plots, photos])

  const fromChild = (data) => {
    const { type, value } = data;
    if(type==='plan change') {
      let plan = plans.find(x => x.appId === value);
      if(plan) setActivePlan(plan);

    } else if(type==='view plot') {
      setActiveAppId(value.appId);
    }
  }

  const addToProject = async () => {
    console.log('adding');
    let data = {...plotHistory[0]};
    delete data.id;
    delete data.updatedAt;
    delete data.updatedBy;
    data.projectId = project.id;
    data.phaseId = activePhase.id;
    data.createdAt = dayjs().format('YYYY-MM-DD HH:mm:ss');
    data.createdBy = userDetails.id;
    data.status = 'A';

    let returned = await serverCall({ method: 'POST', data, url: `/mapping/plots`, eamsAuth0: true });
    if(returned.status!==200) return alert('Error getting adding to project. Contact support.'); // lklklk

    // format new plot and add to appropriate states
    // lklklk you may need to switch getPlotDisplay to be a promise, 
    // this isn't coming back correctly right now
    let formatted = getPlotDisplay({ types, plot: returned.data });

    await setPlots((prev) => {
      let arr = [...prev];
      arr.push(formatted);
      return arr;
    })

    setActivePlot(formatted);
  }

  if(!allLoaded) return <Loading />;

  return (
    <Fragment>
      <Helmet>
        <title>Application | {config.title}</title>
        <style>{`html, body, div#root { height: 100%; }`}</style>
      </Helmet>
      <Header task={task} />
      { !params.mode && (
        <Fragment>
          <div className="position-fixed" style={{ top:64, right: 12, zIndex: 999 }}>
            <Filters search={search} setSearch={setSearch} filters={filters} setFilters={setFilters} />
          </div>
          <div className="position-fixed w-100 h-100" style={{top:0, left:0, marginTop:53}}>
            <GisMap zoom={mapStart.zoom} center={mapStart.center} draggable={true} plans={plans} plots={activePlots} setBounds={setBounds} toParent={fromChild} />
          </div>
        </Fragment>
      )}
      { params.mode === 'review' && (
        <Review task={task} project={project} activePhase={activePhase} types={types} plots={plots} setPlots={setPlots} photos={photos} setPhotos={setPhotos} />
      )}
      <Offcanvas show={plotHistory.length > 0} onHide={closePanel} placement="end" scroll={true} backdrop={false} className="shadow">
        <Offcanvas.Header className="bg-body" closeButton>
          <Offcanvas.Title>{ emptyObj(activePlot) ? `Viewing ${plotHistory[0]?.name}` : `Editing ${activePlot.name}`} </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="bg-body pt-0">
          { emptyObj(activePlot) && (
            <Fragment>
              <button onClick={addToProject} className="btn btn-primary d-block mb-2" disabled={pending}>
                { !pending && 'Add to this Project' }
                {  pending && 'Please wait...' }
              </button>
              <PlotHistory plots={plotHistory} photos={plotPhotos} libraries={libraries} types={types} />
            </Fragment>
          )}

          { !emptyObj(activePlot) && (
            <Tabs defaultActiveKey="details" id="edit-tabs" className="mb-3">
              <Tab eventKey="details" title="Details">
                <PlotEdit obj={activePlot} setActivePlot={setActivePlot} project={project} activePhase={activePhase} types={types} setPlots={setPlots} photos={photos} setPhotos={setPhotos} handleClose={closePanel} toParent={fromChild} />
              </Tab>
              <Tab eventKey="history" title="History">
                <PlotHistory plots={plotHistory} photos={plotPhotos} libraries={libraries} types={types} />
              </Tab>
            </Tabs>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </Fragment>
  )
}

export default View;


// xs modal instructions:
// Sign placement and mounting
// Select and place relevant sign types based on circulation and decision point planning.