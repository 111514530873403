import React, { forwardRef } from 'react'
import { Controller } from 'react-hook-form'
import dayjs from 'dayjs'

// how to use:
// <FloatingInput item={{ field: 'name', label: 'Page Name' }} pieces={{ register, control, errors, toParent: fromChild }} />

// schema example:
// { id: '__NEW_ID__', field: '__DB_FIELD__', type: 'input', inputType: 'number', label: 'ze label', required: 'wow u fucked', note: 'some other note' },

const Field = forwardRef(({ item, pieces, disabled, ...rest }, ref) => {
  if(!item.inputType) item.inputType = 'text';

  return (
    <Controller
      name={item.field}
      control={pieces.control}
      rules={{ required: item.required }}
      render={({ field }) => {
        if(!field.value) field.value = '';

        if(item.inputType==='date' && item.prefill!==false)
          field.value = field.value ? dayjs(field.value).format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD');
        else if(item.inputType==='datetime-local' && item.prefill!==false)
          field.value = field.value ? dayjs(field.value).format('YYYY-MM-DDTHH:mm') : dayjs().format('YYYY-MM-DDTHH:mm');

        return (
          <div className="form-floating my-2">
            {item.note && (<p className="mb-1 text-muted fst-italic small">{item.note}</p>)}
            <input {...field}
              ref={field.ref}
              type={item.inputType}
              name={item.field}
              value={field.value}
              placeholder={item.label}
              className="form-control"
              onBlur={(e) => {
                if(pieces.toParent) pieces.toParent({ type: 'input', name: item.field, value: e.target.value });
              }}
              disabled={item.disabled} />
            {pieces.errors[item.field] && <p className="mt-0 error">{item.errorMsg}</p>}
            {item.label && (<label htmlFor={item.field}>{item.label}</label>)}
          </div>
        )
      }}
    />
  )
});

export default Field;
