import React, { forwardRef, useEffect, useState } from 'react';

// note: this either accepts a string to look for,
// OR an object with a key including "id"
// if you need other keys or options they need to be added

const Field = forwardRef(({ pieces }, ref) => {
  const value = pieces.vars.value;
  const selected = pieces.vars.selected;
  const [checked, setChecked] = useState(false);

  const toggle = (e) => {
    e.target.blur();
    if(pieces.toParent) pieces.toParent({ type: 'checkbox-select', value: { value, checked: e.target.checked }});
  }

  useEffect(() => {
    if(!value) return;
    if (typeof value === 'object' && !Array.isArray(value)) {
      let found = selected.filter(x => x.id === value.id);
      (found.length > 0) ? setChecked(true) : setChecked(false);
    } else {
      setChecked(selected.includes(value));
    }
  }, [value, selected])

  return (
    <div className="form-check my-2">
      <input className="form-check-input" type="checkbox"
        id={'selected-'+value}
        value={value}
        checked={checked}
        onChange={toggle}
        ref={ref}
      />
    </div>
  )
});

export default Field;

