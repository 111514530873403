export const plans = [
  { id: 1, name: 'Level 1', appId: 'o4kxexc1jHH37a2OYmO5C', url: 'https://sfo-dcgis02.flysfo.com/arcgis/rest/services/CustomServices/MustardSquareLevel1/MapServer'},
  { id: 2, checked: true, name: 'Level 2', appId: 'kAMUxdCyHK2Fn9ePJeAcA', url: 'https://sfo-dcgis02.flysfo.com/arcgis/rest/services/CustomServices/MustardSquareLevel2/MapServer'},
  { id: 3, name: 'Level 3', appId: 'b2etzRevbXs16j76wFFMJ', url: 'https://sfo-dcgis02.flysfo.com/arcgis/rest/services/CustomServices/MustardSquareLevel3/MapServer'},
  // { id: 4, name: 'Level 1', appId: 'o4kxexc1jHH37a2OYmO5C', url: 'https://sfo-dcgis01.flysfo.com/arcgis/rest/services/BASEMAP/Level_1/MapServer'},
  // { id: 5, name: 'Level 2', appId: 'kAMUxdCyHK2Fn9ePJeAcA', url: 'https://sfo-dcgis01.flysfo.com/arcgis/rest/services/BASEMAP/Level_2/MapServer'},
  // { id: 6, name: 'Level 3', appId: 'b2etzRevbXs16j76wFFMJ', url: 'https://sfo-dcgis01.flysfo.com/arcgis/rest/services/BASEMAP/Level_3/MapServer'},
  { id: 4, name: 'Level 4', appId: '59lKwmXfay90u4SBuB3mL', url: 'https://sfo-dcgis01.flysfo.com/arcgis/rest/services/BASEMAP/Level_4/MapServer'},
  { id: 5, name: 'Level 5', appId: 'HcmjrVvztdzhisk3tNbPL', url: 'https://sfo-dcgis01.flysfo.com/arcgis/rest/services/BASEMAP/Level_5/MapServer'},
  { id: 6, name: 'Level 6', appId: '0aZZdUQ6m5lDmBBE95bft', url: 'https://sfo-dcgis01.flysfo.com/arcgis/rest/services/BASEMAP/Level_6/MapServer'},
  { id: 7, name: 'Level 7', appId: 'T5QevgsQfYbqQCp2RynEy', url: 'https://sfo-dcgis01.flysfo.com/arcgis/rest/services/BASEMAP/Level_7/MapServer'},
  { id: 8, name: 'Level 8', appId: 'tqngYFnofaWMccNEKIWcy', url: 'https://sfo-dcgis01.flysfo.com/arcgis/rest/services/BASEMAP/Level_8/MapServer'},
  { id: 9, name: 'Level 9', appId: 'hIbYH6IFuwv9DrEKlygmf', url: 'https://sfo-dcgis01.flysfo.com/arcgis/rest/services/BASEMAP/Level_9/MapServer'},
  { id: 10, name: 'Level 10', appId: 'XUDkQBKcefpG7N2Ndk1Bp', url: 'https://sfo-dcgis01.flysfo.com/arcgis/rest/services/BASEMAP/Level_10/MapServer'},
  { id: 11, name: 'Level Mezzanine', appId: 'jWTsjmAilC6vZvkUmWVhr', url: 'https://sfo-dcgis01.flysfo.com/arcgis/rest/services/BASEMAP/Level_Mezzanine/MapServer'},
  { id: 12, name: 'Basement', appId: '42EuoLBnkIvJl6WPPoINh', url: 'https://sfo-dcgis01.flysfo.com/arcgis/rest/services/BASEMAP/Basement/MapServer'},
  { id: 13, name: 'AirTrain', appId: 'H2lfIxWTU2wxtRuibI6IC', url: 'https://sfo-dcgis01.flysfo.com/arcgis/rest/services/BASEMAP/AirTrain/MapServer'},
  { id: 14, name: 'Roadway', appId: 'ztvrvJh67QHfHAUSVsIaY', url: 'https://sfo-dcgis01.flysfo.com/arcgis/rest/services/BASEMAP/SFO_Base_Map/MapServer/14'},
  { id: 15, name: 'Fence', appId: 'fWghnV4xABBTWkiYmV8SS', url: 'https://sfo-dcgis01.flysfo.com/arcgis/rest/services/BASEMAP/SFO_Base_Map/MapServer/5'},
  { id: 16, name: 'Taxiway Elements', appId: '2psDuS5aspKQUF9iPZwGm', url: 'https://sfo-dcgis01.flysfo.com/arcgis/rest/services/BASEMAP/SFO_Base_Map/MapServer/13'},
  { id: 17, name: 'Runway', appId: 'Y9zqw6xLQnwfeBvS4TtDw', url: 'https://sfo-dcgis01.flysfo.com/arcgis/rest/services/BASEMAP/SFO_Base_Map/MapServer/12'},
  { id: 18, name: 'SFO Base Map', appId: 'Twj6NY8IbeOm6zXXe7yrS', url: 'https://sfo-dcgis01.flysfo.com/arcgis/rest/services/BASEMAP/SFO_Base_Map/MapServer'},
  { id: 19, name: 'Testing', appId: 'abcd1234', url: 'https://services.arcgisonline.com/arcgis/rest/services/ESRI_Imagery_World_2D/MapServer'},
]