import React, { useEffect, useState } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom'

import Application from './_products/sfo/Application';
import Survey from      './_products/survey/Survey';
import Error from '../../components/system/Error';

// lklklk you need to add something here
// in case the task is matched to a different phase
// right now it's just whatever phase the person was last in...
// or should this just be handled if the task is affected by it?

const View = () => {
  const params = useParams();
  const navigate = useNavigate();
  const context = useOutletContext();
  const [task, setTask] = useState({});

  useEffect(() => {
    if(!params.taskId) return;
    let task = context.projTasks?.find(x => x.appId === params.taskId);
    if(!task) navigate('../tasks');
    setTask(task);
  }, [params])

  if(task.taskType==='application') return <Application context={context} task={task} setTask={setTask} />;
  else if(task.taskType==='survey') return <Survey context={context} task={task} setTask={setTask} />;
  return <Error msg="Task type not found. Contact support (7221)." />
}

export default View;
