import React, { Fragment, Suspense, lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

import { SiteProvider } from './context/SiteContext';
import { UserProvider } from './context/UserContext';
import { SocketProvider } from './context/SocketContext';
import { WorkerProvider } from './context/WorkerContext';

import Loading from './components/system/Loading';

import DsmOverview from './views/dsm/admin/Overview';

// import Project from './views/mapping/Project';
import ProjectEdit from './views/mapping/components/Edit';
import ProjectTasks from './views/tasks/components/ProjectTasks';
import { DiscussionOutlet } from './views/system/discussion/Discussion';
import FileManager from './views/system/files/FileManagerOutlet';
import Log from './views/system/log/Log';

import Structure from './views/mapping/structure/Structure';
import Quantities from './views/mapping/quantities/Quantities';
import List from './views/mapping/list/List';
import Photos from './views/mapping/photos/Photos';
import { LibraryOutlet } from './views/system/library/Library';
import Map from './views/mapping/map/Map';
import Task from './views/tasks/Task';

// survey stuff
import SurveyOverview from './views/survey/views/Overview';

const Error  = lazy(() => import('./components/system/Error'));
const Logout = lazy(() => import('./views/system/Logout'));

// const JsonAuth = lazy(() => import('./views/system/JsonAuth'));
// const Config = lazy(() => import('./views/dsm/Config'));
const Dsm = lazy(() => import('./views/dsm/display/Dsm'));

const Admin = lazy(() => import('./views/admin/Admin'));

const Cms = lazy(() => import('./views/cms/Cms'));
const CmsContent = lazy(() => import('./views/cms/Content'));
const Uploads = lazy(() => import('./views/dsm/admin/Uploads'));
const Presentation = lazy(() => import('./views/dsm/admin/presentations/Presentation'));
const Locations = lazy(() => import('./views/dsm/admin/locations/Locations'));

const Dashboard = lazy(() => import('./views/Dashboard'));
const Projects = lazy(() => import('./views/mapping/Projects'));
const Tasks = lazy(() => import('./views/tasks/Tasks'));
const Checklists = lazy(() => import('./views/system/checklists/Checklists'));

const Project = lazy(() => import('./views/mapping/Project'));
// const ProjectEdit = lazy(() => import('./views/mapping/components/ProjectEdit'));
// const ProjectTasks = lazy(() => import('./views/tasks/components/ProjectTasks'));
// const Discussion = lazy(() => import('./views/system/discussion/Discussion'));
// const FileManager = lazy(() => import('./views/system/files/FileManager'));
// const Log = lazy(() => import('./views/system/log/Log'));

const Wayfinder = lazy(() => import('./views/wayfinder/Wayfinder'));

const Share  = lazy(() => import('./views/share/Share'));
const TempContent = lazy(() => import('./views/share/TempContent'));

const Survey = lazy(() => import('./views/survey/Survey'));

// product-specific files
const MapGis = lazy(() => import('./views/mapping/map/MapGis'));
const WGSLogTicket = lazy(() => import('./views/_products/sfo/LogTicket'));

const Schemas = lazy(() => import('./_schemas/ICP'));

var hostname = window.location.hostname.replace('www.', '');
if(hostname === 'localhost') {
  // hostname = 'admin.mstdsqr.com';
  // hostname = 'device.mstdsqr.com';
  hostname = 'wgs.flysfo.io';
  // hostname = 'new.wayfindit.com';
  // hostname = 'survey.wayfindit.com';
  // hostname = 'icp.jblapp.com';
}

function App() {
  return (
    <SiteProvider hostname={hostname}>
      <SocketProvider>
        <UserProvider>
          <WorkerProvider>
            <Suspense fallback={<Loading />}>
              <Routes>
                {/*
                  lklklk: you should create a new DSM generic device screen
                  that checks for the right layout and splits between Wayfinder
                  and Related-type content
                */}

                <Route path="/schemas" element={<Schemas />} />
                <Route path="/logout" element={<Logout />} />

                  { (hostname === 'new.wayfindit.com' || hostname.includes('jblapp')) && (
                    <Fragment>
                      <Route path="/checklists" element={<Checklists />} />
                      <Route path="/projects" element={<Projects />} />
                      <Route path="/project/:projectId" element={<Project />}>
                        <Route path="phase/:phaseId" element={<Structure />} />
                        <Route path="phase/:phaseId/quantities" element={<Quantities />} />
                        <Route path="phase/:phaseId/list" element={<List />} />
                        <Route path="phase/:phaseId/photos" element={<Photos />} />
                        <Route path="phase/:phaseId/map/:planId" element={<Map />} />
                        <Route path="tasks/:taskId/map/:planId" element={<Task />} />
                        <Route path="tasks/:taskId/:mode" element={<Task />} />
                        <Route path="tasks/:taskId" element={<Task />} />
                        <Route path="tasks" element={<ProjectTasks />} />
                        <Route path="library" element={<LibraryOutlet />} />
                        <Route path="discussion" element={<DiscussionOutlet />} />
                        <Route path="attachments" element={<FileManager parentType="project" />} />
                        <Route path="activity" element={<Log />} />
                        <Route path="settings" element={<ProjectEdit />} />
                      </Route>
                      <Route path="/tasks" element={<Tasks />} />
                    </Fragment>
                  )}

                  { hostname === 'wgs.flysfo.io' && (
                    <Fragment>
                      <Route path="/admin" element={<Admin />} />
                      <Route path="/projects" element={<Projects />} />
                      <Route path="/project/:projectId" element={<Project />}>
                        <Route index element={<ProjectEdit />} />
                        <Route path="discussion" element={<DiscussionOutlet />} />
                        <Route path="tasks/:taskId/:mode" element={<Task />} />
                        <Route path="tasks/:taskId" element={<Task />} />
                        <Route path="tasks" element={<ProjectTasks />} />
                        <Route path="list" element={<List />} />
                        <Route path="attachments" element={<FileManager parentType="project" />} />
                        <Route path="activity" element={<Log />} />
                      </Route>
                      <Route path="/tasks" element={<Tasks />} />
                      <Route path="/log-ticket" element={<WGSLogTicket />} />
                      <Route path="/assets" element={<MapGis />} />
                      <Route path="/share/:shareKey/:shareAccess" element={<Share />}>
                        <Route index element={<TempContent />} />
                        <Route path=":slug/:section" element={<TempContent />} />
                        <Route path=":slug" element={<TempContent />} />
                      </Route>
                      <Route path="/:bookId" element={<Cms />}>
                        <Route index element={<CmsContent />} />
                        <Route path=":slug/:section" element={<CmsContent />} />
                        <Route path=":slug" element={<CmsContent />} />
                      </Route>
                    </Fragment>
                  )}

                  { hostname === 'admin.mstdsqr.com' && (
                    <Fragment>
                      <Route path="/" element={<Dashboard />}>
                        <Route path="people" element={<Locations type="person" />} />
                        <Route path="places" element={<Locations type="place" />} />
                        <Route path="presentations/:presoId" element={<Presentation />} />
                        <Route path="presentations" element={<DsmOverview />} />
                        <Route path="pdfs" element={<Uploads text="PDF" fileTypes={['application/pdf']} convert={true} />} />
                        <Route path="videos" element={<Uploads text="Video" fileTypes={['video/mp4']} convert={false} />} />
                        <Route path="images" element={<Uploads text="Image" fileTypes={['image/jpg','image/jpeg','image/gif','image/png']} thumbnails={true} convert={false} />} />
                      </Route>
                      <Route path="/:deviceId" element={<Dsm />} />
                    </Fragment>
                  )}

                  { (hostname === 'device.mstdsqr.com' || hostname === 'eams-v8-staging.herokuapp.com') && (
                    <Fragment>
                      <Route path="/:deviceId" element={<Wayfinder />} />
                    </Fragment>
                  )}

                {/*
                  <Route path="/:deviceId" element={<Dsm />} />
                  <Route path="/json" element={<JsonAuth />} />
                  <Route path="/config" element={<Config />} />
                  <Route path="/admin" element={<Admin />} />
                */}

                  { hostname === 'survey.wayfindit.com' && (
                    <Fragment>
                      <Route path="/:taskId" element={<Survey />}>
                        <Route index element={<SurveyOverview />} />
                      </Route>
                    </Fragment>
                  )}

                <Route path="/" element={<Dashboard />} />
                <Route path="*" element={<Error msg="404 Not Found" />} />
              </Routes>
            </Suspense>
          </WorkerProvider>
        </UserProvider>
      </SocketProvider>
    </SiteProvider>
  );
}

export default App;
