import { fabric } from 'fabric';

const shape = ({ textLabel, textOpts, fillColor, scale, details }) => {
  let text = new fabric.Text(textLabel, textOpts);

  let triangle = new fabric.Triangle({
    width: 30*scale,
    height: 26*scale,
    fill: fillColor,
    strokeWidth: 1*scale,
    stroke: '#000',
    top: -6*scale
  });

  let c = new fabric.Group([ triangle, text ], details);
  return c;
}

export default shape;