import React, { createContext, useState } from 'react';
import { useAxios } from '../hooks/useAxios';

import Collapse from 'react-bootstrap/Collapse';
import WorkerProgress from './components/WorkerProgress';
import { ChevronDown, ChevronUp, X } from 'react-bootstrap-icons'

import pdfWorker from './workers/pdf/init';
import ccPlanWorker from './workers/cc-plans/init';
import ccThumbsWorker from './workers/cc-thumbs/init';

const WorkerContext = createContext();

// this will be used for:
// pdf reports
// pdf location plans (and png?)
// artwork
// zip downloads of photos


const WorkerProvider = (props) => {
  const { serverCall } = useAxios();
  const [open, setOpen] = useState(true);
  const [workers, setWorkers] = useState({});

  const startWorker = ({ type, pieces }) => {
    setOpen(true);

    switch (type) {
      case 'pdf': return pdfWorker({ pieces, setWorkers });
      case 'cc-plan': return ccPlanWorker({ pieces, setWorkers, serverCall });
      case 'cc-thumbs': return ccThumbsWorker({ pieces, setWorkers, serverCall });
      default: return alert('Error starting worker.'); // lklklk
    }
  }

  const stopWorker = (id) => {
    workers[id].worker.terminate();
    setWorkers((prev) => {
      const updatedWorkers = {...prev};
      updatedWorkers[id].data = { status: 'stopped' };
      return updatedWorkers;
    });
  }

  const clearAll = () => {
    Object.values({...workers}).forEach((worker) => {
      worker.worker.terminate();
    });
    setWorkers({});
    setOpen(false);
  }

  return (
    <WorkerContext.Provider value={{ workers, startWorker, stopWorker }}>
      {props.children}

      {Object.keys(workers).length > 0 && (
        <div className="position-fixed bg-white border rounded shadow" style={{zIndex:1999, width:300, bottom:12, right:12}}>
          <div className={`bg-dark p-2 text-end ${open ? 'rounded-top' : 'rounded'}`}>
            {  open && ( <ChevronDown size={24} className="pointer text-white" onClick={() => setOpen(false)} /> )}
            { !open && ( <ChevronUp size={24} className="pointer text-white" onClick={() => setOpen(true)} /> )}
            <X size={28} className="pointer text-white ms-2" onClick={clearAll} />
          </div>

          <Collapse in={open}>
            <div>
              { Object.keys(workers).map((key, idx) => <WorkerProgress key={idx} id={key} obj={workers[key]} stopWorker={stopWorker} /> )}
            </div>
          </Collapse>
        </div>
      )}
    </WorkerContext.Provider>
  );
}

export { WorkerContext, WorkerProvider };
