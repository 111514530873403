import dayjs from 'dayjs';
import parse from 'html-react-parser';

import { titleCase } from './functions';

// future:
// consider splitting the padding and styling so you could apply both
// may also want to switch so that sorting is a different type of value...?

// https://stackoverflow.com/questions/68136203/how-to-use-javascript-sort-to-order-by-year-month-day

// https://stackoverflow.com/questions/69270593/how-to-update-manage-data-for-react-table-userowstate-plugin-behaviour-is-uncl

export const formatHeaders = (arr, toParent) => {
  let headers = [];
  for(const header of arr){
    let column = {
      Header: header.header,
      accessor: header.column,
      width: header.width
    };

    // column.accessor = ({ row, cell }) =>
    // you'll update the accessor manually...
    // https://stackoverflow.com/a/66307242/983017

    // this means you may also be able to do the array stuff here instead of at the ... state?

    column.Cell = ({ row, cell }) => {
      let td = cell.value;

      if(header.html)
        td = parse(td);

      if(header.format === 'padded' || header.padded)
        td = String(cell.value).padStart(3, '0');

      if(header.titleCase)
        td = titleCase(cell.value);

      if(header.prefix)
        td = header.prefix+td;

      if(header.dateFormat && cell.value)
        td = dayjs(cell.value).format(header.dateFormat);

      if(header.styling) {
        let found = header.styling.find(x => x.value === cell.value);
        let style = found ? found.class : '';
        td = <span className={style}>{td}</span>;
      }

      if(header.form) {
        td = <input className="form-control" defaultValue={cell.value} onBlur={(e) => checkInput(e, cell)} />;
      }

      if(header.toggle) {
        td = <label className="switch"><input type="checkbox" onChange={(e) => toggle(e, cell)} checked={cell.value==='Y'} /><span className="switch-slider"></span></label>;
      }

      return td;
    }

    if(header.sort === 'date') {
      column.sortType = (a, b) => {
        var a1 = new Date(a.original[header.column]).getTime();
        var b1 = new Date(b.original[header.column]).getTime();
        if(a1<b1) return 1;
        else if(a1>b1) return -1;
        else return 0;
      }
    }

    headers.push(column);
  }

  const checkInput = (e, cell) => {
    if(!toParent) return;
    if(e?.target?.value === cell?.value) return;
    toParent({ action: 'field update', value: {appId: cell.row.original.appId, id: cell.row.original.id, [cell.column.id]: e.target.value}});
  }

  const toggle = (e, cell) => {
    if(!toParent) return;
    let value = e.target.checked ? 'Y' : 'N';
    toParent({ action: 'field update', value: {appId: cell.row.original.appId, id: cell.row.original.id, [cell.column.id]: value}});
  }

  return headers;
}
