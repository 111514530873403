import React, { Fragment, useContext, useEffect, useMemo, useState } from 'react';
import { SiteContext } from '../../../context/SiteContext';
import { UserContext } from '../../../context/UserContext';
import { useAxios }    from '../../../hooks/useAxios';
import { useNavigate, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form';

import { formObj } from '../../../utils/schemas';
import { userArray, getDifference, titleCase, emptyObj } from '../../../utils/functions';
import { formatEmail } from '../../../utils/emails';
import dayjs from 'dayjs'

import { Discussion } from '../../system/discussion/Discussion';
import FileManager from '../../system/files/FileManager';
import ConvertLink from './ConvertLink';

import ApplicationReview from '../_products/sfo/ApplicationReview';

import Modal from 'react-bootstrap/Modal';
import Generated  from '../../../components/system/Generated';
import DeleteBtn from '../../../components/buttons/DeleteBtn';
// import EmailError from './EmailError';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

const View = (props) => {
  const params = useParams();
  const navigate = useNavigate();
  const { obj, handleCancel, tasks, setTasks } = props;
  const { config, siteSchemas } = useContext(SiteContext);
  const { userDetails, allUsers, schemas, checklists } = useContext(UserContext);

  const [schema, setSchema] = useState([]);
  const [taskStatus, setTaskStatus] = useState([])
  const [taskTypes, setTaskTypes] = useState([])

  const [access, setAccess] = useState([]);
  const [accessUsers, setAccessUsers] = useState([]);

  const [threads, setThreads] = useState([]);
  const [comments, setComments] = useState([]);

  const [files, setFiles] = useState([]);
  const [filesLog, setFilesLog] = useState([]);

  const formText = { submit: 'Save', pending: 'Saving', success: 'Saved!', cancel: 'Cancel' };
  const errorCode = '234';

  const { serverCall, pending, error } = useAxios();
  const { control, register, handleSubmit, formState: { errors }, reset, watch, setValue } = useForm({
    defaultValues: useMemo(() => formObj(schema), [schema])
  });

  const objStatus = watch('status');

  useEffect(() => {
    if(emptyObj(obj)) return;
    (async function () {
      let res = await serverCall({ method: 'GET', url: `/system/tasks/${obj.appId}`, eamsAuth0: true });
      if(res.data.threads) setThreads(res.data.threads);
      if(res.data.comments) setComments(res.data.comments);
		})();
  }, [obj])

  useEffect(() => {
    let found = schemas.find(x => x.name === 'edit-task');
    if(found) setSchema(found.schema);

    let filled = formObj(found.schema, obj);
    reset(filled);

    found = schemas.find(x => x.name === 'task-status');
    if(found) setTaskStatus(found.schema);

    found = schemas.find(x => x.name === 'task-types');
    if(found) setTaskTypes(found.schema);
  }, [schemas, obj, reset])

  const fromChild = (data) => {
    const { type, value } = data; // name

    if(type === 'access') {
      setAccess(value);
    } else if(type === 'file uploaded') {
      // we already have the taskId, we should be able to just push
    } else if(type === 'links updated') {
      setValue('parentIds',value);
    }
  }

  const onSubmit = async (data) => {
    data.updatedBy = userDetails.id;
    data.updatedAt = dayjs().format('YYYY-MM-DD HH:mm:ss');
    data.updatedByUser = userDetails.byUser;

    // check if anyone has been newly assigned
    const newAssignees = getDifference(data.assignedTo, data.ogAssignedTo);
    let template = siteSchemas.find(x => x.type === 'email' && x.name === 'system-email');
    if(newAssignees.length > 0 && template?.schema?.template) {
      let emails = [];
      let emailData = {...data};

      // task emails include user information,
      // for task edits let's just put whoever is assigning them in the email
      emailData.email = userDetails.email;
      emailData.firstName = userDetails.firstName;
      emailData.lastName = userDetails.lastName;

      let email = formatEmail({
        template: 'task assigned',
        html: template.schema.template,
        senderObj: config.supportEmail,
        recipientIds: newAssignees,
        subject: `${config.title}: ${emailData.name} Assigned`,
        emailData,
        logObj: { createdBy: userDetails.id }
      });
      emails.push(email)

      // only send task assignment email if site config allows
      if(config.taskEmails && emails.length > 0)
        data.eamsEmail = emails;
    }

    let res = await serverCall({ method: 'PATCH', data, url: `/system/tasks/${obj.appId}`, eamsAuth0: true });
    console.log(res);
    if(res.status===200) {
      // update managedByDisplay and new ogAssignedTo
      data.assignedToDisplay = userArray(allUsers, data.assignedTo);
      data.ogAssignedTo = data.assignedTo;

      // delete any email or file data since we don't need to store with the obj
      delete data.eamsEmail;
      delete data.eamsFiles;

      // lklklk if you are doing the manual publicStatus checks, do you need to update it here
      // in case the value has changed?

      // now update the main task objects
      let newTasks = [...tasks];
      let idx = newTasks.findIndex(x => x.id === data.id);
      newTasks[idx] = { ...newTasks[idx], ...data};
      setTasks(newTasks);

      // also check if response includes any new files...
      // if(response.files) {
      //
      // }

      // // check if response includes any log data
      // if(response.log) {
      //   let arr = [...logs];
      //   let idx = arr.findIndex(x => x.id === response.log.id);
      //   if(idx > -1)
      //     arr[idx] = response.log;
      //   else
      //     arr.push(response.log);
      //
      //   setLogs(arr);
      // }

      handleCancel();
    }
  };

  const toggleStatus = async ({ type }) => {
    // lklklk: consider moving this to the DeleteBtn component
    // then you can just pass the appropriate appId etc?
    let status = (type === 'activate') ? 'A' : 'D';

    let data = {
      id: obj.id,
      status: status,
      updatedBy: userDetails.id,
      updatedAt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
      updatedByUser: userDetails.byUser
    }

    let newTasks = [...tasks];
    let idx = newTasks.findIndex(x => x.id === data.id);
    newTasks[idx] = { ...newTasks[idx], ...data};
    setTasks(newTasks);

    let newObj = {...obj, ...data};
    let filled = formObj(schema, newObj);
    reset(filled);

    let res = await serverCall({ method: 'PATCH', data, url: `/system/tasks/${obj.appId}`, eamsAuth0: true });
    console.log(res);

    if(type === 'delete confirmed')
      handleCancel();
  }

  useEffect(() => {
    if(!access) return;
    let ids = access.filter(x => x.type === 'group').map(obj => obj.id);
    let filtered = allUsers.filter(x => {
      let groups = x.groupArr.filter(x => ids.includes(x.id));
      if(groups.length > 0) return groups;
      else return null;
    })
    setAccessUsers(filtered);
  }, [access, allUsers])

  const open = () => {
    navigate(`./${obj.appId}`);
  }

  return (
    <Fragment>
      <Modal.Header closeButton>
        <Modal.Title>Edit { obj.taskType === 'ticket' ? 'Ticket' : `${titleCase(obj.taskType)} Task` }</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tabs defaultActiveKey="request" id="uncontrolled-tab-example" className="mb-3">
          <Tab eventKey="request" title="Request">
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <Generated id="task-edit" schema={schema} size="md" pieces={{ vars: { 'task-types': taskTypes, 'task-status': taskStatus, 'access-users': accessUsers, 'all-users': allUsers, 'checklists': checklists }, register, control, setValue, watch, errors, toParent: fromChild }} />
            </form>
          </Tab>
          { obj.taskType==='Application' && (
            <Tab eventKey="review" title="Review">
              <ApplicationReview />
            </Tab>
          )}
          <Tab eventKey="discussion" title="Discussion">
            <Discussion parentType="task" parentId={obj.id} threads={threads} setThreads={setThreads} comments={comments} setComments={setComments} />
          </Tab>
          <Tab eventKey="files" title={ files.length > 0 ? `Attachments (${files.length})` : 'Attachments' }>
            <FileManager
              parentType="task"
              parentId={obj.appId}
              files={files}
              setFiles={setFiles}
              filesLog={filesLog}
              setFilesLog={setFilesLog}
            />
          </Tab>
          <Tab eventKey="convert-link" title="Convert/Link">
            <ConvertLink obj={obj} tasks={tasks} setTasks={setTasks} toParent={fromChild} />
          </Tab>
        </Tabs>
      </Modal.Body>
      <Modal.Footer>
        { params.projectId && (
          <Fragment>
            { obj.taskType === 'application' && <button className="btn btn-sm btn-outline-dark float-end" onClick={open}>Open Application</button>}
            { obj.taskType === 'survey'  && <button className="btn btn-sm btn-outline-dark float-end" onClick={open}>Open Inspection</button>}
          </Fragment>
        )}
        { objStatus === 'A' && (
          <DeleteBtn toParent={toggleStatus} text={`Archive Task`} wrapper="me-auto" />
        )}
        { objStatus === 'D' && (
          <button type="button" className="d-inline-block btn btn-sm btn-outline-dark me-auto" onClick={() => toggleStatus({type: 'activate'})}>
            Activate Task
          </button>
        )}

        <button className="btn btn-sm btn-outline-dark me-1" type="button" onClick={handleCancel}>
          {formText.cancel}
        </button>

        <button className={`btn btn-sm ${error ? 'btn-danger' : 'btn-success'}`} type="submit" disabled={pending} onClick={() => handleSubmit(onSubmit)() }>
          { !pending && !error && (formText.submit)}
          { pending === true && (
            <Fragment>
              <div className="spinner-border spinner-border-sm text-white me-2" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
              {formText.pending}
            </Fragment>
          )}
          { error && (`Error saving (${errorCode})`)}
          { pending ==='success' && formText.success}
        </button>
      </Modal.Footer>
    </Fragment>
  )
}

export default View;
