import React, { Fragment, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useAxios } from '../../../../hooks/useAxios';

import Offcanvas from 'react-bootstrap/Offcanvas';
import Generated  from '../../../../components/system/Generated';
import DeleteBtn from '../../../../components/buttons/DeleteBtn';
// import EmailError from '../system/EmailError';

import dayjs from 'dayjs'

import { formObj } from '../../../../utils/schemas';

const View = (props) => {
  const debug = true;
  const { userDetails, schema, obj, setTypes, handleClose, toParent } = props;
  const formText = { submit: `Update`, pending: 'Updating', success: 'Updated!', cancel: 'Cancel' };
  const errorCode = '5512'

  const { serverCall, pending, error } = useAxios();
  const { control, register, handleSubmit, formState: { errors }, reset, watch, setValue } = useForm({
    defaultValues: useMemo(() => formObj(schema), [])
  });

  useEffect(() => {
    if(!obj) return;
    let filled = formObj(schema, obj);
    // manually set these for color picker:
    if(filled.styling?.fillColor) filled.fillColor = filled.styling.fillColor;
    if(filled.styling?.borderColor) filled.borderColor = filled.styling.borderColor;

    reset(filled);
  }, [schema, obj, reset])

  const onSubmit = async (data) => {
    if(debug) console.log(data);

    setTypes((prev) => {
      let arr = [...prev];
      let idx = arr.findIndex(x => x.id === obj.id);
      arr[idx] = {...arr[idx], ...data}
      return arr;
    })

    data.updatedAt = dayjs().format('YYYY-MM-DD HH:mm:ss');
    data.updatedBy = userDetails.id;

    let res = await serverCall({ method: 'PATCH', data, url: `/library/types/${obj.appId}`, eamsAuth0: true });
    if(res.status!==200) return alert('Error updating type. Contact support.') // lklklk
    handleClose();
  };

  const fromChild = async (data) => {
    const { type } = data;
    if(type==='delete confirmed') {
      if(toParent) toParent({ type: 'remove types', value: [obj.id] });
      handleClose();
    }
  }

  return (
    <div className="d-flex flex-column h-100 overflow-hidden">
      <Offcanvas.Header className="bg-body" closeButton>
        <Offcanvas.Title>{obj?.label}</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="bg-body flex-grow-1 overflow-scroll">
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" className="mb-3">
          <Generated id="type-edit" schema={schema} size="md" pieces={{ register, control, setValue, watch, errors, toParent: fromChild }} />
        </form>        
      </Offcanvas.Body>
      <div className="bg-body p-3">
        <button className={`btn btn-sm ${error ? 'btn-danger' : 'btn-success'} float-end`} type="submit" disabled={pending} onClick={() => handleSubmit(onSubmit)() }>
          { !pending && !error && (formText.submit)}
          { pending === true && (
            <Fragment>
              <div className="spinner-border spinner-border-sm text-white me-2" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
              {formText.pending}
            </Fragment>
          )}
          { error && (`Error saving (${errorCode})`)}
          { pending ==='success' && formText.success}
        </button>

        <button className="btn btn-sm btn-outline-dark ms-auto me-1 float-end" type="button" onClick={handleClose}>
          {formText.cancel}
        </button>

        <DeleteBtn toParent={fromChild} text={`Delete Type`} />
      </div>
    </div>
  )
}

export default View;
