import { fabric } from 'fabric'
import { viewportChanged } from './viewport';

export const handleTouchDrag = ({ e, debug, canvas, interaction, map }) => {
  // if(debug) console.log('touch drag');
  if(!e.self.x || !e.self.y || !e.e.type) return;
  if(interaction.bkgdLocked || interaction.pauseSel || interaction.selected.length > 0) return;

  let xChange = e.self.x - interaction.panX;
  let yChange = e.self.y - interaction.panY;

  // lklklk:
  // maybe we change the minimum value depending on how zoomed they are?
  // this would avoid the issues you have with slow panning at small zooms
  let zoom = canvas.getZoom();
  let threshold = (zoom < 1) ? 50 : 100;
  // console.log(zoom, xChange, yChange);

  if( (Math.abs(xChange) <= threshold) &&
      (Math.abs(yChange) <= threshold) ) {
    var delta = new fabric.Point(xChange, yChange);
    canvas.relativePan(delta);
  }

  interaction.panX = e.self.x;
  interaction.panY = e.self.y;
  viewportChanged(canvas, map);
}

// below are not yet implemented:

export const handleLongpress = ({ e, debug, canvas, interaction, quickMode, toParent }) => {
  if(debug) console.log('checking for touch:', interaction.touching);

  console.log(e.target);

  if(interaction.touching && !quickMode) {
    // add plot, also exists in mouse.js
    var pointer = canvas.getPointer(e.e);
    var posX = Math.round(pointer.x);
    var posY = Math.round(pointer.y);
    if(debug) console.log('adding from longpress: '+posX + ' - ' + posY);
    toParent({ type: 'add', left: posX, top: posY });
  }
}

export const handleTouchGesture = ({ e, debug, canvas, interaction }) => {
  if(debug) console.log('touch:gesture');
  interaction.pinching = true; // this is unset in mouseUp

  // lklklk probably need to lock all objects to avoid moving
  if (e.e.touches && e.e.touches.length === 2) {
    var point = new fabric.Point(e.self.x, e.self.y);
    let zoomStartScale = 1;
    if (e.self.state === 'start') {
      zoomStartScale = canvas.getZoom();
    }
    var delta = zoomStartScale * e.self.scale;
    canvas.zoomToPoint(point, delta);
  }
}
