import { fabric } from 'fabric';
import rect from './shapes/rect';
import circle from './shapes/circle';
import triangle from './shapes/triangle';
import polygon from './shapes/polygon';
import polyline from './shapes/polyline';

import archline from './shapes/archline';
import lozenge from './markers/lozenge';

// arrows:
// https://stackoverflow.com/questions/53114152/draw-two-head-arrows-in-fabric-js



// simple vs arch comes from project
// but can be overriden at the type level
// LKLKLK HOWEVER!!

// we may want to add a third at project level
// that just lets it be set at type
// how else would we override so all are viewed as simple...?

// locator, label, and line scales are set at phasePlan
// but... can be multiplied I guess by the type?

export const handleDrawPlots = async ({ canvas, map, plots, interaction }) => {
  if(!canvas) return;
  let promise = new Promise((resolve, reject) => {

    let fontFamily = '"Roboto","Segoe UI","Roboto","Helvetica Neue","Arial"';
    let fontSize = 10;
    let textPad = 6;
    let lineHeight = .85;

    // get the selected pieces before they are cleared for re-establishing later
    let temp = interaction ? [...interaction.selected] : [];

    canvas.getObjects().forEach((o) => {
      canvas.remove(o);
    });

    let filtered = plots.filter(x => x.planId === map.planId);
    for (const plot of filtered) {
      let scale = map.styling?.scale ? map.styling.scale : 2;

      // lklklk status color override here?

      let details = {
        id: plot.id,
        appId: plot.appId,
        phaseId: plot.phaseId,
        typeId: plot.typeId,
        status: plot.publicStatus,
        left: plot.styling.locatorX,
        top: plot.styling.locatorY,
        piece: 'locator'
      }

      let labelX = plot.styling.labelX ? plot.styling.labelX : details.left + 50;
      let labelY = plot.styling.labelY ? plot.styling.labelY : details.top + 50;
      let points = plot.styling.points ? plot.styling.points : [];
      let rotation = plot.styling.rotation ? plot.styling.rotation : 0;
      let textLabel = plot.display.textLabel;

      let textOpts = {
        fill: plot.display.textFill,
        fontSize: fontSize*scale,
        fontFamily: fontFamily,
        lineHeight: lineHeight*scale,
        padding: textPad*scale,
      }

      if(plot.display.type === 'simple') {
        let shapeId = plot.display.locatorId;
        let fillColor = plot.display.locatorFill;

        let shape = buildShape({ interaction, shapeId, textLabel, textOpts, fillColor, scale, details, points })
        canvas.add(shape);

        // send to back for polygons/other shapes
        if(shape.sendToBack) canvas.sendToBack(shape);
          
      } else {
        let coords = [
          { x: details.left, y: details.top },
          // lklklk will want to add any plot line breaks here...
          { x: labelX, y: labelY },
        ]

        let labelDetails = {...details, piece: 'label', left: labelX, top: labelY }

        // create line and append to details for moveLine
        let line = archline({ coords, color: plot.display.lineColor, scale });
        labelDetails.eamsLine = line;
        details.eamsLine = line;

        let locator = buildMarker({ shapeId: plot.display.locatorId, fillColor: plot.display.locatorFill, details, textLabel, textOpts, scale, rotation });
        let label = buildShape({ interaction, shapeId: plot.display.labelId, fillColor: plot.display.labelFill, details: labelDetails, textLabel, textOpts, scale, points });

        canvas.add(line);
        canvas.add(locator);
        canvas.add(label);
      }
    }

    // after redrawing, reselect; yes this is needed!
    // let selected = [];
    // for(const plot of temp) {
    //   canvas.getObjects().forEach((obj) => {
    //     if(plot.appId === obj.appId && plot.phaseId === obj.phaseId && plot.piece === obj.piece)
    //       selected.push(obj);
    //   });
    // }

    // var sel = new fabric.ActiveSelection(selected, { canvas });
    // canvas.setActiveObject(sel).requestRenderAll();
    resolve(canvas);
  })

  let result = await promise;
  return result;
}


const buildShape = (pieces) => {
  switch (pieces.shapeId) {
    case 1: return circle(pieces);
    case 2: return rect(pieces);
    case 3: return triangle(pieces);
    // case 4: return icon(pieces);
    // case 5: return thumbnail(pieces);
    case 6: return polyline(pieces);
    case 7: return polygon(pieces);
    default: return circle(pieces);
  }
}

const buildMarker = (pieces) => {
  switch (pieces.shapeId) {
    case 1: return lozenge(pieces);
    case 2: return rect(pieces);
    case 3: return triangle(pieces);
    default: return lozenge(pieces);
  }
}

// { value: 1, label: 'Lozenge' },
//   { value: 2, label: 'A/B' },
//   { value: 3, label: 'Directional' }


// saving for later...
// const animate = (canvas, obj, scale) => {
//   const duration = 750;
//   const opacity = 0.5;
//   const animateScale = 1.2;
//   var newOpacity = (obj.opacity == 1) ? opacity : 1;
//   var newScale = (obj.scaleX == scale) ? scale*animateScale : scale;

//   obj.animate({
//     scaleX: newScale,
//     scaleY: newScale,
//     opacity: newOpacity
//   }, {
//     duration: duration,
//     onChange: canvas.renderAll.bind(canvas),
//     onComplete: function() {
//       animate(canvas, obj, scale)
//     }
//   });
// }
