import React, { Fragment, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAxios } from '../../../../hooks/useAxios';

import { UserContext } from '../../../../context/UserContext';
import Dropzone  from '../../../../components/system/dropzone/Dropzone';
import Generated  from '../../../../components/system/Generated';
import DeleteBtn from '../../../../components/buttons/DeleteBtn';
// import EmailError from '../system/EmailError';

import dayjs from 'dayjs'
import { formObj } from '../../../../utils/schemas';

const View = (props) => {
  const debug = true;
  const { userDetails, schemas } = useContext(UserContext);
  const { obj, types, setPlots, photos, setPhotos, handleClose, toParent } = props;
  const formText = { submit: `Update`, pending: 'Updating', success: 'Updated!', cancel: 'Cancel' };
  const errorCode = '1984'

  const objRef = useRef({});
  const [editSchema, setEditSchema] = useState([]);
  const [actionSchema, setActionSchema] = useState([]);
  const [phasePhotos, setPhasePhotos] = useState([]);

  const { serverCall, pending, error } = useAxios();
  const { control, register, handleSubmit, formState: { errors }, reset, watch, setValue } = useForm({
    defaultValues: useMemo(() => formObj(editSchema), [editSchema])
  });

  useEffect(() => {
    let found = schemas.find(x => x.name === 'plot-actions');
    if(found) setActionSchema(found.schema);

    found = schemas.find(x => x.name === 'plot-edit');
    if(found) setEditSchema(found.schema);
  }, [schemas])

  useEffect(() => {
    if(!obj) return;
    objRef.current = obj;
    let filled = formObj(editSchema, obj);
    reset(filled);
  }, [editSchema, obj, reset])

  useEffect(() => {
    let filtered = photos.filter(x => x.plotAppId === obj.appId && x.projectId === obj.projectId && x.phaseId === obj.phaseId && x.status === 'A').sort((a,b) => (b.id > a.id) ? 1 : ((a.id > b.id) ? -1 : 0));
    setPhasePhotos(filtered);
  }, [obj.appId, obj.projectId, obj.phaseId, photos])

  const onSubmit = async (data) => {
    if(debug) console.log(data);
    data.updatedAt = dayjs().format('YYYY-MM-DD HH:mm:ss');
    data.updatedBy = userDetails.id;

    setPlots((prev) => {
      let arr = [...prev];
      let idx = arr.findIndex(x => x.id === obj.id);
      arr[idx] = {...arr[idx], ...data};
      return arr;
    })

    // update the form with new default values so the bg color goes away
    let filled = formObj(editSchema, data);
    reset(filled);

    let res = await serverCall({ method: 'PATCH', data, url: `/mapping/plots/${obj.appId}`, eamsAuth0: true });
    if(res.status!==200) return alert('Error updating plot. Contact support.') // lklklk
  };

  const fromChild = async (data) => {
    const { type } = data;
    if(type==='file uploaded') {
      let pic = {...data.value, ...{
        projectId: objRef.current.projectId,
        phaseId: objRef.current.phaseId,
        planId: objRef.current.planId,
        plotId: objRef.current.id ? objRef.current.id : null,
        plotAppId: objRef.current.appId,
        createdAt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
        createdBy: userDetails.id,
        status: 'A'
      }};

      let res = await serverCall({ method: 'POST', data: pic, url: `/mapping/photos`, eamsAuth0: true });
      if(res.status!==200) return alert('Error adding photo. Contact support.') // lklklk

      setPhotos((prev) => {
        let arr = [...prev];
        arr.push(res.data);
        return arr;
      })
    }
    // if(type==='delete confirmed') {
    //   if(toParent) toParent({ type: 'remove types', value: [obj.id] });
    //   handleClose();
    // }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" className="mb-3">
      { phasePhotos.length > 0 && (
        <div className="horizontal-scroll mb-3">
          { phasePhotos.map((img) => (
            <a key={img.appId} href={img.url} target="_blank" rel="noreferrer">
              <img src={img.url} className="horizontal-thumb border" alt="Reference" />
            </a>
          ))}
        </div>
      )}

      <Generated id="plot-edit" schema={editSchema} size="md" pieces={{ vars: { 'plot-actions': actionSchema, types }, register, control, setValue, watch, errors, toParent: fromChild }} />

      {/* and then here... should be the message by the type? */}

      <div className="pt-2">
        <button className={`btn btn-sm ${error ? 'btn-danger' : 'btn-success'}`} type="submit" disabled={pending} onClick={() => handleSubmit(onSubmit)() }>
          { !pending && !error && (formText.submit)}
          { pending === true && (
            <Fragment>
              <div className="spinner-border spinner-border-sm text-white me-2" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
              {formText.pending}
            </Fragment>
          )}
          { error && (`Error saving (${errorCode})`)}
          { pending ==='success' && formText.success}
        </button>

        <button className="btn btn-sm btn-outline-dark ms-2" type="button" onClick={handleClose}>
          {formText.cancel}
        </button>

        {/* <DeleteBtn toParent={fromChild} text={`Delete Plot`} wrapper="float-start" /> */}
        { userDetails.supe && <p className="float-end ms-2 mt-2 mb-0 small text-muted">{obj.id}</p>}        
      </div>
      <hr />
      <h5>Add Photos</h5>
      <Dropzone
        folder="sfo-temp/uploads/"
        prefix="file-upload"
        toParent={fromChild}
      />   
    </form>
  )
}

export default View;
