import React, { Fragment, useContext, useEffect, useState } from 'react';
import parse from 'html-react-parser';
import { nanoid } from 'nanoid';

import { UserContext } from '../../context/UserContext';
import { userArray } from '../../utils/functions';

// how to use:
// <Generated id="needed?" schema={schema} tag="sm" />

const View = (props) => {
  const { schema, data } = props;
  const formId = props.id ? props.id : 'form';
  const tag = props.tag ? props.tag : 'h5';
  const { allUsers, allGroups } = useContext(UserContext);
  const [pieces, setPieces] = useState({ allUsers: [], allGroups: []});

  useEffect(() => {
    setPieces({ allUsers, allGroups});
  }, [allUsers, allGroups])

  return (
    <Fragment>
      { schema.map((item) => findType({ formId, tag, item, data, pieces }) )}
    </Fragment>
  )
}

const findType = (props) => {
  const { formId, tag, item, data, pieces } = props;
  if(!item || !data) return;
  if(item.type === 'hidden') return;
  if(item.display === false) return;

  let label = item.displayLabel ? item.displayLabel : item.label;
  let value = data[item.field] ? data[item.field] : '-';

  if(value!=='-') {
    if(item.field==='createdBy' || item.field==='updatedBy') {
      let found = pieces.allUsers.find(x => x.id === value);
      if(found) value = found.label;
    } else if(item.field==='managedBy' || item.field==='assignedTo')
      value = userArray(pieces.allUsers, value);
  }


  // create conditional check here?


  if(item.type.includes('col-'))
    return <Col key={formId+item.id} tag={tag} obj={item} data={data} pieces={pieces} />;


  switch (item.type) {
    // case 'input':
    //   return <Input key={formId+item.id} {...pieces.register(item.field)} item={item} pieces={pieces} />;

    default:
      return (
        <Fragment key={formId+item.id}>
          {parse(`<${tag} class="mb-1">${label}</${tag}>`)}
          <p>{value}</p>
        </Fragment>
      )
  }
}

const Col = (props) => {
  const { formId, tag, obj, data, pieces } = props;

  return (
    <div className="row g-2">
      { obj.type === 'col-6' && (
        <Fragment>
          <div className="col-sm-6">
            { obj.col1?.map((item) => findType({ formId, tag, item, data, pieces }) )}
          </div>
          <div className="col-sm-6">
            { obj.col2?.map((item) => findType({ formId, tag, item, data, pieces }) )}
          </div>
        </Fragment>
      )}

      { obj.type === 'col-12' && (
        <div className="col">
          { obj.col?.map((item) => findType({ formId, tag, item, data, pieces }) )}
        </div>
      )}
    </div>
  )
}

export default View;
