import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useAxios }   from '../hooks/useAxios';

import Loading  from '../components/system/Loading';
import Error    from '../components/system/Error';

import { SiteContext } from './SiteContext';
import { userArray, accessArray } from '../utils/functions';

// userGroups is an array with all user group info
// userGroupOpts is the mapped value/label for dropdowns or selects

// the profile "sub" key includes either email|, sms| or oidc|
// perhaps for all users we should just store the name and email address...

export const UserContext  = createContext({});

export const UserProvider = ({ type, children }) => {
  const { user } = useAuth0();
  const location = useLocation();
  const { config } = useContext(SiteContext);
  const { serverCall, loading, error } = useAxios();
  const [userError, setUserError] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [highestRole, setHighestRole] = useState('viewer');
  const [allGroups, setAllGroups] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [schemas, setSchemas] = useState([]);
  const [projects, setProjects] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [checklists, setChecklists] = useState([]);

  const [register, setRegister] = useState(false);

  useEffect(() => {
    if(!user) return;
    if(location?.pathname === '/logout') return;

    let uuid  = user['https://mstdsqr.com/app_metadata']?.uuid;
    let email = user['https://mstdsqr.com/user_metadata']?.email;

    // if no uuid or email, force registration
    if(!uuid || !email) return setRegister(true);

    (async () => {
      let res = await serverCall({
        method: "GET", url: `/system/users/${uuid}`, eamsAuth0: true,
        headers: { 'eams-key': email, 'eams-access': uuid }
      });

      // if no user is found, check if this site allows self-register
      if(res.status===204 && config.selfRegister)
        return setRegister(true);
      else if(res.status!==200)
        return setUserError(true);

      // console.log(res.data);
      let details = res.data.user;
      details.byUser = {
        id: details.id,
        email: details.email,
        firstName: details.firstName,
        lastName: details.lastName
      }

      setUserDetails(details);
      setAllGroups(res.data.groups);
      setSchemas(res.data.schemas);
      setChecklists(res.data.checklists);

      // determine user's highest role for add/admin buttons
      let groupStr = JSON.stringify(details.groupArr);
      let highest = 'viewer';
      if(details.supe) highest = 'admin';
      else if(groupStr.includes('owner')) highest = 'admin';
      else if(groupStr.includes('admin')) highest = 'admin';
      else if(groupStr.includes('editor')) highest = 'editor';
      else if(groupStr.includes('contributor')) highest = 'contributor';
      setHighestRole(highest);

      // format project managedByDisplay
      if(res.data.projects) {
        let proj = [...res.data.projects];
        for (const project of proj) {
          if(project.managedBy.length === 0) continue;
          project.managedByDisplay = userArray(res.data.users, project.managedBy);
        }
        setProjects(proj);
      }

      if(res.data.tasks) {
        // format task assigned to
        let tasks = [...res.data.tasks];
        for (const task of tasks) {
          let projectIds = task.parentIds.filter(x => x.type==='project').map(x => x.id);
          let linked = [];
          for (const id of projectIds) {
            let found = res.data.projects.find(x => x.id === id);
            let str = `<a href="/project/${found.appId}">${found.name} (${String(found.id).padStart(3, '0')})</a>`;
            linked.push(str);
          }
          linked = linked.join('<br />');
          task.linkedProject = linked;

          if(task.assignedTo.length === 0) continue;
          task.assignedToDisplay = userArray(res.data.users, task.assignedTo);
        }
        setTasks(tasks);
      }

      if(res.data.users) {
        let users = [...res.data.users];
        for (const user of users) {
          user.rolesAccess = accessArray(res.data.groups, user.groupArr);
        }
        setAllUsers(users);
      }

      // lastly, setRegister to false in case they were registering
      setRegister(false);

    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  if(loading) return ( <Loading />);
  // lklklk you need to handle this... if you just do a return
  // the sockets etc. all disconnect...
  if(userError || error) return ( <Error msg="Not authorized. (001)" />)

  // if this person doesn't have any user groups or assigned projects,
  // we probably want to forward them to a URL that is based on the site config

  // or... are we just going to show 'my tickets' ... ?

  return (
    <UserContext.Provider value={{
        register,
        userDetails,
        highestRole,
        allGroups,
        allUsers,
        setAllUsers,
        schemas,
        setSchemas,
        projects,
        setProjects,
        tasks,
        setTasks,
        checklists,
        setChecklists
      }}>
      {children}
    </UserContext.Provider>
  );
};
