import React, { forwardRef } from 'react'
import { Controller } from 'react-hook-form'
import Select from 'react-select';

// future sortable tags with dnd-kit:
// https://github.com/JedWatson/react-select/blob/724921b64a29975392f930cd2d60cce2af361aec/docs/examples/MultiSelectSort.tsx

// schema example:
// { id: '__NEW_ID__', field: '__DB_FIELD__', type: 'autosuggest', multiple: true, label: 'Managed by', optionVar: 'all-users', options: [{value:'',label:'TBD'}] },

const Field = forwardRef(({ item, pieces, disabled, ...rest }, ref) => {
  if(!item.options) item.options = [];
  if(!pieces.vars) pieces.vars = {};
  if(!pieces.vars[item.optionVar]) pieces.vars[item.optionVar] = [];

  // merge the options for this component
  let options = [];
  item.options.forEach(obj => {
    let label = obj.label ? obj.label : obj.value;
    options.push({ value: obj.value, label: label})
  })

  pieces.vars[item.optionVar].forEach(obj => {
    let label = obj.label ? obj.label : obj.value;
    options.push({ value: obj.value, label: label})
  })

  return (
    <Controller
      name={item.field}
      control={pieces.control}
      rules={{ required: item.required }}
      render={({ field }) => {
        if(!field.value) field.value = '';

        let value = null;
        if(item.multiple && Array.isArray(field.value)) {
          value = [];
          field.value.forEach(obj => {
            let found = options.find(x => x.value === parseInt(obj));
            if(found) value.push(found);
          })

        } else {
          value = field.value ? options.find(x => x.value === field.value) : null;
        }

        return (
          <div className={ pieces.size === 'sm' ? 'my-0' : 'my-2'}>
            {item.label && (<label htmlFor={item.field}>{item.label}</label>)}
            {item.note && (<p className="mb-1 text-muted fst-italic small">{item.note}</p>)}
            <Select
              isClearable
              blurInputOnSelect
              ref={field.ref}
              name={item.field}
              xxdeleteName="maybe"
              isMulti={item.multiple}
              xxClassName="react-select"
              xxxClassName={`form-select ${(pieces.size) ? "form-select-"+pieces.size : ""}`}
              classNamePrefix="select"
              placeholder={item.placeholder}
              value={value}
              onChange={(e) => {
                if(item.multiple) {
                  // currently we wrap the IDs as strings for database searches, e.g. ["4","7"]
                  // in the future you may want to specify this or even map with a key, e.g. [{id:4},{id:7}]
                  let val = (e.length > 0) ? e.map(obj => String(obj.value)) : [];
                  pieces.setValue(`${item.field}`, val, { shouldDirty: true });
                  if(pieces.toParent) pieces.toParent({ type: 'autosuggest', name: item.field, value: val, autosave: item.autosave });
                } else {
                  let val = e?.value ? e.value : null;
                  // create a tempArray if needed
                  val = item.tempArray ? [String(val)] : val;
                  pieces.setValue(`${item.field}`, val, { shouldDirty: true });
                  if(pieces.toParent) pieces.toParent({ type: 'autosuggest', name: item.field, value: val, autosave: item.autosave });
                }
              }}

              options={options}
              disabled={item.disabled}
            />
            {pieces.errors[item.field] && <p className="mt-0 error">{item.errorMsg}</p>}
          </div>
        )
      }}
    />
  )
});

export default Field;
