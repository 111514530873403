import React, { Fragment } from 'react';
import dayjs from 'dayjs'

const View = (props) => {
  const { photos, types, plots } = props; // also receiving libraries

  return (
    <Fragment>
      { plots.map((obj) => {
        let found = types.find(x => x.id === obj.typeId);
        if(!found) found = { description: 'TBD' };
        let library = found.libraryId===2 ? 'Legacy' : 'WGS';
        let plotPics = photos.filter(x => x.phaseId === obj.phaseId && x.status === 'A');
        let date = obj.updatedAt ? obj.updatedAt : obj.createdAt;
        date = dayjs(date).format('MMM DD, YYYY'); // h:mma

        return (
          <div key={obj.id+'-'+obj.appId} className="card mb-2">
            <div className="card-body">
              <h5 className="card-title">{library}: {found.description}</h5>
              <h6 className="card-subtitle mb-2 text-muted">Updated {date}</h6>
              { plotPics && (
                <div className="horizontal-scroll mb-3">
                  { plotPics.map(img => (
                    <a key={img.appId} href={img.url} target="_blank" rel="noreferrer">
                      <img src={img.url} className="horizontal-thumb border" alt="Site Photo" />
                    </a>
                  ))}
                </div>
              )}
              <p className="card-text">
                { obj.udf7 && <span className="small">Variation<br /></span>}
                { obj.udf7 && <span>{obj.udf7}</span>}
                { obj.udf8 && <span> x {obj.udf8}</span>}
                { obj.udf9 && <span><br />{obj.udf9}</span>}
                { obj.udf10 && <span><br />{obj.udf10}</span>}
                { obj.udf11 && <span><br />{obj.udf11}</span>}
                { obj.udf12 && <span><br />{obj.udf12}</span>}
                { obj.udf13 && <span><br />{obj.udf13}</span>}
              </p>

              { obj.udf1 && (
                <Fragment>
                  <h6>Condition</h6>
                  <p>{obj.udf1}</p>
                </Fragment>
              )}

              { obj.udf2 && (
                <Fragment>
                  <h6>Condition Notes</h6>
                  <p>{obj.udf2}</p>
                </Fragment>
              )}
              {/* <a href="#" className="card-link">Card link</a>
              <a href="#" className="card-link">Another link</a> */}
            </div>
          </div>
        )
      })}
    </Fragment>
  )
}

export default View;
