import React, { Fragment } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Helmet } from 'react-helmet';

import { ArrowLeftShort } from 'react-bootstrap-icons';

// remember that for Review routing you'll need
// to check to see if theres a planId and go up 2 levels
// otherwise it appends mode after the planId

const View = (props) => {
  const { task } = props;
  const { user, logout, isAuthenticated } = useAuth0();
  const params = useParams();

  return (
    <Fragment>
      <Helmet>
        <style>{` body { padding-top:4.3rem; } `}</style>
      </Helmet>
      <nav className="navbar navbar-expand-md navbar-dark bg-dark fixed-top">
        <div className="container-fluid">
          <Link className="navbar-brand me-1" aria-current="page" to="../tasks"><ArrowLeftShort size={28} /></Link>
          <span className="navbar-brand">
            { task.taskType === 'application' && 'Application' }
            { task.taskType === 'survey' && 'Inspection' }
          </span>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar" aria-controls="navbar" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbar">
            <ul className="navbar-nav ms-auto mb-2 mb-md-0">
              { isAuthenticated && (
                <Fragment>
                  {(task.taskType === 'application') && (
                    <li className="nav-item">
                      { params.mode === 'review' && <Link className="nav-link" aria-current="page" to={`../tasks/${params.taskId}`}>Back to Map</Link>}
                      { params.mode !== 'review' && <Link className="nav-link" aria-current="page" to="./review">Review</Link>}
                    </li>
                  )}
                  <li className="nav-item dropdown ms-2">
                    <span className="nav-link dropdown-toggle pointer" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                      <img alt={user?.name} src={user?.picture} className="avatar" />
                    </span>
                    <ul className="dropdown-menu dropdown-menu-md-end" aria-labelledby="navbarDropdown">
                      <li className="pointer dropdown-item" onClick={() => {
                        logout({ returnTo: window.location.origin })
                      }}>Logout</li>
                      <li><hr className="dropdown-divider" /></li>
                      <li><p className="dropdown-item small mb-0 text-muted">Version 8.19</p></li>
                    </ul>
                  </li>
                </Fragment>
              )}
              {/* not including login button here due to OIDC/email options */}
            </ul>
          </div>
        </div>
      </nav>
    </Fragment>
  )
}

export default View;
