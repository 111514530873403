import React, { forwardRef } from 'react';
import { Binoculars, Pencil, Eye, Search } from 'react-bootstrap-icons'


// lklklk future:
// add tooltip/hover over


const Field = forwardRef(({ item, pieces }, ref) => {
  const clicked = () => {
    if(pieces.toParent) pieces.toParent({ type: item.action });
  }

  return (
    <div className="mx-1 my-2 text-muted pointer" onClick={clicked}>
      {item.icon === 'eye' && <Eye size={18} />}
      {item.icon === 'pencil' && <Pencil size={18} className="ms-2" />}
      {item.icon === 'search' && <Search size={18} />}
      {item.icon === 'binoculars' && <Binoculars size={18} />}
    </div>
  )

});

export default Field;

