export const filterExists = (filters, name, group) => (
  filters.find((f) => f.name === name && f.group === group) !== undefined
);

const addFilter = (setFilters, name, group, fnc) => {
  setFilters((currentFilters) => [...currentFilters, { name, group, fnc }]);
}

const removeFilter = (setFilters, name, group) => {
  setFilters((currentFilters) =>
    currentFilters.filter((f) => !(f.name === name && f.group === group))
  );
}

export const toggleFilter = (filters, setFilters, name, group, fnc) => {
  if(filterExists(filters, name, group))
    removeFilter(setFilters, name, group)
  else
    addFilter(setFilters, name, group, fnc)
}
