import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { UserContext } from '../../../context/UserContext';
import { useAxios } from '../../../hooks/useAxios';
import { nanoid } from 'nanoid';

import { ArrowLeftRight, Link as LinkIcon } from 'react-bootstrap-icons'
import Checkboxes from '../../../components/system/Checkboxes';
import ConfirmBtn from '../../../components/buttons/ConfirmBtn';

// future: add ability to filter the project list for a quick find

import { emptyObj } from '../../../utils/functions';

const View = (props) => {
  const navigate = useNavigate();
  const { obj, setTasks, toParent } = props;
  const { projects, setProjects } = useContext(UserContext);
  const [projArr, setProjArr] = useState([]);
  const [linked, setLinked] = useState([]);

  const { serverCall } = useAxios();

  useEffect(() => {
    let vals = projects.map(x => ({ value: String(x.id), label: `${String(x.id).padStart(3, '0')}: ${x.name}` }));
    setProjArr(vals);
  }, [projects])

  useEffect(() => {
    if(emptyObj(obj)) return;
    let linked = obj.parentIds.filter(x => x.type === 'linked');
    if(linked.length > 0) linked = linked.map(x => String(x.id));
    setLinked(linked);
  }, [obj])

  const fromChecks = (data) => {
    // remove any existing links, only leave projects
    // then spin through values to re-create parentIds
    let newIds = obj.parentIds.filter(x => x.type === 'project');
    for(const id of data) {
      newIds.push({type:'linked', id: parseInt(id)});
    }
    if(toParent) toParent({ type: 'links updated', value: newIds });
  }

  const convert = async () => {
    let appId = nanoid();
    let proj = {
      access: obj.access,
      appId: appId,
      name: obj.name,
      description: obj.udf4, // description, should adjust this in tasks lklklk
      udf1: obj.udf1, // location
      createdAt: obj.createdAt,
      createdBy: obj.createdBy,
      publicStatus: 'Open',
      status: 'A',
      visible: 'Y'
    };

    // create the new project
    let res = await serverCall({ method: 'POST', data: proj, url: `/mapping/projects`, eamsAuth0: true });
    if(res.status !== 200) return alert('Error converting to project. Contact support.'); // lklklk
    setProjects((prev) => {
      const all = [...prev];
      all.push(res.data);
      return all;
    });

    // update this tasks linked items and close
    let newIds = obj.parentIds.filter(x => x.type === 'project');
    newIds.push({ type: 'linked', id: res.data.id });
    for(const id of linked) {
      newIds.push({type:'linked', id: parseInt(id)});
    }
    let update = await serverCall({ method: "PATCH", data: { appId: obj.appId, id: obj.id, parentIds: newIds, publicStatus: 'Closed' }, url: `/system/tasks/${obj.appId}`, eamsAuth0: true });
    if(update.status !== 200) return alert('Error linking task to new project. Contact support.'); // lklklk

    setTasks((prev) => {
      const all = [...prev];
      let idx = all.findIndex(x => x.id === obj.id);
      all[idx].parentIds = newIds;
      return all;
    });

    // then navigate to the new project
    navigate(`/project/${appId}`);
  }

  return (
    <Fragment>
      <h5><ArrowLeftRight size={13} className="me-1" /> Convert to Project</h5>
      <ConfirmBtn wrapper="text-center" toParent={convert}
        warning="Are you sure you want to convert this task to a project?">
        Start Conversion
      </ConfirmBtn>
      <hr />
      <h5 className="mb-3"><LinkIcon className="me-1" /> Link Projects</h5>
      <div className="overflow-scroll" style={{height:'40vh'}}>
        <Checkboxes arr={projArr} setArr={setProjArr} checked={linked} setChecked={setLinked} toParent={fromChecks} />
      </div>

    </Fragment>
  )
}

export default View;
