import React, { Fragment, useState } from 'react';
import Collapse from 'react-bootstrap/Collapse';
import RotatingCaret from '../buttons/RotatingCaret';

// reminder that you need to pass the empty div
// so the animation doesn't jump from padding/margins

// how to use:
// import Collapsed from '../../../components/system/Collapsed';
// <Collapsed title="Phases">
//   <div className="mt-2">
//     <Phases projectId={project.id} phases={phases} setPhases={setPhases} />
//   </div>
// </Collapsed>

const View = ({ title, children }) => {
  const [show, setShow] = useState(false);
  const toggleShow  = () => setShow((s) => !s);

  return (
    <Fragment>
      <div className="py-2 pointer" onClick={toggleShow}>
        <RotatingCaret rotated={show} />
        <h5 className="mb-0">{title ? title : 'View'}</h5>
      </div>
      <Collapse in={show}>
        <div>
          {children}
        </div>
      </Collapse>
    </Fragment>
  )
}

export default View;
