import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { ThreeDotsVertical } from 'react-bootstrap-icons'

export function SortableItem(props) {
  const { sort, child: SortItem, idx, obj, pieces, length, handle, toParent } = props;
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({id: props.id});

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  if(handle === 'start') return (
    <div ref={setNodeRef} style={style} className="d-flex align-items-center">
      <ThreeDotsVertical size={18} className="me-2 my-2 text-muted move" {...attributes} {...listeners} tabIndex="-1" />
      <div className="flex-grow-1">
        <SortItem key={sort} idx={idx} obj={obj} pieces={pieces} length={length} toParent={toParent} />
      </div>
    </div>
  )

  if(handle === 'end') return (
    <div ref={setNodeRef} style={style} className="d-flex align-items-center">
      <div className="flex-grow-1">
        <SortItem key={sort} idx={idx} obj={obj} pieces={pieces} length={length} toParent={toParent} />
      </div>
      <ThreeDotsVertical size={18} className="me-2 my-2 text-muted move" {...attributes} {...listeners} tabIndex="-1" />
    </div>
  )

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <SortItem key={sort} idx={idx} obj={obj} pieces={pieces} length={length} toParent={toParent} />
    </div>
  );
}
