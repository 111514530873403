import { emptyObj, isNumeric, pickTextColor } from './functions'

export const locatorShapeIds = [
  { value: 1, label: 'Circle' },
  { value: 2, label: 'Rectangle' },
  { value: 3, label: 'Triangle' },
  // { value: 4, label: 'Icon' },
  // { value: 5, label: 'Thumbnail' },
  { value: 6, label: 'Polyline' },
  { value: 7, label: 'Polygon' },
]

export const labelShapeIds = [
  { value: 1, label: 'Circle' },
  { value: 2, label: 'Rectangle' },
  { value: 3, label: 'Triangle' },
  // { value: 4, label: 'Icon' },
  // { value: 5, label: 'Thumbnail' }
]

export const markerIds = [
  { value: 1, label: 'Lozenge' },
  { value: 2, label: 'Directional' },
  { value: 3, label: 'A/B' }
]

export const textLabelIds = [
  // if any of these are updated also update createLabelText below, too
  { value: 1, label: '123' },
  { value: 2, label: 'ID-123' },
  { value: 3, label: 'ID/123' },
  // 4 is missing for some reason
  { value: 5, label: 'ID.123' },
  { value: 6, label: 'ID' },
  { value: 7, label: '01-123' },
  { value: 8, label: '01/123' },
  { value: 9, label: '01.123' },
  { value: 10, label: '01-ID-123' },
  { value: 11, label: '01/ID/123' },
  { value: 12, label: '01.ID.123' },
  { value: 13, label: 'ID-01-123' },
  { value: 14, label: 'ID/01/123' },
  { value: 15, label: 'ID.01.123' },
  // lklklk this should be switched to a specific UDF based on WFI
  // { value: 16, label: 'ARCHROOM' },
  // { value: 17, label: 'ARCHROOM-123' },
]

export const getPlotDisplay = ({ project, plans, types, plot }) => {
  // typeFill, typeText = type + variation color
  // statusFill, statusTExt = status color
  if(!project) project = {};
  
  if(emptyObj(project.styling)) project.styling = { text: 1, type: 'simple', locator: 1 }
  // arch example:
  // { label: 1, type: 'arch', locator: 1, locatorColor: '#ff0', lineColor: '#f00', label: 2 }

  let displayType = project.styling.type ? project.styling.type : 'simple';
  let locatorId = project.styling.locatorId ? project.styling.locatorId : 1;
  let lineColor = project.styling.lineColor ? project.styling.lineColor : '#000';
  let locatorFill = project.styling.locatorColor ? project.styling.locatorColor : lineColor;
  let labelId = project.styling.labelId ? project.styling.labelId : 2;
  let labelFill = project.styling.labelColor ? project.styling.labelColor : '#ccc';
  let textId = project.styling.textId ? project.styling.textId : 1;
  let textFill = project.styling.textFill ? project.styling.textFill : pickTextColor(locatorFill);
  let borderColor = project.styling.borderColor ? project.styling.borderColor : '#000';

  // format text values
  let archRoomNo = plot.archRoomNo ? plot.archRoomNo.replaceAll(/\s/g,'') : 'ARCHROOM';
  let plotName = plot.name ? plot.name.replaceAll(/\s/g,'') : 'TBD';
  if(isNumeric(plotName)) plotName = plotName.toString().padStart(3, '0');
  // future: will need to include linkedFields/figure out how that works

  // find the plan code
  let plan = plans?.find(x => x.id === parseInt(plot.planId));
  let planCode = plan?.code ? plan.code : 'TBD';

  // find the type/variation info
  let type = types?.find(x => x.id === parseInt(plot.typeId));
  let typeCode = type?.code ? type.code : 'TBD';

  if(type && !emptyObj(type.styling)) {
    let style = type.styling;

    // only override display type if locatorId and/or labelId exist
    if(style.labelId)
      displayType = 'arch';
    else if(style.locatorId)
      displayType = 'simple';

    locatorId = style.locatorId ? style.locatorId : locatorId;

    if(displayType==='simple') {
      locatorFill = style.fillColor ? style.fillColor : locatorFill;
      textFill = style.textFill ? style.textFill : pickTextColor(locatorFill);
    } else {
      labelFill = style.fillColor ? style.fillColor : labelFill;
      textFill = style.textFill ? style.textFill : pickTextColor(labelFill);
    }

    lineColor = style.lineColor ? style.lineColor : lineColor;
    labelId = style.labelId ? style.labelId : labelId;
    textId = style.textId ? style.textId : textId;
    borderColor = style.borderColor ? style.borderColor : borderColor;
  }

  // lklklk: need to add variation overrides here, above label

  let textLabel = createLabelText({ textId, plotName, archRoomNo, planCode, typeCode });

  if(displayType === 'simple')
    plot.display = { type: displayType, locatorId, locatorFill, textFill, textLabel, borderColor }
  else
    plot.display = { type: displayType, locatorId, locatorFill, lineColor, labelId, labelFill, textFill, textLabel, borderColor }

  return plot;
}

const createLabelText = ({ textId, planCode, typeCode, plotName }) => {
  // not currently sending in linked field as we weren't sure how to implement
  // let linkedField = 'TBD';

  // if any of these are updated, update textLabelIds above too
  switch (textId) {
    case 1:  return plotName;
    case 2:  return typeCode + '-' + plotName;
    case 3:  return typeCode + '/' + plotName;
    // 4 is missing for some reason
    case 5:  return typeCode + '.' + plotName;
    case 6:  return typeCode;
    case 7:  return planCode + '-' + plotName;
    case 8:  return planCode + '/' + plotName;
    case 9:  return planCode + '.' + plotName;
    case 10: return planCode + '-' + typeCode + '-' + plotName;
    case 11: return planCode + '/' + typeCode + '/' + plotName;
    case 12: return planCode + '.' + typeCode + '.' + plotName;
    case 13: return typeCode + '-' + planCode + '-' + plotName;
    case 14: return typeCode + '/' + planCode + '/' + plotName;
    case 15: return typeCode + '.' + planCode + '.' + plotName;
    // lklklk we are moving archRoom to a udf
    // so this should be resolved... 
    // case 16: return archRoomNo;
    // case 17: return archRoomNo + '-' + plotName;
    // not sure how these were actually going to be implemented
    // case 18: return linkedField;
    // case 19: return linkedField + '-' + plotName;
    default: return plotName;
  }
}