import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { matchSorter } from 'match-sorter';

import { UserContext } from '../../../../context/UserContext';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import FilterCheckboxes from '../../../../components/system/filters/Checkboxes';

import { emptyObj } from '../../../../utils/functions';

const Group = {
  TYPE: 'typeId',
  VARIATION: 'variationId',
  INSTALL: 'installMode',
  STATUS: 'status',
  PHASE: 'phase',
  GROUP: 'planGroup',
  PLAN: 'plan',
  TAG: 'tag'
}

const isShownByStatus = (item, filters) => {
  let filtered = filters.filter((filter) => filter.group === Group.STATUS);
  if (!filtered.length) return true;
  return filtered.some((filter) => filter.fnc(item));
}

const isActivePhase = (item, phase) => {
  if(emptyObj(phase)) return true;
  return (item.phaseId === phase.id) ? true : false;
}

const isActivePlan = (item, plan) => {
  if(emptyObj(plan)) return true;
  return (item.planId === plan.id) ? true : false;
}

const isShownByTypeId = (item, filters) => {
  let filtered = filters.filter((filter) => filter.group === Group.TYPE);
  if (!filtered.length) return true;
  return filtered.some((filter) => filter.fnc(item));
}

// we do want to keep this in case they want to filter
// the list/reports by specific plans
const isShownByPlanId = (item, filters) => {
  let filtered = filters.filter((filter) => filter.group === Group.PLAN);
  if (!filtered.length) return true;
  return filtered.some((filter) => filter.fnc(item));
}

const isShownByTagId = (item, filters) => {
  let filtered = filters.filter((filter) => filter.group === Group.TAG);
  if (!filtered.length) return true;
  return filtered.some((filter) => filter.fnc(item));
}

export const applyFilters = (activePhase, activePlan, data, filters, search, bounds, projectId) => {
  if(!data) data = [];

  if(bounds && !emptyObj(bounds)) {
    data = data.filter(x => (
      x.styling.lat <= bounds._northEast.lat && 
      x.styling.lat >= bounds._southWest.lat &&
      x.styling.lng <= bounds._northEast.lng && 
      x.styling.lng >= bounds._southWest.lng
  ))}

  // apply filters
  let active = data.filter((plot) => {
    // if this is permanent (sfo/icp), set as true and we filter later
    const showByActivePhase = projectId ? true : isActivePhase(plot, activePhase);

    // all others are default
    const showByActivePlan = isActivePlan(plot, activePlan);
    const showByStatus = isShownByStatus(plot, filters);
    const showByPlanId = isShownByPlanId(plot, filters);
    const showByTypeId = isShownByTypeId(plot, filters);
    const showByTagId  = isShownByTagId(plot, filters);
    return showByActivePlan && showByActivePhase && showByStatus && showByPlanId && showByTypeId && showByTagId;
  });

  // then apply any search terms
  if(search) {
    active = matchSorter(active, search, {keys: [
      {threshold: matchSorter.rankings.CONTAINS, key: 'name'},
      {threshold: matchSorter.rankings.CONTAINS, key: 'udf1'},
      {threshold: matchSorter.rankings.CONTAINS, key: 'udf2'},
      {threshold: matchSorter.rankings.CONTAINS, key: 'udf3'},
      {threshold: matchSorter.rankings.CONTAINS, key: 'udf4'},
      {threshold: matchSorter.rankings.CONTAINS, key: 'udf5'},
    ]});
  }

  // if projectId exists, split between visible and active
  if(projectId && !emptyObj(activePhase)) {
    // console.clear();
    // pull out the ones that are active/currently in this phase
    // then filter the outies and sort desc so we see the most recent ones (in theory)
    // if this starts to slow down consider reduce/some:
    // https://stackoverflow.com/a/45440277/983017

    // also possible future editing if it gets slow:
    // remove project + phase (innies)
    // sort by id desc
    // reduce by appId
    // recombine

    let innies = active.filter(x => x.projectId === projectId && x.phaseId === activePhase.id);
    let outies = active.filter(x => x.projectId !== projectId && x.phaseId !== activePhase.id).sort((a,b) => (b.id > a.id) ? 1 : ((a.id > b.id) ? -1 : 0));
    let appIds = innies.map(x => x.appId);
    for(const plot of outies) {
      if(appIds.includes(plot.appId)) continue;
      plot.disabled = true;
      innies.push(plot);
      appIds.push(plot.appId);
    }

    active = innies;
  }

  // then sort
  // active.sort((a, b)=> (a.name.localeCompare(b.name, 'en', { numeric: true })))
  active.sort((a, b) => ((a || {}).name || '').localeCompare((b || {}).name || '', undefined, { numeric: true, sensitivity: 'base' }));
  // active.sort((a,b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0))
  // active.sort((a,b) => (b.id > a.id) ? 1 : ((a.id > b.id) ? -1 : 0))
  // console.log('Filtered qty:', active.length);
  // console.log(active);
  return active;
}

const View = (props) => {
  const { plans, types, filters, setFilters, setSearch } = props;
  const { schemas } = useContext(UserContext);

  const defaultsRef = useRef([]);
  const [statuses, setStatuses] = useState([]);
  const [publicStatus, setPublicStatus] = useState([]);

  const resetFilters = () => {
    setFilters(defaultsRef.current);
  }

  useEffect(() => {
    defaultsRef.current = [];
    let found = schemas.find(x => x.name === 'statuses');
    if(found?.schema) {
      setStatuses(found.schema);
      for(const obj of found.schema) {
        if(obj.active)
          defaultsRef.current.push({name: obj.value, group: 'status', fnc: (x) => x.status === obj.value})
      }
    }

    found = schemas.find(x => x.name === 'plot-status');
    if(found?.schema) {
      setPublicStatus(found.schema);
      for(const obj of found.schema) {
        if(obj.active)
          defaultsRef.current.push({name: obj.value, group: 'publicStatus', fnc: (x) => x.publicStatus === obj.value})
      }
    }

    // found = schemas.find(x => x.name === 'project-scale');
    // if(found) setScale(found.schema);

    // found = schemas.find(x => x.name === 'project-priority');
    // if(found) setPriority(found.schema);

    setFilters(defaultsRef.current);
  }, [schemas, setFilters])

  return (
    <Fragment>
      <div className="dropdown d-inline-block float-end">
        <button className="btn border bg-white shadow-sm dropdown-toggle" type="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
          Filters { JSON.stringify(filters) !== JSON.stringify(defaultsRef.current) && (`(${filters.length})`)}
        </button>

        <ul className="dropdown-menu dropdown-menu-end" style={{width:300, maxHeight:415, overflow: 'scroll'}}>

          { JSON.stringify(filters) !== JSON.stringify(defaultsRef.current) && (
            <Fragment>
              <li className="text-end">
                <OverlayTrigger placement="bottom" overlay={<Tooltip id={`tooltip-reset`}>Clear filters</Tooltip>}>
                  <button className="btn btn-sm btn-outline-dark mx-2" type="button" onClick={resetFilters}>
                    Clear Filters
                  </button>
                </OverlayTrigger>
              </li>
              <li><hr className="dropdown-divider" /></li>
            </Fragment>
          )}

          {/*<FilterCheckboxes arr={priority} group={Group.PRIORITY} field="priority" title="Priority" filters={filters} setFilters={setFilters} />
          <li><hr className="dropdown-divider" /></li>
          <FilterCheckboxes arr={scale} group={Group.SCALE} field="udf1" title="Scale" filters={filters} setFilters={setFilters} />
          <li><hr className="dropdown-divider" /></li>
          <FilterCheckboxes arr={publicStatus} group={Group.PUBLICSTATUS} field="publicStatus" title="Status" filters={filters} setFilters={setFilters} />
          <li><hr className="dropdown-divider" /></li>
          <FilterCheckboxes arr={types} group={Group.TYPE} field="typeId" title="By Type" filters={filters} setFilters={setFilters} />
          <li><hr className="dropdown-divider" /></li>*/}
          <FilterCheckboxes arr={statuses} group={Group.STATUS} field="status" title="Active" filters={filters} setFilters={setFilters} />
        </ul>
      </div>

      <form className="d-inline-block mb-2 me-2 float-end" style={{width:150}}>
        <input style={{height:34}} className="form-control shadow-sm" type="search" placeholder="Search plots" aria-label="Search" onChange={(e) => setSearch(e.target.value)} />
      </form>
    </Fragment>
  )
}

export default View;
