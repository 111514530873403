import { nanoid } from 'nanoid';
import dayjs from 'dayjs';

import { sumByTwoKeys } from '../../../../utils/functions';
import { getPlotDisplay } from '../../../../utils/plots';

export const newPlot = ({ typeId, project, plans, types, planRef, activePhase, pieces, userDetails }) => {
  let plot = {
    appId: nanoid(),
    projectId: project.id,
    planId: planRef.current.id,
    phaseId: activePhase.id,
    name: pieces.nextPlot,
    installMode: 'New',
    styling: {locatorX: pieces.left, locatorY: pieces.top},
    createdAt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
    createdBy: userDetails.id,
    status: 'A'
  }

  if(typeId) plot.typeId = typeId;

  plot = getPlotDisplay({ project, plans, types, plot });
  return plot;
}


export const copyPlot = ({ project, plans, types, planRef, activePhase, ogPlot, userDetails }) => {
  let plot = {...ogPlot};
  plot.styling = {...ogPlot.styling};

  // delete conflicting fields
  delete plot.id;
  delete plot.appId;
  delete plot.updatedAt;
  delete plot.updatedBy;

  // generate new values
  plot.appId = nanoid();
  plot.planId = planRef.current.id;
  plot.phaseId = activePhase.id;
  plot.createdAt = dayjs().format('YYYY-MM-DD HH:mm:ss');
  plot.createdBy = userDetails.id;
  plot.status = 'A';

  // if we are pasting to the same plan, we need to offset the coords
  if(plot.planId === ogPlot.planId) {
    plot.styling.locatorX = plot.styling.locatorX + 25;
    plot.styling.locatorY = plot.styling.locatorY + 25;
    if(plot.styling.labelX) plot.styling.labelX = plot.styling.labelX + 25;
    if(plot.styling.labelY) plot.styling.labelY = plot.styling.labelY + 25;
  }

  // reformat plot display in case the plan has changed
  plot = getPlotDisplay({ project, plans, types, plot });
  return plot;
}

export const addPlot = ({ setPlots, plot }) => {
  setPlots((prev) => {
    let arr = [...prev];
    arr.push(plot);
    return arr;
  })
}

export const replacePlot = ({ setPlots, plot }) => {
  setPlots((prev) => {
    let arr = [...prev];
    let idx = arr.findIndex(x => x.appId===plot.appId && x.phaseId===plot.phaseId);
    arr[idx] = plot;
    return arr;
  })
}

export const updatePlot = ({ project, plans, types, setPlots, plot }) => {
  return new Promise(async(resolve) => {
    let obj;
    await setPlots((prev) => {
      let arr = [...prev];
      let idx = arr.findIndex(x => x.appId===plot.appId && x.phaseId===plot.phaseId);
      for (const [key, value] of Object.entries(plot)) {
        arr[idx][key] = value;
      }
      arr[idx] = getPlotDisplay({ project, plans, types, plot: arr[idx] });
      obj = arr[idx];
      return arr;
    })
    resolve(obj);
  })
};

export const rotatePlot = ({ setPlots, plot, userDetails }) => {
  return new Promise(async(resolve) => {
    let styling;
    await setPlots((prev) => {
      let arr = [...prev];
      let idx = arr.findIndex(x => x.appId===plot.appId && x.phaseId===plot.phaseId);
      arr[idx].styling = {...arr[idx].styling, rotation: plot.rotation};
      styling = arr[idx].styling;
      return arr;
    })
    resolve({
      appId: plot.appId,
      phaseId: plot.phaseId,
      styling,
      updatedAt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
      updatedBy: userDetails.id
    });
  })
};

export const updatePoints = ({ setPlots, plot, userDetails }) => {
  return new Promise(async(resolve) => {
    let styling;
    await setPlots((prev) => {
      let arr = [...prev];
      let idx = arr.findIndex(x => x.appId===plot.appId && x.phaseId===plot.phaseId);
      styling = {...arr[idx].styling};
      styling.points = plot.points;
      if(plot.piece==='locator') {
        styling.locatorX = plot.left;
        styling.locatorY = plot.top;
      } else {
        styling.labelX = plot.left;
        styling.labelY = plot.top;
      }      
      arr[idx].styling = styling;
      styling = arr[idx].styling;
      return arr;
    })
    resolve({
      appId: plot.appId,
      phaseId: plot.phaseId,
      styling,
      updatedAt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
      updatedBy: userDetails.id
    });
  })
};

export const movePlots = ({ setPlots, plots, userDetails }) => {
  return new Promise(async(resolve) => {
    let updates = [];
    await setPlots((prev) => {
      let arr = [...prev];
      for(const plot of plots) {
        let found = arr.find(x => x.id===plot.id);
        if(!found) continue;
        if(plot.piece==='locator') {
          found.styling.locatorX = plot.left;
          found.styling.locatorY = plot.top;
        } else {
          found.styling.labelX = plot.left;
          found.styling.labelY = plot.top;
        }

        // check if this update already exists, otherwise add
        let idx = updates.findIndex(x => x.id === found.id);
        if(idx > -1)
          updates[idx].styling = found.styling;
        else
          updates.push({ id: found.id, appId: found.appId, phaseId: found.phaseId, styling: found.styling });
      }
      return arr;
    })

    resolve({
      updates,
      updatedAt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
      updatedBy: userDetails.id
    });
  })
};

export const toggleStatus = ({ setPlots, plotIds, userDetails, status }) => {
  return new Promise(async(resolve) => {
    let updates = []
    await setPlots((prev) => {
      let arr = [...prev];
      for(const id of plotIds) {
        let found = arr.find(x => x.id === id);
        if(found) found.status = status;
        updates.push({ id: found.id, appId: found.appId, phaseId: found.phaseId, status });
      }
      return arr;
    })

    resolve({
      updates,
      updatedAt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
      updatedBy: userDetails.id
    });
  })
};

export const typeQtys = (types, plots) => {
  if(plots.length === 0) return [];

  // const start = performance.now();
  let summary = [];
  const qtys = sumByTwoKeys(plots,'typeId','variationId');

  for (const qty of qtys) {
    if(!qty.typeId) {
      qty.code = 'TBD';
      qty.description = 'TBD';
      qty.fillColor = '#ccc';
      qty.textColor = '#000';
      qty.labelId = 1;
    } else {
      let type = types.find(x => x.id === Number(qty.typeId));
      if(!type) {
        qty.code = 'TBD';
        qty.description = 'Missing type';
        qty.fillColor = '#f00';
        qty.textColor = '#fff';
        qty.labelId = 1;
      } else {
        qty.code = type.code;
        qty.description = type.description;
        qty.fillColor = type.styling.fillColor ? type.styling.fillColor : '#ccc';
        qty.textColor = type.styling.textFill;
        qty.labelId = type.styling.labelId;

        if(type.multiplier > 1) qty.quantity = qty.quantity * type.multiplier;
      }

      // check for variation override
      // if(qty.variationId && type.assetVariations.length > 0 ) {
      //   let variation = type.assetVariations.find(x => x.id === qty.variationId);
      //   if(variation) {
      //     qty.code = variation.code;
      //     qty.description = variation.description;
      //     qty.fillColor = variation.fillColor;
      //     qty.textColor = variation.textColor;
      //     qty.labelId = variation.labelId;
      //     qty.shape = variation.shape;
      //   }
      // }
    }
    summary.push(qty);
  }

  // sort by code, then description
  summary.sort((a, b)=> (
    a.code?.localeCompare(b.code, 'en', { numeric: true }) ||
    a.description?.localeCompare(b.description, 'en', { numeric: true })
  ));

  // then get a total and append to the bottom
  let total = summary.reduce((total, obj) => obj.quantity + total,0);
  summary.push({ typeId: 'total', quantity: total});

  // const duration = performance.now() - start;
  // console.log(duration);
  return summary;
}