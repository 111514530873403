import React, { useContext, useEffect, useState } from 'react';
import { SiteContext } from '../../../context/SiteContext';
import { UserContext } from '../../../context/UserContext';
import { useAxios } from '../../../hooks/useAxios';

import FormModal from '../../../components/system/FormModal';

import { userArray }   from '../../../utils/functions';
import { formatEmail } from '../../../utils/emails';

const View = (props) => {
  const { parentType, parentId } = props;
  const modalText = props.modalText ? props.modalText : { button: 'Create Task', title: 'Create New' };
  const { config, siteSchemas } = useContext(SiteContext);
  const { userDetails, allUsers, schemas, tasks, setTasks, checklists } = useContext(UserContext);
  const { serverCall } = useAxios();

  const [taskSchema, setTaskSchema] = useState([]);
  const [taskStatus, setTaskStatus] = useState([])
  const [taskTypes, setTaskTypes] = useState([])
  const [parentArr, setParentArr] = useState([]);

  const [access, setAccess] = useState([]);
  const [accessUsers, setAccessUsers] = useState([]);

  const fromForm = async (data) => {
    let { type, status, value, response } = data;
    // value = original form values, response = response returned from server

    // once submitted, add to overall projects and open url
    if(type === 'access') {
      setAccess(value);
    } else if(type === 'submitted' && status === 200) {
      if(!response) alert('Error.'); //lklklk

      let item = response.item;
      // reminder that the attachment form component attaches data as eamsFiles
      // that's why they are properly linked but we don't see/adjust them here

      // format managedByDisplay and prepend to list
      item.assignedToDisplay = userArray(allUsers, item.assignedTo);
      let newTasks = [...tasks];
      newTasks.unshift(item);
      setTasks(newTasks);

      // create the emails...
      let emails = [];
      let emailData = {...value};
      let template = siteSchemas.find(x => x.type === 'email' && x.name === 'system-email');
      if(template?.schema?.template) {
        // task emails include user information,
        // need to add their user details for this template
        emailData.email = userDetails.email;
        emailData.firstName = userDetails.firstName;
        emailData.lastName = userDetails.lastName;

        // check if request is being filed for someone else;
        // search all users to get the requestor's info
        if(emailData.createdBy !== userDetails.id) {
          let found = allUsers.find(x => x.id === emailData.createdBy);
          if(found) {
            emailData.email = found.email;
            emailData.firstName = found.firstName;
            emailData.lastName = found.lastName;
          }
        }

        // created the assigned to email
        if(emailData.assignedTo?.length > 0) {
          let email = formatEmail({
            template: 'task assigned',
            html: template.schema.template,
            senderObj: config.supportEmail,
            recipientIds: emailData.assignedTo,
            subject: `${config.title}: ${emailData.name} Assigned`,
            emailData,
            logObj: { createdBy: userDetails.id }
          });
          emails.push(email)
        }

        // create the requestor copied email
        if(value.emailRequestor) {
          let email = formatEmail({
            template: 'ticket received',
            html: template.schema.template,
            senderObj: config.supportEmail,
            recipientIds: [emailData.createdBy],
            subject: `${config.title}: ${emailData.name} Received`,
            emailData,
            logObj: { createdBy: userDetails.id }
          });
          emails.push(email)
        }
      }

      // lKLKLK WILL NEED TO ADD TASK ID INFO TO THE EMAIL
      // only send task assignment email if site config allows
      if(config.taskEmails && emails.length > 0) {
        let res = await serverCall({ method: 'post', data: emails, url: '/system/email', eamsAuth0: true });
        console.log(res.status);
      }
    }
  }

  useEffect(() => {
    let found = schemas.find(x => x.name === 'task');
    if(found) setTaskSchema(found.schema);

    found = schemas.find(x => x.name === 'task-status');
    if(found) setTaskStatus(found.schema);

    found = schemas.find(x => x.name === 'task-types');
    if(found) setTaskTypes(found.schema);
  }, [schemas])

  useEffect(() => {
    if(!parentType || !parentId) return;
    setParentArr([{ type: parentType, id: parentId }]);
  }, [parentType, parentId])

  useEffect(() => {
    if(!access) return;
    let ids = access.filter(x => x.type === 'group').map(obj => obj.id);
    let filtered = allUsers.filter(x => {
      let groups = x.groupArr.filter(x => ids.includes(x.id));
      if(groups.length > 0) return groups;
      else return null;
    })
    setAccessUsers(filtered);
  }, [access, allUsers])

  return (
    <FormModal debug={true} method="POST" url="/system/tasks" schema={taskSchema}
      vals={{
        parentIds: parentArr,
        createdBy: userDetails.id,
        publicStatus: taskStatus[0]?.value
      }}
      vars={{
        'task-types': taskTypes,
        'task-status': taskStatus,
        'access-users': accessUsers,
        'all-users': allUsers,
        'checklists': checklists
      }}
      createForm={true} updateForm={false} toParent={fromForm}
      modalText={modalText}
      formText={{ submit: 'Create', pending: 'Creating', success: 'Created', cancel: 'Cancel' }}
      submitType={{ type: 'close-modal' }}
    />
  )
}

export default View;
