import React from 'react';

const View = (props) => {
  const { plans, activePlan, setActivePlan } = props;

  const changePlan = (e) => {
    e.target.blur();
    let found = plans.find(x => x.id === parseInt(e.target.value));
    if(found) setActivePlan(found);
  }

  return (
    <select
      style={{width:260}}
      className="form-select form-select-sm d-inline-block border shadow-sm float-end me-2"
      value={activePlan.id}
      onChange={changePlan}>
      {plans.filter(x => x.status === 'A')
        .sort((a,b) => (a.sortOrder > b.sortOrder) ? 1 : ((b.sortOrder > a.sortOrder) ? -1 : 0))
        .map((plan, idx) => (
        <option key={`plan-${plan.id}`} value={plan.id}>{plan.name}</option>
      ))}
    </select>
  )
}

export default View;
