import { nanoid } from 'nanoid';

const init = ({ pieces, setWorkers, serverCall }) => {
  const worker = new Worker(new URL('./worker.js', import.meta.url));
  let newId = nanoid();

  worker.postMessage({ url: pieces.url, value: pieces.value });
  worker.onerror = (err) => err;
  worker.onmessage = async (e) => {
    const { status, value, files } = e.data;

    setWorkers((prev) => {
      const updatedWorkers = {...prev};
      updatedWorkers[newId].data = e.data;
      return updatedWorkers;
    });

    if(status === 'finished') {
      if(pieces.subtype === 'plan upload') {
        let res = await serverCall({ method: 'POST', data: { value, files }, url: `/mapping/plans/`, eamsAuth0: true });
        pieces.setPlans((prev) => [...prev, ...res.data.plans]);
        pieces.setPhasePlans((prev) => [...prev, ...res.data.phasePlans]);
      }

      worker.terminate();
    }
  };
  
  setWorkers((prev) => ({ ...prev, 
    [newId]: { 
      worker, 
      type: 'plan upload', 
      name: pieces.name,
      data: { status: 'starting', progress: 0 }
    }
  }));

}

export default init;