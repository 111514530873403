import React, { forwardRef, useEffect } from 'react'
import { Controller } from 'react-hook-form'

// how to use:
// <Textarea key={item.id} {...register(item.field)} item={item} pieces={{ control, errors, toParent, size }} />

// schema example:
// { id: '__NEW_ID__', field: '__DB_FIELD__', type: 'textarea', rows: 5, label: 'ze label', error: 'u fd up', required: 'wow u fucked', note: 'some other note', placeholder='Wow how r u' },

const Field = forwardRef(({ item, pieces, disabled, ...rest }, ref) => {
  if(!item.rows) item.rows = 3;

  const handleKeyDown = (e) => {
    e.target.style.height = 'inherit';
    e.target.style.height = `${e.target.scrollHeight+12}px`;
  }

  useEffect(() => {
    const element = document.querySelector(`textarea[name="${item.field}"]`);
    if(element) {
      element.style.height = 'inherit';
      element.style.height = `${element.scrollHeight+12}px`;
    }
  }, [item])

  return (
    <Controller
      name={item.field}
      control={pieces.control}
      rules={{ required: item.required }}
      render={({ field }) => {
        if(!field.value) field.value = '';

        return (
          <div className="my-2">
            {item.label && (<label htmlFor={item.field}>{item.label}</label>)}
            {item.note && (<p className="mb-1 text-muted fst-italic small">{item.note}</p>)}
            <textarea {...field}
              ref={field.ref}
              name={item.field}
              placeholder={item.placeholder}
              className={`form-control ${(pieces.size) ? "form-control-"+pieces.size : ""}`}
              rows={item.rows}
              onKeyDown={handleKeyDown}
              onBlur={(e) => {
                if(pieces.toParent) pieces.toParent({ type: 'textarea', name: item.field, value: e.target.value });
              }}
              disabled={item.disabled} />
            {pieces.errors[item.field] && <p className="mt-0 error">{item.errorMsg}</p>}
          </div>
        )
      }}
    />
  )
});

export default Field;
