import React, { Fragment, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { UserContext } from '../../../context/UserContext';
import { useAxios } from '../../../hooks/useAxios';
import dayjs from 'dayjs'

import { DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { handleDragOver, handleDragEnd } from '../../../utils/dndkit';

import Collapse from 'react-bootstrap/Collapse';
import Droppable from '../../../components/system/sortable/Droppable';
import NewGroup  from './components/NewGroup';
import GroupMini from './components/GroupMini';
import PlanMini  from './components/PlanMini';
import UploadPlan from './components/UploadPlan';
import DownloadPlan  from '../../../components/buttons/DownloadPlan';
import DownloadPdf   from '../../../components/buttons/DownloadPdf';
import DownloadExcel from '../../../components/buttons/DownloadExcel';

import { ArrowsCollapse, ArrowsExpand } from 'react-bootstrap-icons'

const View = () => {
  const { serverCall } = useAxios();
  const { userDetails } = useContext(UserContext);
  const { project, phases, activePhase, planGroups, setPlanGroups, plans, setPlans, phasePlans, setPhasePlans, activePlots } = useOutletContext();
  const [structure, setStructure] = useState({});
  const bottomRef = useRef();

  // plan group toggles
  const [open, setOpen] = useState(true);
  const toggleOpen = () => setOpen((s) => !s);

  const sensors = useSensors(useSensor(PointerSensor));
  const dragOver = (e) => handleDragOver({ e, setStructure });
  const dragEnd = (e) => {
    document.activeElement.blur();
    handleDragEnd({ e, setStructure })
  };

  const updateSort = useCallback(async (items) => {
    setPlans((prev) => {
      let arr = [...prev];
      for(const plan of items) {
        let idx = arr.findIndex(x => x.id === plan.id);
        arr[idx] = {...arr[idx], ...plan};
      }
      return arr;
    })

    let update = { sorted: items, updatedAt: dayjs().format('YYYY-MM-DD HH:mm:ss'), updatedBy: userDetails.id };
    let res = await serverCall({ method: 'POST', data: update, url: `/mapping/plans/sort-order`, eamsAuth0: true });
    if(res.status!==200) return alert('Error sorting. Contact support.') // lklklk
  }, [serverCall, userDetails.id, setPlans]);  

  useEffect(() => {
    let obj = {};
    obj['ungrouped'] = plans.filter(x => !x.planGroupId).sort((a,b) => (a.sortOrder > b.sortOrder) ? 1 : ((b.sortOrder > a.sortOrder) ? -1 : 0));
    for(const group of planGroups) {
      obj[group.id] = plans.filter(x => x.planGroupId === group.id).sort((a,b) => (a.sortOrder > b.sortOrder) ? 1 : ((b.sortOrder > a.sortOrder) ? -1 : 0));
    }
    setStructure(obj);
  }, [planGroups, plans])

  useEffect(() => {
    let updates = [];
    for (const [key, plans] of Object.entries(structure)) {
      let parentId = (key === 'ungrouped') ? null : parseInt(key);
      for(const [idx, plan] of plans.entries()) {
        if(plan.planGroupId !== parentId || plan.sortOrder !== idx+1) {
          updates.push({ id: plan.id, appId: plan.appId, planGroupId: parentId, sortOrder: idx+1 });
        }
      }
    }
    if(updates.length > 0) updateSort(updates);
  }, [structure, updateSort]);

  const fromChild = async (data) => {
    const { type, value } = data;

    if(type==='group added') {
      bottomRef.current.scrollIntoView({ behavior: 'smooth' })
    } else if(type==='update group') {
      setPlanGroups((prev) => {
        let arr = [...prev];
        let idx = arr.findIndex(x => x.id === value.id);
        arr[idx] = {...arr[idx], ...value};
        return arr;
      })

    } else if(type==='update plan') {
      setPlans((prev) => {
        let arr = [...prev];
        let idx = arr.findIndex(x => x.id === value.id);
        arr[idx] = {...arr[idx], ...value};
        return arr;
      })
    }
  }

  return (
    <div className="row h-100 overflow-hidden">
      <div className="col-sm-8 pe-5 h-100 overflow-scroll">
        <DndContext sensors={sensors} onDragOver={dragOver} onDragEnd={dragEnd}>
          { plans.length===0 && (
            <div className="mb-3 p-2 bg-white rounded">
              <p className="p-3 mb-0">No plans yet. Add one to get started.</p>
            </div>
          )}

          { planGroups.filter(x => x.status === 'A').sort((a,b) => (a.sortOrder > b.sortOrder) ? 1 : ((b.sortOrder > a.sortOrder) ? -1 : 0)).map(obj => (
            <div key={obj.appId} className="mb-3 p-2 bg-white rounded">
              <GroupMini obj={obj} toParent={fromChild} />
              <Collapse in={open}>
                <div>
                  <Droppable 
                    id={obj.id} 
                    arr={structure[obj.id] ? structure[obj.id] : []}
                    handle="end" 
                    child={PlanMini} 
                    pieces={{activePhase}} 
                    toParent={fromChild}
                    text="Empty plan group"
                    caption="plans sorted"
                  />
                </div>
              </Collapse>
            </div>
          ))}

          <div className="mb-3 p-2 bg-white rounded">
            <Collapse in={!open}><div><p className="m-0 p-3">{plans.filter(x => !x.planGroupId).length} Ungrouped Plan(s)</p></div></Collapse>
            <Collapse in={open}>
              <div>
                <Droppable 
                  id="ungrouped"
                  arr={structure['ungrouped'] ? structure['ungrouped'] : []}
                  handle="end" 
                  child={PlanMini} 
                  pieces={{activePhase}} 
                  toParent={fromChild} 
                  text="No ungrouped plans"
                  caption="plans sorted"
                />
              </div>
            </Collapse>
          </div>
        </DndContext>
        <div ref={bottomRef} />
      </div>
      <div className="col-sm-4 h-100 overflow-scroll">
        <h5 className="mb-0">Actions</h5>
        { plans.filter(x => x.status === 'A').length > 0 && (
          <button className="btn btn-outline-dark mt-2 me-2 mb-2" onClick={toggleOpen}>
            { open && <Fragment><ArrowsCollapse size={18} /> Collapse</Fragment> }
            {!open && <Fragment><ArrowsExpand size={18} /> Expand</Fragment>}
          </button>
        )}
        <NewGroup projectId={project.id} setPlanGroups={setPlanGroups} toParent={fromChild} />
        <br />
        <DownloadPlan text="All Plans" project={project} planGroups={planGroups} plans={plans} phasePlans={phasePlans} phases={phases} phase={activePhase} plots={activePlots} />
        {/* <DownloadPdf text="PDF" project={project} planGroups={planGroups} plans={plans} phasePlans={phasePlans} phases={phases} phase={activePhase} plots={activePlots} />
        <DownloadExcel text="Excel" project={project} planGroups={planGroups} plans={plans} phasePlans={phasePlans} phases={phases} phase={activePhase} plots={activePlots} /> */}
        <hr />

        <h5>Upload New Plan(s)</h5>
        <p className="small fst-italic text-muted">To swap out backgrounds, click into each plan to edit.</p>
        { phases.filter(x => x.status === 'A').length > 1 && (
          <p className="small text-muted mb-2">This will upload plans in { activePhase.name?.includes('Phase') ? activePhase.name : `the ${activePhase.name} phase`}</p>
        )}
        <UploadPlan project={project} activePhase={activePhase} setPlans={setPlans} setPhasePlans={setPhasePlans} />
      </div>
    </div>
  )
}

export default View;
