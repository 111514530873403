import React, { Fragment, useContext, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAxios } from '../../../../hooks/useAxios';

import { SiteContext } from '../../../../context/SiteContext';
import { UserContext } from '../../../../context/UserContext';

import Offcanvas from 'react-bootstrap/Offcanvas';
import Generated  from '../../../../components/system/Generated';
import DeleteBtn from '../../../../components/buttons/DeleteBtn';
// import EmailError from '../system/EmailError';

import dayjs from 'dayjs'
import { formObj, removeArrs } from '../../../../utils/schemas';
import { getPlotDisplay } from '../../../../utils/plots';

import qrReport from '../../../../utils/reports/qrcodes';
import { QrCode } from 'react-bootstrap-icons';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

// font documentation for when you need it:
// https://pdfmake.github.io/docs/0.1/fonts/custom-fonts-client-side/vfs/



const View = (props) => {
  const debug = true;
  const { config } = useContext(SiteContext);
  const { userDetails, schemas } = useContext(UserContext);
  const { schema, actions, project, activePhase, obj, types, setPlots, handleClose, toParent } = props;
  const formText = { submit: `Update`, pending: 'Updating', success: 'Updated!', cancel: 'Cancel' };
  const errorCode = '1984'
  const [border, setBorder] = useState('#ccc');

  // plot schema is the default set by site config, if any
  // type schema is the override if any exists
  const [plotSchema, setPlotSchema] = useState([]);
  const [typeSchema, setTypeSchema] = useState([]);

  const { serverCall, pending, error } = useAxios();
  const { control, register, handleSubmit, formState: { errors }, reset, watch, setValue } = useForm({
    defaultValues: useMemo(() => formObj(schema), [])
  });
  const typeId = watch('typeId');

  useEffect(() => {
    if('schemaIds' in config && 'edit-plot' in config.schemaIds) {
      let schemaId = config.schemaIds['edit-plot'];
      let found = schemas.find(x => x.id===schemaId);
      if(found) setPlotSchema(found.schema);
    }
  }, [config])

  useEffect(() => {
    if(!obj) return;
    console.log(obj);
    let filled = formObj(schema, obj);
    let details = formObj(plotSchema, obj);
    reset({...filled, ...details});

    if(!obj.display) return;
    let color = obj.display.labelFill ? obj.display.labelFill : obj.display.locatorFill;
    setBorder(color);
  }, [config, schema, plotSchema, obj, reset])

  // useEffect(() => {
  //   console.log(typeId);
  // }, [typeId])

  const handleCancel = async () => {
    if(obj.ogTypeId) {
      let plot = {...obj};
      plot.typeId = obj.ogTypeId;
      delete plot.ogTypeId;
      plot = getPlotDisplay({ types, plot });

      let filled = formObj(schema, plot);
      let details = formObj(plotSchema, obj);
      reset({...filled, ...details});

      let color = plot.display.labelFill ? plot.display.labelFill : plot.display.locatorFill;
      setBorder(color);

      await setPlots((prev) => {
        let arr = [...prev];
        let idx = arr.findIndex(x => x.id === plot.id);
        arr[idx] = {...arr[idx], ...plot}
        return arr;
      })
    }
  }

  const onSubmit = async (data) => {
    if(debug) console.log(data);
    delete data.ogTypeId;
    let cleaned = removeArrs(data);

    await setPlots((prev) => {
      let arr = [...prev];
      let idx = arr.findIndex(x => x.id === obj.id);
      arr[idx] = {...arr[idx], ...cleaned}
      return arr;
    })

    data.updatedAt = dayjs().format('YYYY-MM-DD HH:mm:ss');
    data.updatedBy = userDetails.id;

    let res = await serverCall({ method: 'PATCH', data, url: `/mapping/plots/${obj.appId}`, eamsAuth0: true });
    if(res.status!==200) return alert('Error updating plot. Contact support.') // lklklk
  };

  const fromChild = async (data) => {
    const { type, name, value } = data;
    if(type==='autosuggest' && name==='typeId' && obj.typeId !== value) {
      let plot = {...obj};
      plot.typeId = value;
      // only overwrite if it doesn't already exist
      if(!plot.ogTypeId) 
        plot.ogTypeId = obj.typeId;

      plot = getPlotDisplay({ types, plot });
      let color = plot.display.labelFill ? plot.display.labelFill : plot.display.locatorFill;
      setBorder(color);

      await setPlots((prev) => {
        let arr = [...prev];
        let idx = arr.findIndex(x => x.id === plot.id);
        arr[idx] = {...arr[idx], ...plot}
        return arr;
      })
    }
    // if(type==='delete confirmed') {
    //   if(toParent) toParent({ type: 'remove types', value: [obj.id] });
    //   handleClose();
    // }
  }

  const lktest = async () => {
    let content = await qrReport({ project, activePhase });
    pdfMake.createPdf(content).open();
  }

  return (
    <div className="d-flex flex-column h-100 overflow-hidden" style={{borderLeft:`6px solid ${border}`}}>
      <Offcanvas.Header className="bg-body" closeButton>
        <Offcanvas.Title>{obj?.display?.textLabel}</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="bg-body flex-grow-1 pt-0 overflow-scroll">
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" className="mb-3">
        <Generated id="plot-edit" schema={schema} size="md" pieces={{ vars: { 'plot-actions': actions, types }, register, control, setValue, watch, errors, toParent: fromChild }} />
        <Generated id="plot-detail" schema={plotSchema} size="md" pieces={{ register, control, setValue, watch, errors, toParent: fromChild }} />

          {/* and then here... should be the message by the type? */}

          
        </form>
      </Offcanvas.Body>
      <div className="bg-body p-3">
        
        {/*<button className="btn btn-sm" onClick={lktest}><QrCode size={18} /></button>*/}
        <button className={`btn btn-sm ${error ? 'btn-danger' : 'btn-success'} float-end`} type="submit" disabled={pending} onClick={() => handleSubmit(onSubmit)() }>
          { !pending && !error && (formText.submit)}
          { pending === true && (
            <Fragment>
              <div className="spinner-border spinner-border-sm text-white me-2" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
              {formText.pending}
            </Fragment>
          )}
          { error && (`Error saving (${errorCode})`)}
          { pending ==='success' && formText.success}
        </button>

        <button className="btn btn-sm btn-outline-dark ms-auto me-1 float-end" type="button" onClick={handleCancel}>
          {formText.cancel}
        </button>

        {/* <DeleteBtn toParent={fromChild} text={`Delete Plot`} wrapper="float-start" /> */}
        { userDetails.supe && <p className="float-start ms-2 mt-2 mb-0 small text-muted">{obj.id}</p>}        
      </div>
    </div>
  )
}

export default View;
